import { Button, Input } from 'antd'
import AccreditationTabs from 'components/AccreditationTabs'
import { 
  AccreditationNotificationKey,
  InspectionProcessKey,
  ListEditAccreditationKey,
  PunishKey,
  CreateNewCustomerKey,
  ListAccreditationKey
} from 'constants/accreditationTabs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import LoginService from 'services/loginService'
import './punish.scss'

function AuthPunish() {
  const { state } = useLocation()
  const { t: translation } = useTranslation()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [error, setError] = useState("")
  const [licensePlates, setLicensePlates] = useState(state && state.plate || "")

  useEffect(() => {
    if(state && state.plate) {
      handleFind()
    }
  },[])

  function handleFind() {
    setLoading(true)
    error && setError('')
    LoginService.getDetailPunish({
      plateNumber: licensePlates
    }).then(result => {
      if(result && result.length > 0) {
        setData(result)
      } else {
        data.length > 0 && setData([])
        setError(translation('management.none'))
      }
      setLoading(false)
    })
  }

  return (
    <AccreditationTabs 
      onChangeTabs={(key)=> {
        if(key === AccreditationNotificationKey) {
          window.open("/accreditation-public", "_blank")
        } else if(key===CreateNewCustomerKey){
          window.open("/create-customer", "_blank")
        } else if(key === ListEditAccreditationKey) {
          history.push('/list-detail-accreditation')
        } else if(key === InspectionProcessKey){
          history.push("/inspection-process")
        } else if(key === ListAccreditationKey) {
          history.push('/accreditation')
        } else {
  
        }
      }}
      ListAccreditation={null}
      activeKey={PunishKey}
      ListEditAccreditation={null}
      InspectionProcess={null}
      CreateNewCustomer={null}
      AccreditationNotification={null}
      Punish={() => (
        <main className='punish_container row'>
        <div className='punish_container__center_block col-12 col-md-12 col-lg-8 col-xl-8'>
          <div className='punish_container__center_block_title'>{translation('header.punish')}</div>
          
          <div className='d-flex justify-content-center mb-3'>
            <div className='row w-100'>
              <div className='col-2'/>
              <div className='col-12 col-sm-5'>
                <Input 
                  autoFocus
                  placeholder={translation('accreditation.licensePlates')} 
                  value={licensePlates}
                  onChange={(e) => setLicensePlates(e.target.value)}
                  onPressEnter={!loading && handleFind}
                />
              </div>
              <div className='col-12 col-sm-3 my-mt-2'>
                <Button disabled={loading} onClick={handleFind} type='primary'>{translation('landing.search')}</Button>
              </div>
              <div className='col-2'/>
            </div>
          </div>
            
          <div className='punish_container__center_block_result'>
            <div className='row'>
            {
              loading && <div className='d-flex justify-content-center'>
                <div className="loader"/>
              </div> 
            }
            {
              data && data.length > 0 ? data.map((item, index) => {
                return (
                  <React.Fragment key={item.id}>
                      {index % 8 === 0 && <hr />}
                        <div className="d-flex align-items-center item">
                          <div className="col-5">{translation('punish.plateId')}:</div>
                          <div className="col-7">{item.licenseNumber}</div>
                        </div>
                        <div className="d-flex align-items-center item">
                          <div className="col-5">{translation('punish.plateType')}:</div>
                          <div className="col-7">{item.specs}</div>
                        </div>
                        <div className="d-flex align-items-center item">
                          <div className="col-5">{translation('punish.time')}:</div>
                          <div className="col-7">{item.violationTime}</div>
                        </div>
                        <div className="d-flex align-items-center item">
                          <div className="col-5">{translation('punish.address')}:</div>
                          <div className="col-7">{item.violationAddress}</div>
                        </div>
                        <div className="d-flex align-items-center item">
                          <div className="col-5">{translation('punish.behavior')}:</div>
                          <div className="col-7">{item.behavior}</div>
                        </div>
                        <div className="d-flex align-items-center item">
                          <div className="col-5">{translation('punish.status')}:</div>
                          <div className="col-7"><span className="ant-btn ant-btn-default ant-btn-dangerous">{item.status}</span></div>
                        </div>
                        <div className="d-flex align-items-center item">
                          <div className="col-5">{translation('punish.provider')}:</div>
                          <div className="col-7">{item.provider}</div>
                        </div>
                        <div className="d-flex align-items-center item">
                          <div className="col-5">{translation('punish.phone')}:</div>
                          <div className="col-7">{item.phone}</div>
                        </div>
                      {index === item.length - 1 && <hr />}
                  </React.Fragment>
                )
              }) : (
                <div className='error'>{error}</div>
              )
            }
            </div>
          </div>
        </div>
      </main>
      )}
    />
  )
}

export default AuthPunish