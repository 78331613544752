import React from "react"
import { useTranslation } from "react-i18next"
import { Modal, Button } from 'antd'
import { LoadingOutlined } from "@ant-design/icons"
import { resolveAppApiUrl } from "helper/resolveAppApiUrl"

const XLSX_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
const SAMPLE_FILE_LINK = `${resolveAppApiUrl()}/uploads/exportExcel/file_mau_import.xlsx`
const ModalUpload = ({ visible, toggleUploadModal, onUploadFile, file, onImportFileToDb, isLoading }) => {
	const { t: translation } = useTranslation()
	return (
		<Modal
			visible={visible}
			title={translation("listCustomers.modalUploadTitle")}
			onCancel={toggleUploadModal}
			footer={null}
		>
			<div className="d-flex justify-content-center">
				{isLoading && <LoadingOutlined />}
			</div>
			<div>
				<div className="list_customers__modal_selectFile" >{translation("listCustomers.selectFileTitle")}{" "} excel(csv):</div>
				<input hidden type="file" id="selectFile" onChange={onUploadFile} accept={XLSX_TYPE} />
				<Button className="black_button w-100" size="large">
					<label htmlFor="selectFile">
						{translation('listCustomers.selectFile')}
					</label>
				</Button>
				<p>{file && file.name}</p>
			</div>

			<Button className="blue_button w-100" size="large" onClick={() => window.open(SAMPLE_FILE_LINK, "_blank")}>
				{translation('listCustomers.downloadSampleFile')}
			</Button>

			<Button onClick={onImportFileToDb} className="mt-3" type="default">{translation('listCustomers.importFile')}</Button>
		</Modal>
	)
}

export default ModalUpload