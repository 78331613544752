import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Table, Modal, Tooltip } from 'antd';
import mqtt from 'mqtt';
import InspectionProcessService from "./../../services/inspectionProcessService"
import AccreditationService from "./../../services/accreditationService"
import moment from "moment"
import { IconCar } from "../../assets/icons"
import './accreditation_public.scss'
import { ExceptionOutlined } from '@ant-design/icons';
import { ModalCrime } from 'Page/ListCustomers/ModalCrime';

const DEFAULT_ACTIVE_ROW={ recordId: -1, rowId: -1}
const SIZE = 400
const DEFAULT_FILTER = { filter: {}, skip: 0, limit: SIZE , startDate: moment().format("DD/MM/YYYY"), endDate: moment().format("DD/MM/YYYY")}
let TmpData = []
let TmpAudio = []
let TmpTotal = 0
let IsRunning = false
function ListAccreditation() {
  const myAudio = useRef(null);
  const myAudio2 = useRef(null);
  const { t:translation } = useTranslation()
  const [stationCheckingConfig, setStationCheckingConfig] = useState([])
  const [client, setClient] = useState(null);
  const [activeNow, setActiveNow] = useState(DEFAULT_ACTIVE_ROW)
  const [processSpeech, setProcessSpeech] = useState()
  const [plateSpeeches, setPlateSpeeches] = useState([]);
  const [crimePlateNumber, setCrimePlateNumber] = useState('')
  const [dataAccreditation, setDataAccreditation] = useState({
    total: 0,
    data: []
  })
  const startAudio = useRef()

  TmpTotal = dataAccreditation.total

  TmpData = dataAccreditation.data
  const [dataFilter, setDataFilter] = useState(DEFAULT_FILTER)

  const mqttConnect = (host, mqttOption) => {
    setClient(mqtt.connect(host, mqttOption));
  };
  
  useEffect(()=>{
    handleFetchAccreditation(DEFAULT_FILTER)
 
  }, [])

  const user = useSelector((state) => state.member)
  const setting = useSelector(state => state.setting)

  function handleFetchAccreditation(filter){
    AccreditationService.getList(filter).then(result=>{
      if(result){
        setDataAccreditation({
          ...result
        })
      }
    })
  }

  useEffect(() => {
    if(startAudio && startAudio.current && startAudio.current.play) {
      const playPromise = startAudio.current.play()
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {                  
            console.log("audio played auto");
          })
          .catch(error => {
            Modal.info({
              title: 'Cho phép trang web phát âm thanh thông báo',                    
              onOk() {
                startAudio.current.play()
              },
            });                 
            console.log("playback prevented");
          });
      }
    }
  },[])

  useEffect(() => {
    if (client) {
      
      client.on('connect', () => {
        console.log('connect',3242)
      });
      client.on('error', (err) => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => {
        console.log('Reconnecting')
      });
      client.on('message', (topic, message) => {     
        
        const payload = { topic, message: message.toString() };
        if(topic === `RECORD_UPDATE_${user.stationsId}` && payload.message) {         
          const newData = JSON.parse(`${payload.message}`)
          const index = TmpData.findIndex(item=> item.customerRecordId === newData.customerRecordId)
          
          if(index !==-1){
            TmpData[index] = newData
            setDataAccreditation({
              total: TmpTotal,
              data: [
                ...TmpData
              ]
            })
            
            TmpAudio.push(newData)
          }
        }

        if(topic === `RECORD_ADD_${user.stationsId}` && payload.message) {         
          const newData = JSON.parse(`${payload.message}`)
          const newDataArray = []
          TmpData.forEach(item=>{
            newDataArray.push({
              ...item,
              isAdd: false
            })
          })
          setDataAccreditation({
            total: TmpTotal +1,
            data: [
              {
                ...newData,
                isAdd: true
              },
              ...newDataArray
            ]
          })
        }
        if(topic === `RECORD_DELETE_${user.stationsId}`) {
          const record = JSON.parse(`${payload.message}`)
          const newData = TmpData.filter(item=> item.customerRecordId !== record.customerRecordId)
          if(newData.length !== TmpData.length) {
            setDataAccreditation({
              total: TmpTotal-1,
              data: newData
            })
          }
        }

      });
      mqttSub({
        topic: `RECORD_UPDATE_${user.stationsId}`, qos: 2
      })
      mqttSub({
        topic: `RECORD_ADD_${user.stationsId}`, qos: 2
      })
      mqttSub({
        topic: `RECORD_DELETE_${user.stationsId}`, qos: 2
      })
      return ()=>{
        mqttUnSub({
          topic: `RECORD_UPDATE_${user.stationsId}`
        }) 
        mqttSub({
          topic: `RECORD_ADD_${user.stationsId}`, qos: 2
        })
        mqttUnSub({
          topic: `RECORD_DELETE_${user.stationsId}`, qos: 2
        })
      }
    }
    
  }, [client]);

  useEffect(()=>{
    setInterval(()=>{
  
        if(TmpAudio.length && !IsRunning){
          const newData = TmpAudio[0]
          console.log(newData)
          setActiveNow({
            recordId: newData.customerRecordId,
            rowId: newData.customerRecordState
          })
         
          if (myAudio && myAudio2) {
            IsRunning = true
           
            let i =0
            myAudio.current.onended = function () {
                i = ++i 
                if(i < newData.plateSpeeches.length ){
                  myAudio.current.src = newData.plateSpeeches[i];
                  myAudio.current.autoplay =true
                  myAudio.current.load()
                  if(myAudio.current.play){
                    myAudio.current.play()
                  }
                 
                }else if(i === newData.plateSpeeches.length){
                  myAudio2.current.src = newData.processSpeech
                  myAudio2.current.load()
                  myAudio2.current.autoplay =true
                  if(myAudio2.current.play){
                    myAudio2.current.play()
                  }
                  IsRunning=false
                  setActiveNow(DEFAULT_ACTIVE_ROW)
                }   
            };
            
            myAudio.current.src = newData.plateSpeeches[0];
            myAudio.current.load()
            myAudio.current.autoplay =true
            
            if(myAudio.current.play){
              const playPromise = myAudio.current.play()
              if (playPromise !== undefined) {
                playPromise
                  .then(_ => {                  
                    console.log("audio played auto");
                  })
                  .catch(error => {
                    // Modal.info({
                    //   title: 'Cho phép trang web phát âm thanh thông báo',                    
                    //   onOk() {
                        // myAudio.current.play()
                      // },
                    // });                 
                    console.log("playback prevented");
                  });
              }
        
            }   
            TmpAudio.shift()
         }
          
        }
      
     
      
    }, 5000)
  },[])
  
  const mqttDisconnect = () => {
    if (client) {
      client.end(() => {
        console.log('end')
      });
    }
  }

  const mqttSub = (subscription) => {
    if (client) {
      const { topic, qos } = subscription;
      
      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
      });
    }
  };

  const mqttUnSub = (subscription) => {
    if (client) {
      const { topic } = subscription;
      client.unsubscribe(topic, error => {
        if (error) {
          console.log('Unsubscribe error', error)
          return
        }
        
      });
    }
  };

  useEffect(()=>{
    InspectionProcessService.getDetailById({ id: user.stationsId }).then(result=>{
      if(result && result.stationCheckingConfig){
        setStationCheckingConfig(result.stationCheckingConfig)
      }
    })
    handleFetchAccreditation(dataFilter)
  },[])

  useEffect(()=>{
  
  
    handleConnect()
    return ()=>{
      handleDisconnect()
    }
  }, [])

  const handleConnect = () => {
    let url = process.env.REACT_APP_API_WSS_URL
    
    if (window.location.protocol !== "https:") {
       url = process.env.REACT_APP_API_WS_URL
   }
   const clientId = `RECORD_UPDATE_${user.stationsId}_${moment().format("YYYY_MM_DD_hh_mm_ss")}`
    
    const options = {  
      reconnectPeriod: 10000,   
      rejectUnauthorized: false
    };
    options.clientId = clientId;

    mqttConnect(url, options);
  };

  const handleDisconnect = () => {
    mqttDisconnect();
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'name',
      width: 50,
      className: 'accreditation__center accreditation__stt',
      render: (_, __, index)=>{
        return (
          <h4 className="accreditation__center accreditation__stt">
            <b style={{fontFamily: "sans-serif"}}>{dataFilter.skip ? dataAccreditation.total - ( dataFilter.skip + index ): dataAccreditation.total  - (index)}</b>
          </h4>
        )
      }
    },
    {
      title: translation("accreditation.licensePlates"),
      dataIndex: 'customerRecordPlatenumber',
      key: 'customerRecordPlatenumber',
      width: 100,
      render: (_, record) => {
        const { customerRecordPlatenumber, hasCrime } = record
        if (!hasCrime) {
          return (
            <h4 className="accreditation__center">
              <b style={{fontFamily: "sans-serif"}}>{customerRecordPlatenumber}</b>
            </h4>
          )
        }
        return (
          <div className='d-flex justify-content-between align-items-center'>
            <h4 className="accreditation__center">
              <b style={{fontFamily: "sans-serif"}}>{customerRecordPlatenumber}</b>
            </h4>
            <Tooltip title={translation('punish.notification')}>
              <ExceptionOutlined
                  style={{
                      color: 'var(--bs-warning)',
                      fontSize: '1.2rem',
                      cursor: 'pointer',
                  }}
                  onClick={() => setCrimePlateNumber(customerRecordPlatenumber)}
              />
            </Tooltip>
          </div>
        )
      }
    }
  ];

  const columnsV1 =[...columns]
  stationCheckingConfig.forEach(item=>{
    columnsV1.push({
      title: item.stepLabel,
      dataIndex: item.stepIndex,
      key: item.stepLabel,
      className: "accreditation__center",
      render: (_, record)=>{
        return <div className="accreditation">
          {item.stepIndex === record.customerRecordState ?<> <IconCar height="60px" width="60px" className={`accreditation__circle-active ${activeNow.recordId ===record.customerRecordId && !IsRunning? "accreditation__circle-animation" : ""}`}></IconCar></>:  <div className={`accreditation__circle`}></div>}
        </div>
      }
    })
  })
 
  const srcBannerLeft = setting && setting.stationsEnableAd ? setting.stationsCustomAdBannerLeft : ""
  const srcBannerRight = setting && setting.stationsEnableAd ? setting.stationsCustomAdBannerRight : ""
	return (
    <>
    <div className={`accreditation_public ${setting && setting.stationsEnableAd ? 'banner_on' : ''}`}>
      {
        setting && setting.stationsEnableAd ? (
          <div className={`accreditation_public_banner`}>
            <img src={srcBannerLeft}/>
          </div>
        ) : (
          <></>
        )
      }
      <Table 
        dataSource={dataAccreditation.data} 
        columns={columnsV1} 
        rowClassName={(record, index) => `${record &&record.isAdd ? "edited-row__add" : ""}`}  
        scroll={{x:1190}}
        className={setting && setting.stationsEnableAd ? 'accreditation_public_table_with_banner' : ""}
        pagination={{
          position: ['bottomCenter'],
          total: dataAccreditation.total,
          pageSize	: SIZE,
          current: dataFilter.skip ? (dataFilter.skip / dataFilter.limit) + 1 : 1
        }}
        onChange={({current, pageSize	})=>{
          dataFilter.skip = (current -1) * pageSize	
          setDataFilter({...dataFilter})
          handleFetchAccreditation(dataFilter)
        }} 
    />
    {
      setting && setting.stationsEnableAd ? (
        <div className='accreditation_public_banner'>
          <img src={srcBannerRight}/>
        </div>
      ) : (
        <></>
      )
    }
  </div>
   {/* className="displayNone" */}
    <audio  ref={myAudio} type='audio'>
     {
       plateSpeeches.map(link=>(
        <source src={link} type="audio/mpeg" />
       ))
     }  
     </audio>
     <audio   ref={myAudio2} type='audio'>
       {
         processSpeech ? <source src={processSpeech} type="audio/mpeg" /> : ""
       }  
     </audio>
     <audio ref={startAudio} type="audio">
       <source type='audio/mpeg' src={require('../../assets/mp3/testAudio.mp3')}/>
     </audio>

     {!!crimePlateNumber && <ModalCrime plateNumber={crimePlateNumber} onClose={() => setCrimePlateNumber('')} />}
   </>
	)
}

export default ListAccreditation;