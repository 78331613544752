import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Button, notification, Select } from 'antd'
import { useTranslation } from "react-i18next"
import ManagementService from "../../services/manageService"

const DEFAULT_FILTER = {
  "filter": {
    "appUserRoleName": undefined,
    "permissions": undefined
  },
  "skip": 0,
  "limit": 20,
  "order": {
    "key": "createdAt",
    "value": "desc"
  }
}

const ModalEditUserInfo = ({ form, isEditing, toggleEditModal, onUpdateUser, selectedUser, inputRef }) => {
  const { t: translation } = useTranslation()
  const [roleList, setRoleList] = useState({
    total: 0,
    data: []
  })

  function handleUpdate(values) {
    if(values.appUserRoleId && typeof values.appUserRoleId === 'string') {
      const findItem = roleList.data.find(item => item.appUserRoleName === values.appUserRoleId)
      values.appUserRoleId =  findItem.appUserRoleId
    }
    Object.keys(values).map(k =>{ 
      if(!values[k]) {
        values[k] = undefined
      }
    })
    onUpdateUser({
      id: selectedUser.appUserId,
      data: {
        ...values
      }
    })
  }

  useEffect(() => {
    if(selectedUser) {
      let oldId = roleList.data.find(item => item.appUserRoleId === selectedUser.appUserRoleId)
      if(!oldId) {
        oldId = undefined
      } else {
        oldId = oldId['appUserRoleName']
      }
      form.setFieldsValue({
        ...selectedUser,
        appUserRoleId: oldId
      })
    }
    return () => form.resetFields()
  }, [selectedUser])

  useEffect(() => {
    function getListRole() {
      ManagementService.getListRole(DEFAULT_FILTER).then(result =>{ 
        if(result) {
          setRoleList(result)
        } else {
          notification.error({
            message: '',
            description: translation('new.fetchDataFailed')
          })
        }
      })
    }
    getListRole()
  },[])

  return (
    <Modal
      visible={isEditing}
      title={translation('listCustomers.modalEditTitle')}
      onCancel={toggleEditModal}
      footer={null}
    >
      <Form
        form={form}
        onFinish={handleUpdate}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <Form.Item
              name="firstName"
            >
              <Input autoFocus={selectedUser ? true : false} ref={selectedUser ? inputRef : undefined} placeholder={`${translation('management.firstName')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="lastName"
            >
              <Input placeholder={`${translation('management.lastName')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="email"
            >
              <Input placeholder={`Email`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="phoneNumber"
            >
              <Input placeholder={translation('landing.phoneNumber')} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="appUserRoleId"
            >
              <Select placeholder={translation('management.role')}>
                {
                  roleList && roleList.data && roleList.data.length > 0 && roleList.data.map(item => {
                    return (
                      <Select.Option value={item.appUserRoleId} key={item.appUserRoleId}>{item.appUserRoleName}</Select.Option>
                    )
                  }) 
                }
              </Select>
            </Form.Item>
          </div>
          
        </div>
        <div className="row">
          <div className="col-8 col-md-9" />
          <div className="col-3 col-md-3">
            <Form.Item>
              <Button key="submit" className="blue_button" htmlType="submit">
                {translation('listSchedules.save')}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEditUserInfo