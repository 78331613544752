import Request from './request'

export default class LoginService {
  static async AddBooking({
    time,
    dateSchedule,
    email,
    fullnameSchedule,
    phone,
    licensePlates,
    notificationMethod,
    stationUrl
  }) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/userInsertSchedule',
        data: {
          licensePlates: licensePlates,
          phone: phone,
          fullnameSchedule: fullnameSchedule,
          email: email,
          dateSchedule: dateSchedule,
          time: time,
          stationUrl: stationUrl,
          notificationMethod: notificationMethod,
        },
      }).then((result = {}) => {
        const { statusCode } = result
        if (statusCode === 200) {
          return resolve({ issSuccess: true })
        } else {
          return resolve({ issSuccess: false })
        }
      })
    })
  }
}
