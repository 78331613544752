import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Form,
  Input,
  Button,
  InputNumber,
  notification,
  Switch,
  Tabs,
} from 'antd'
import {
  MinusCircleOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import { IconAddCircle } from './../../assets/icons'
import { useSelector } from 'react-redux'
import InspectionProcessService from './../../services/inspectionProcessService'
import './inspectionProcess.scss'
import {
  ListAccreditationKey,
  ListEditAccreditationKey,
  CreateNewCustomerKey,
  AccreditationNotificationKey,
  InspectionProcessKey,
  PunishKey,
} from 'constants/accreditationTabs'
import AccreditationTabs from 'components/AccreditationTabs'

function InspectionProcess(props) {
  const { t: translation } = useTranslation()
  const user = useSelector((state) => state.member)
  const [dataValues, setDataValues] = useState([])
  const [loading, setLoading] = useState(true)
  const [isAuto, setIsAuto] = useState(0)
  const [buttonLoading, setButtonLoading] = useState(false)
  const { history } = props

  useEffect(() => {
    InspectionProcessService.getDetailById({ id: user.stationsId }).then(
      (result) => {
        if (result && result.stationCheckingConfig) {
          setDataValues(result.stationCheckingConfig)
          setIsAuto(result.stationCheckingAuto ? 1 : 0)
        }
        setLoading(false)
      }
    )
  }, [buttonLoading])

  const checkStepIndex = (data) => {
    let stations = data.stations

    let storeIndexNotEqual
    for (let i = 0; i < stations.length; i++) {
      if (stations[i].stepIndex !== i) {
        storeIndexNotEqual = i
        break
      }
    }

    if (storeIndexNotEqual !== undefined) {
      for (let i = storeIndexNotEqual; i < stations.length; i++) {
        stations[i].stepIndex = i
      }
    }
    return {
      ...data,
      stations: stations,
    }
  }

  const onFinish = (values) => {
    const newData = values ? checkStepIndex(values) : {}
    setButtonLoading(true)
    newData.stationCheckingAuto = newData.stationCheckingAuto ? 1 : 0
    InspectionProcessService.updateById({
      id: user.stationsId,
      data: {
        stationCheckingConfig: newData.stations,
        stationCheckingAuto: newData.stationCheckingAuto,
      },
    }).then((result) => {
      if (result && result.issSuccess) {
        notification['success']({
          message: '',
          description: translation('inspectionProcess.updateSuccess'),
        })
      } else {
        notification['error']({
          message: '',
          description: translation('inspectionProcess.updateError'),
        })
      }
      setButtonLoading(false)
    })
  }

  const onChangeAuto = (e) => {
    notification['info']({
      message: '',
      description: e
        ? translation('accreditation.autoOn')
        : translation('accreditation.autoOff'),
    })
  }

  return (
    <AccreditationTabs
      onChangeTabs={(key) => {
        if (key === AccreditationNotificationKey) {
          window.open('/accreditation-public', '_blank')
        } else if (key === CreateNewCustomerKey) {
          window.open('/create-customer', '_blank')
        } else if (key === ListEditAccreditationKey) {
          history.push('/list-detail-accreditation')
        } else if (key === ListAccreditationKey) {
          history.push('/accreditation')
        } else if (key === PunishKey) {
          history.push('/auth-punish')
        } else {
        }
      }}
      ListAccreditation={null}
      activeKey={InspectionProcessKey}
      ListEditAccreditation={null}
      InspectionProcess={() => (
        <main className='inspection_process'>
          <div className='inspection_process__title'>
            {translation('inspectionProcess.title')}
          </div>
          {!loading ? (
            <Form
              initialValues={{
                stations: dataValues,
                stationCheckingAuto: isAuto === 0 ? false : true,
              }}
              onFinish={onFinish}
              autoComplete='off'
            >
              <div className='row inspection_process__form__title'>
                <div className='col-1 col-lg-1 custom_displayNone'></div>
                <div className='col-4 col-lg-3'>
                  {translation('inspectionProcess.processName')}
                </div>
                <div className='col-4 col-lg-3'>
                  {translation('inspectionProcess.processNameVoice')}
                </div>
                <div className='col-3 col-lg-3'>
                  {translation('inspectionProcess.timeMinutes')}
                </div>
                <div className='col-1 col-lg-1'></div>
              </div>
              <Form.List name='stations'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => {
                      return (
                        <div className='row inspection_process__form'>
                          <div className='col-1 col-lg-1 custom_displayNone'>
                            <div className='ant-row ant-form-item inspection_process__form__number'>
                              <div>
                                <span>{name + 1}</span>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'stepIndex']}
                                  fieldKey={[fieldKey, 'stepIndex']}
                                  className='displayNone'
                                  initialValue={name}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className='col-4 col-lg-3'>
                            <Form.Item
                              {...restField}
                              name={[name, 'stepLabel']}
                              fieldKey={[fieldKey, 'stepLabel']}
                              rules={[
                                {
                                  required: true,
                                  message: translation(
                                    'inspectionProcess.enterProcessName'
                                  ),
                                },
                              ]}
                            >
                              <Input
                                autoFocus={name === 0 ? true : false}
                                placeholder={translation(
                                  'inspectionProcess.enterProcessName'
                                )}
                              />
                            </Form.Item>
                          </div>
                          <div className='col-4 col-lg-3'>
                            <Form.Item
                              {...restField}
                              name={[name, 'stepVoice']}
                              fieldKey={[fieldKey, 'stepVoice']}
                            >
                              <Input
                                placeholder={translation(
                                  'inspectionProcess.enterProcessNameVoice'
                                )}
                              />
                            </Form.Item>
                          </div>
                          <div className='col-3 col-lg-3'>
                            <Form.Item
                              {...restField}
                              name={[name, 'stepDuration']}
                              fieldKey={[fieldKey, 'stepDuration']}
                              rules={[
                                {
                                  required: true,
                                  message: translation(
                                    'inspectionProcess.enterProcessTime'
                                  ),
                                },
                              ]}
                            >
                              <InputNumber
                                className='inspection_process__form__input'
                                min={1}
                                type='number'
                                placeholder={translation(
                                  'inspectionProcess.enterProcessTime'
                                )}
                              />
                            </Form.Item>
                          </div>
                          <div className='col-1 col-lg-1 custom-padding-0'>
                            <div className='ant-row ant-form-item inspection_process__form__number inspection_process__form__number-second'>
                              <MinusCircleOutlined
                                className='inspection_process__form__delete'
                                onClick={() => remove(name)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    {fields.length < 5 && (
                      <div className='row'>
                        <div className='col-1 col-lg-1'></div>
                        <div className='col-11 col-lg-11'>
                          <Button
                            className='inspection_process__form__button'
                            onClick={() => add()}
                            icon={<IconAddCircle />}
                          >
                            {translation('inspectionProcess.add')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Form.List>
              <div className='row mt-2 button-bottom'>
                <div className='col-6 col-lg-8 d-flex justify-content-end pt-1 custom_displayNone'>
                  <span>{translation('accreditation.auto')}</span>
                </div>
                <div className='col-3 col-lg-1 d-flex justify-content-end'>
                  <Form.Item name='stationCheckingAuto' valuePropName='checked'>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      defaultChecked={isAuto}
                      onClick={onChangeAuto}
                    />
                  </Form.Item>
                </div>
                <div className='col-4 col-lg-1 inspection_process__form__save'>
                  <Button
                    disabled={buttonLoading}
                    type='primary'
                    htmlType='submit'
                  >
                    {translation('inspectionProcess.save')}
                  </Button>
                </div>
              </div>
            </Form>
          ) : null}
        </main>
      )}
      CreateNewCustomer={null}
      AccreditationNotification={null}
      Punish={null}
    />
  )
}
export default InspectionProcess
