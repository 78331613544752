import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Input, Radio, notification, Select, DatePicker, Tabs } from 'antd'
import AccreditationService from '../../services/accreditationService'
import { useSelector } from 'react-redux'
import moment from 'moment'
import InspectionProcessService from '../../services/inspectionProcessService'
import { listInspectionCycle } from 'constants/listInspectionCycle'
import { IconCar } from "../../assets/icons"
// import _verifyCarNumber from 'constants/licenseplates'
import {
  ListAccreditationKey,
  ListEditAccreditationKey,
  CreateNewCustomerKey,
  AccreditationNotificationKey,
  InspectionProcessKey,
  PunishKey
} from 'constants/accreditationTabs';
import AccreditationTabs from 'components/AccreditationTabs'
import { replaceSpecal } from '../../helper/common'

const { TabPane } = Tabs
const { Option } = Select

function CreateNewCustomer(props) {
  const { t: translation } = useTranslation()
  const [form] = Form.useForm()
  const [stationCheckingConfig, setStationCheckingConfig] = useState([])
  const user = useSelector(state => state.member)
  const { history } = props

  useEffect(() => {
    InspectionProcessService.getDetailById({ id: user.stationsId }).then(result => {
      if (result && result.stationCheckingConfig) {
        setStationCheckingConfig(result.stationCheckingConfig)
        form.setFieldsValue({
          customerRecordState: result.stationCheckingConfig[0].stepIndex
        })
      }
    })
  }, [])

  function onCreateNewCustomer(values) {
    // && _verifyCarNumber(values.customerRecordPlatenumber
    if (values)
      AccreditationService.createNewCustomer({
        ...values,
        customerRecordPlatenumber:replaceSpecal(values.customerRecordPlatenumber.toUpperCase()),
        "customerRecordState": Number(values.customerRecordState) || stationCheckingConfig[0].stepIndex,
        "customerRecordCheckDuration": Number(values.customerRecordCheckDuration) || undefined,
        "customerStationId": user.stationsId,
        "customerRecordCheckDate": moment(values.customerRecordCheckDate).format('DD/MM/YYYY'),
        "customerRecordCheckExpiredDate": values.customerRecordCheckDuration
          ? moment(values.customerRecordCheckDate).add(Number(values.customerRecordCheckDuration), 'months').subtract(1, 'day').format('DD/MM/YYYY')
          : undefined
      }).then(result => {
        if (result.issSuccess) {
          notification['success']({
            message: "",
            description: translation('accreditation.addSuccess')
          })
          form.resetFields()
        }
        else
          notification['error']({
            message: "",
            description: translation('accreditation.addFailed')
          })
      })
    else
      notification.error({
        message: '',
        description: translation('landing.invalidLicensePlate')
      })
  }

  const OptionsColor = [
    {
      value: "white",
      lable: <div className="accreditation__parent">
        {translation("accreditation.white")}

        <IconCar className=" accreditation__circle-white" />

      </div>
    },
    {
      value: "blue",
      lable: <div className="accreditation__parent">
        {translation("accreditation.blue")}

        <IconCar className=" accreditation__circle-blue" />

      </div>
    },
    {
      value: "yellow",
      lable: <div className="accreditation__parent">
        {translation("accreditation.yellow")}

        <IconCar className=" accreditation__circle-yellow" />

      </div>
    }
  ]

  const InspectionCycle = listInspectionCycle(translation)

  const onFinishFailed = (e) => {
    if (e) {
      form.setFieldsValue({
        customerRecordCheckDate: moment()
      })
      const values = form.getFieldsValue()
      if (!values.customerRecordPlatenumber) {
        return
      } else {
        if (values.customerRecordCheckDuration && typeof values.customerRecordCheckDuration === 'string')
          values.customerRecordCheckDuration = Number(values.customerRecordCheckDuration.split(' ')[0])
        onCreateNewCustomer({
          ...values,
          customerRecordCheckDate: values.customerRecordCheckDate.toDate().toISOString(),
          customerRecordCheckDuration: values.customerRecordCheckDuration
        })
      }
    }
  }

  return (
    <>
      <AccreditationTabs 
        onChangeTabs={(key) => {
          if (key === AccreditationNotificationKey) {
            window.open("/accreditation-public", "_blank")
          } else if (key === ListEditAccreditationKey) {
            history.push("/list-detail-accreditation", "_blank")
          } else if (key === InspectionProcessKey) {
            history.push('/inspection-process')
          } else if (key === ListAccreditationKey) {
            history.push('/accreditation')
          }  else if(key === PunishKey) {
            history.push('/auth-punish')
          } else {
    
          }
        }}
        ListAccreditation={null}
        activeKey={CreateNewCustomerKey}
        ListEditAccreditation={null}
        InspectionProcess={null}
        CreateNewCustomer={() => (
          <>
<div className="create_new_customer_title pb-5">{translation('accreditation.createNew')}</div>
          <Form
            onFinish={onCreateNewCustomer}
            onFinishFailed={onFinishFailed}
            form={form}
            initialValues={{
              customerRecordPlateColor: OptionsColor[0].value
            }}
            className="create_new_customer_body"
          >
            <div className="row d-flex justify-content-center">
              <Form.Item
                name="customerRecordPlatenumber"
                rules={[
                  {
                    required: true,
                    message: translation('landing.invalidLicensePlate')
                  }
                ]}
                className="col-12 col-md-6 col-lg-4"
              >
                <Input autoFocus placeholder={translation("landing.license-plates")} />
              </Form.Item>
            </div>

            <div className="row d-flex justify-content-center">
              <Form.Item
                name="customerRecordFullName"
                className="col-12 col-md-6 col-lg-4"
              >
                <Input placeholder={translation('landing.fullname')} />
              </Form.Item>
            </div>

            <div className="row d-flex justify-content-center">
              <Form.Item
                name="customerRecordPhone"
                className="col-12 col-md-6 col-lg-4"
              >
                <Input placeholder={translation('landing.phoneNumber')} />
              </Form.Item>
            </div>

            {/* ngày kiểm định */}
            <div className="row d-flex justify-content-center">
              <Form.Item
                name="customerRecordCheckDate"
                className="col-12 col-md-6 col-lg-4"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  allowClear={false}
                  placeholder={translation('accreditation.inspectionDate')}
                />
              </Form.Item>
            </div>

            {/* Chu kì kiểm định */}
            <div className="row d-flex justify-content-center">
              <Form.Item
                name="customerRecordCheckDuration"
                className="col-12 col-md-6 col-lg-4"
              >
                <Select placeholder={translation('accreditation.inspectionCycle')}>
                  {
                    InspectionCycle.map((item, _) => {
                      return (
                        <Option value={item.value}>{item.label}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </div>

            {/* Quy trình kiểm định */}
            <div hidden className="row d-flex justify-content-center">
              <Form.Item
                name="customerRecordState"
                className="col-12 col-md-6 col-lg-4"
              >
                <Select placeholder={translation('accreditation.inspectionProcess')}>
                  {
                    stationCheckingConfig.length > 0 && stationCheckingConfig.map((item, _) => {
                      return (
                        <Option value={item.stepIndex}>{item.stepLabel}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </div>

            <div className="row d-flex justify-content-center">
              <Form.Item
                className="col-12 col-md-6 col-lg-4"
                name="customerRecordPlateColor"
                rules={[
                  {
                    required: true,
                    message: translation('accreditation.isRequire')
                  }
                ]}
              >
                <Radio.Group>
                  {
                    OptionsColor.map((color, _) => {
                      return (
                        <Radio key={color.value} value={color.value}>{color.lable}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="row d-flex justify-content-center">
              <Form.Item className="col-12 col-md-6 col-lg-4">
                <div className="d-flex justify-content-center">
                  <Button type="primary" htmlType="submit" className="col-12">
                    {translation("accreditation.save")}
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
          </>
        )}
        AccreditationNotification={null}
        Punish={null}
      />
    </>
  )
}

export default CreateNewCustomer
