const ListAccreditationKey = "1"
const ListEditAccreditationKey = "2"
const InspectionProcessKey = "3"
const CreateNewCustomerKey = "4"
const AccreditationNotificationKey = "5"
const PunishKey = "6"

export {
  ListAccreditationKey,
  ListEditAccreditationKey,
  InspectionProcessKey,
  CreateNewCustomerKey,
  AccreditationNotificationKey,
  PunishKey
}