import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Layout, Menu } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { routes } from "./../../App";
import { handleSignout } from "../../actions";
import IconLogo from "./../../assets/icons/logo-without-text.png";
import { renderRoutes } from "constants/permission";
import "./header.scss";
import { LIST_PERMISSION } from "constants/permission";

const { Header: HeaderAntd } = Layout;

export default function Header(props) {
  const { t: translation } = useTranslation();
  const history = useHistory();
  const setting = useSelector((state) => (state.setting ? state.setting : {}));
  const { Features } = props;
  const dispatch = useDispatch();
  const { permissions, appUserRoleId } = useSelector((state) => state.member);

  const pathName = (props && props.location && props.location.pathname) || "";
  const permisstionRoute = renderRoutes(
    translation,
    permissions,
    appUserRoleId
  );
  const FEATURE = {
    login: [
      {
        name: translation("header.new"),
        href: routes.public_new.path,
      },
      {
        name: translation("header.makeAnAppointment"),
        href: routes.bookingSchedule.path,
      },
      // {
      //   name: translation('header.punish'),
      //   href: routes.punishPublic.path,
      // }
    ],
    home: permisstionRoute,
  };

  const _onClick = (href) => {
    history.push(href);
  };

  const renderBanner = () => {
    if(props.member?.isUserLoggedIn) {
      return <></>;
    }
    if (setting.stationsLogo) {
      return (
        <img
          className="logo-banner"
          onClick={() => history.push("/")}
          src={setting.stationsLogo}
          alt="station-logo"
        />
      );
    }

    return (
      <div className="custom-banner">
        <div className="logo-container">
          <img alt={"logo"} height="62px" src={IconLogo} />
          <div className="iso">ISO: 9001-2015</div>
        </div>

        <div className="banner-info">
          <span className="text">Cục Đăng Kiểm Việt Nam</span>
          <div className="text text-large" title={setting.stationsName}>
            {setting.stationsName}
          </div>
          <div className="d-flex justify-content-between banner-footer">
            <span className="text text-small" title={setting.stationsAddress}>
              {translation("setting.address")}: {setting.stationsAddress}
            </span>
            <span
              className="text text-small"
              textAlign={"right"}
              title={setting.stationsHotline}
            >
              {translation("setting.phone")}: {setting.stationsHotline}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderHamburger = () => {
    return (
      <div className="w-100 header_hamburger">
        <button id="menu-btn" className="block hamburger">
          <span className="hamburger-top"></span>
          <span className="hamburger-middle"></span>
          <span className="hamburger-bottom"></span>
        </button>

        <div
          id="mobile-header-content"
          className="container mobile-header-content"
        >
          <div>
            {Features === "LOGIN"
              ? FEATURE.login.map((item) => (
                  <div
                    className={pathName === item.href ? "mobile-item active" : "mobile-item"}
                    onClick={() => _onClick(item.href)}
                    key={Math.random()}
                  >
                    {item.name}
                  </div>
                ))
              : FEATURE.home.map((item, i) => {
                if(!permissions.includes(LIST_PERMISSION.MANAGE_NEWS) && item.href === "/news") {
                  return;
                }
                  if (item)
                    return (
                      <>
                        <div
                          className={
                            item && item.href && pathName === item.href
                              ? "mobile-item active"
                              : "mobile-item"
                          }
                          onClick={() => {
                            if (!item.isNoHref) {
                              _onClick(item.href);
                            }
                          }}
                          key={Math.random()}
                        >
                          {item.name.toUpperCase()}
                        </div>
                      </>
                    );
                })}
          </div>
          <div
            className="header__button"
            style={{ paddingRight: Features === "LOGIN" ? 0 : 20 }}
          >
            {Features === "LOGIN" ? (
              <Button type="primary" onClick={() => _onClick("/login")}>
                {translation("header.login")}
              </Button>
            ) : (
              <Button type="primary" onClick={() => dispatch(handleSignout())}>
                {translation("header.logout")}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (document.querySelector("#menu-btn")) {
      document.querySelector("#menu-btn").addEventListener("click", (e) => {
        document.querySelector("#menu-btn").classList.toggle("open");
        document
          .querySelector("#mobile-header-content")
          .classList.toggle("open");
      });
    }
  }, []);

  return (
    <div className="container">
      {renderBanner()}
      {renderHamburger()}
      <HeaderAntd className="header">
        <div className="container header-container">
          <Menu
            mode="horizontal"
            selectedKeys={[
              (permisstionRoute &&
                permisstionRoute[0] &&
                permisstionRoute[0].href) ||
                "/",
            ]}
          >
            <div
              className={`d-flex justify-content-center w-100 ${
                Features !== "LOGIN" ? "header__container" : ""
              }`}
            >
              <div className="d-flex w-100 justify-content-center header_content">
                {Features === "LOGIN"
                  ? FEATURE.login.map((item) => (
                      <Menu.Item
                        className={pathName === item.href ? "active" : ""}
                        onClick={() => _onClick(item.href)}
                        key={Math.random()}
                      >
                        {item.name}
                      </Menu.Item>
                    ))
                  : FEATURE.home.map((item, i) => {
                    if(!permissions.includes(LIST_PERMISSION.MANAGE_NEWS) && item.href === "/news") {
                      return;
                    }
                      if (item)
                        return (
                          <>
                            <Menu.Item
                              className={
                                item && item.href && pathName === item.href
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                if (!item.isNoHref) {
                                  _onClick(item.href);
                                }
                              }}
                              key={Math.random()}
                            >
                              {item.name.toUpperCase()}
                            </Menu.Item>
                          </>
                        );
                    })}
              </div>
              <Menu.Item
                className="header__button"
                style={{ paddingRight: Features === "LOGIN" ? 0 : 20 }}
              >
                {Features === "LOGIN" ? (
                  <Button type="primary" onClick={() => _onClick("/login")}>
                    {translation("header.login")}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => dispatch(handleSignout())}
                  >
                    {translation("header.logout")}
                  </Button>
                )}
              </Menu.Item>
            </div>
          </Menu>
        </div>
      </HeaderAntd>
    </div>
  );
}
