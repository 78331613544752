import { Tabs } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { 
  AccreditationNotificationKey,
  InspectionProcessKey,
  CreateNewCustomerKey,
  ListEditAccreditationKey,
  PunishKey,
  ListAccreditationKey
} from 'constants/accreditationTabs';
const { TabPane } = Tabs

const AccreditationTabs = ({
  onChangeTabs,
  ListAccreditation,
  activeKey,
  ListEditAccreditation,
  InspectionProcess,
  CreateNewCustomer,
  AccreditationNotification,
  Punish
}) => {
  const { t: translation } = useTranslation()
  const setting = useSelector(state => state.setting)
  const settingAccreditationTabs = setting.settingAccreditationTabs || ""

  function isHiddenTabs (value) {
    if (settingAccreditationTabs.includes(value)) {
      return true
    }
    return false
  }
  return (
    <Tabs activeKey={activeKey} onChange={onChangeTabs} >
      <TabPane tab={translation("accreditation.title")} key={ListAccreditationKey}>
        {(ListAccreditation && !isHiddenTabs(ListAccreditationKey)) && <ListAccreditation />}
      </TabPane>
      {
        !isHiddenTabs(ListEditAccreditationKey) &&
        <TabPane tab={translation("accreditation.list")} key={ListEditAccreditationKey}>
          {ListEditAccreditation && <ListEditAccreditation />}
        </TabPane>
      }
      {!isHiddenTabs(InspectionProcessKey) && 
        <TabPane tab={translation('header.inspectionProcess')} key={InspectionProcessKey}>
          {InspectionProcess && <InspectionProcess />}
        </TabPane>
      }
      {!isHiddenTabs(CreateNewCustomerKey)&&
        <TabPane tab={translation("accreditation.createNew")} key={CreateNewCustomerKey}>
          {
            CreateNewCustomer && <CreateNewCustomer />
          }
        </TabPane>
      }
      {!isHiddenTabs(AccreditationNotificationKey)&&
        <TabPane tab={translation("accreditation.accreditationNotification")} key={AccreditationNotificationKey}>
          {
            AccreditationNotification && <AccreditationNotification />
          }
        </TabPane>
      }
      {
        !isHiddenTabs(PunishKey) && 
        <TabPane tab={translation("header.punish")} key={PunishKey}>
        {Punish && <Punish />}
        </TabPane>
      }
    </Tabs>
  )
}

export default AccreditationTabs
