import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next'
import './setting.scss'
import { useDispatch } from 'react-redux';
import { SETTING } from './../../constants/setting'
import { USER_DETAILS_UPDATE } from '../../constants/member';
import { useSelector } from 'react-redux'
import InspectionProcessService from "./../../services/inspectionProcessService"
import {
  Input,
  Button,
  notification,
  Modal,
  Form,
  Tabs
} from 'antd';
import _ from 'lodash';
import LoginService from '../../services/loginService';
import SettingService from '../../services/settingService';
import ReactCodeInput from 'react-code-input';
import SettingGeneral from './general';
import SettingWeb from './web';
import SettingSecurity from './security';
import RestSetting from './restSetting';
import { resolveAppApiUrl } from 'helper/resolveAppApiUrl';
const SETTING_GENERAL = 'SETTING_GENERAL'
const SETTING_SECURITY = 'SETTING_SECURITY'
const SETTING_WEB = 'SETTING_WEB'
const SETTING_REST = "SETTING_REST"
function Setting() {
  const { t: translation } = useTranslation()
  const user = useSelector((state) => state.member)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const inputRef = useRef()
  const [isOpenModal2FA, setIsOpenModal2FA] = useState(false)
  const [activeKey, setActiveKey] = useState(SETTING_GENERAL)

  useEffect(() => {
    InspectionProcessService.getDetailById({ id: user.stationsId }).then(result => {
      if (result && !_.isEmpty(result))
        dispatch({ type: SETTING, data: result })
    })
  }, [])

  const onChange2FASecurity = (isOn) => {
    SettingService.UpdateUser({
      id: user.appUserId,
      data: {
        twoFAEnable: isOn ? 0 : 1
      }
    }).then(result => {
      if (!result) {
        notification.error({
          message: "",
          description: translation("accreditation.updateError")
        })
      } else {
        dispatch({
          type: USER_DETAILS_UPDATE,
          data: {
            ...user,
            twoFAEnable: isOn ? 0 : 1
          }
        })
      }
    })
  }

  return (
    <>
      <main
        style={{
          cursor: loading ? 'progress' : 'default'
        }}
      >
        <div className="setting__title">{translation('setting.title')}</div>
        <Tabs activeKey={activeKey} onChange={key => {
          setActiveKey(key)
        }}>
          <Tabs.TabPane tab={translation('setting.generalInfo')} key={SETTING_GENERAL}>
            <SettingGeneral />
          </Tabs.TabPane>
          <Tabs.TabPane tab={translation('setting.webSetting')} key={SETTING_WEB}>
            <SettingWeb
              setLoading={setLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={translation('setting.security')} key={SETTING_SECURITY}>
            <SettingSecurity 
              setIsOpenModal={setIsOpenModal}
              setIsOpenModal2FA={setIsOpenModal2FA}
              isOpenModal={isOpenModal}
              inputRef={inputRef}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={translation('setting.rest')} key={SETTING_REST}>
            <RestSetting />
          </Tabs.TabPane>
        </Tabs>
      </main>
      <ModalChangePassword
        isOpen={isOpenModal}
        translation={translation}
        toggleModal={() => setIsOpenModal(!isOpenModal)}
        inputRef={inputRef}
      />
      <Modal2FACode
        isOpen={isOpenModal2FA}
        translation={translation}
        onCancel={() => setIsOpenModal2FA(false)}
        onChange2FASecurity={onChange2FASecurity}
      />
    </>
  )
}
export default Setting;

const ModalChangePassword = ({ isOpen, translation, toggleModal, inputRef }) => {
  const [form] = Form.useForm()
  const onFinish = (values) => {
    LoginService.changeUserPassword(values).then(result => {
      if (result && result.isSuccess) {
        notification.success({
          message: "",
          description: translation("listCustomers.success", {
            type: translation('setting.changePass')
          })
        })
        form.resetFields()
        toggleModal()
      } else {
        notification.error({
          message: "",
          description: translation("listCustomers.failed", {
            type: translation('setting.changePass')
          })
        })
      }
    })
  }
  return (
    <Modal
      visible={isOpen}
      title={translation('setting.changePass')}
      onCancel={toggleModal}
      footer={null}
    >
      <Form form={form} onFinish={onFinish}>
        <label>{translation('landing.account')}</label>
        <Form.Item
          name="username"
          rules={[{
            message: `${translation('landing.account')} ${translation('accreditation.isRequire')}`,
            required: true
          }]}
        >
          <Input ref={inputRef} autoFocus placeholder={translation('landing.account')} />
        </Form.Item>

        <label>{translation('landing.password')}</label>
        <Form.Item
          name="password"
          rules={[{
            message: `${translation('landing.password')} ${translation('accreditation.isRequire')}`,
            required: true
          }]}
        >
          <Input placeholder={translation('landing.password')} type="password" />
        </Form.Item>

        <label>{translation('landing.newPassword')}</label>
        <Form.Item
          name="newPassword"
          rules={[{
            message: `${translation('landing.newPassword')} ${translation('accreditation.isRequire')}`,
            required: true
          }]}
        >
          <Input placeholder={translation('landing.newPassword')} type="password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {translation('landing.confirm')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const Modal2FACode = ({ isOpen, onCancel, translation, onChange2FASecurity }) => {
  const [isValid, setIsValid] = useState(true)
  const user = useSelector(state => state.member)
  const inputStyle = {
    "fontFamily": "monospace",
    "MozAppearance": "textfield",
    "borderRadius": "6px",
    "border": "1px solid",
    "boxShadow": "0px 0px 10px 0px rgba(0,0,0,.10)",
    "margin": "4px",
    "textAlign": "center",
    "width": "55px",
    "height": "55px",
    "fontSize": "32px",
    "boxSizing": "border-box",
    "color": "black",
    "backgroundColor": "white",
    "borderColor": "lightgrey"
  }

  const inputInvalidStyle = {
    ...inputStyle,
    "border": "1px solid rgb(238, 211, 215)",
    "boxShadow": "rgb(0 0 0 / 10%) 0px 0px 10px 0px",
    "color": "rgb(185, 74, 72)",
    "backgroundColor": "rgb(242, 222, 222)"
  }

  const typeCode = (code) => {
    if (code.length === 6) {
      SettingService.verifyingUserCode({
        "otpCode": code,
        "id": user.appUserId
      }).then(result => {
        if (result) {
          onChange2FASecurity(user.twoFAEnable)
          notification.success({
            message: "",
            description: translation('inspectionProcess.updateSuccess')
          })
        } else {
          notification.error({
            message: "",
            description: translation('setting.twoFAError')
          })
          setIsValid(false)
        }
      })
    }
  }

  return (
    <Modal
      visible={isOpen}
      title={translation('landing.twoFATitle')}
      onCancel={onCancel}
      footer={null}
    >
      <div>
        <i>{translation('landing.twoFANote')}</i>
      </div>
      <img
        src={`${resolveAppApiUrl()}/AppUsers/get2FACode?id=${user.appUserId}`}
        height='200'
      />
      <div className="mb-1 mt-3">
        <strong>
          {translation('landing.twoFASubTitle')}
        </strong>
      </div>
      <ReactCodeInput
        type='text'
        fields={6}
        inputStyle={inputStyle}
        inputStyleInvalid={inputInvalidStyle}
        isValid={isValid}
        onChange={typeCode}
        autoFocus
      />
    </Modal>
  )
}