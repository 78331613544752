import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Button, notification, Select } from 'antd'
import { useTranslation } from "react-i18next"
import ManagementService from "../../services/manageService"

const DEFAULT_FILTER = {
  "filter": {
    "appUserRoleName": undefined,
    "permissions": undefined
  },
  "skip": 0,
  "limit": 20,
  "order": {
    "key": "createdAt",
    "value": "desc"
  }
}

const ModalAddUser = ({ form, isAdd, onCancel, inputRef, onCreateNew, member }) => {
  const { t: translation } = useTranslation()
  const [roleList, setRoleList] = useState({
    total: 0,
    data: []
  })

  function handleAdd(values) {
    Object.keys(values).forEach(k => {
      if(!values[k]) {
        delete values[k]
      }
    })
    if(values.username.length < 6) {
      notification.error({
        message: "",
        description: translation('management.invalidUsername')
      })
      return
    }
    onCreateNew({
      ...values,
      stationsId: member.stationsId
    })
  }

  useEffect(() => {
    function getListRole() {
      ManagementService.getListRole(DEFAULT_FILTER).then(result =>{ 
        if(result) {
          setRoleList(result)
        } else {
          notification.error({
            message: '',
            description: translation('new.fetchDataFailed')
          })
        }
      })
    }
    getListRole()
  },[])

  return (
    <Modal
      visible={isAdd}
      title={translation('management.addUser')}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        onFinish={handleAdd}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <Form.Item
              name="username"
              rules={[{ required: true, message: translation('landing.invalidAccount')}]}
            >
              <Input autoFocus ref={inputRef} placeholder={`${translation('landing.account')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="password"
              rules={[{ required: true, message: translation('landing.invalidPassword')}]}
            >
              <Input type='password' placeholder={`${translation('landing.password')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: translation('landing.invalidFullname')}]}
            >
              <Input placeholder={`${translation('management.firstName')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: translation('landing.invalidFullname')}]}
            >
              <Input placeholder={`${translation('management.lastName')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="email"
              rules={[{ required: true, message: translation('management.invalidEmail')}]}
            >
              <Input placeholder={`Email`} type='email' />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="phoneNumber"
              rules={[{ required: true, message: translation('landing.invalidPhone')}]}
            >
              <Input placeholder={translation('landing.phoneNumber')} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="appUserRoleId"
              rules={[{ required: true, message: translation('management.invalidRole')}]}
            >
              <Select placeholder={translation('management.role')}>
                {
                  roleList && roleList.data && roleList.data.length > 0 && roleList.data.map(item => {
                    return (
                      <Select.Option value={item.appUserRoleId} key={item.appUserRoleId}>{item.appUserRoleName}</Select.Option>
                    )
                  }) 
                }
              </Select>
            </Form.Item>
          </div>
          
        </div>
        <div className="row">
          <div className="col-8 col-md-9" />
          <div className="col-3 col-md-3">
            <Form.Item>
              <Button key="submit" className="blue_button" htmlType="submit">
                {translation('listSchedules.save')}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalAddUser