import React, { useState, useEffect } from 'react'
import {
  Form,
  Upload,
  Image,
  notification,
  Input,
  Button,
  Select,
  Space,
  Typography,
} from 'antd'
import { PlusOutlined, RetweetOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { convertFileToBase64 } from '../../helper/common'
import uploadService from '../../services/uploadService'
import NewService from '../../services/newService'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import 'ckeditor5-custom-build/build/translations/vi'
import _ from 'lodash'
import TextEditor from '../../components/TextEditor'
import './newStyle.scss'

const CONTENT_MAXLENGTH = 10000

function PostNew({
  setIsReload,
  oldPostImage,
  oldPost,
  defaultKey,
  setActiveKey,
  listCategory,
}) {
  const { t: translation } = useTranslation()
  const [selectedImage, setSelectedImage] = useState({
    imageFile: null,
    imageUrl: null,
  })
  const [selectedPost, setSelectedPost] = useState(null)
  const [form] = Form.useForm()
  const [ckeditor, setCkeditor] = useState()
  const [ckeditorWordCount, setWordcount] = useState(0)

  useEffect(() => {
    if (oldPost && oldPostImage) {
      setSelectedPost(oldPost)
      form.setFieldsValue({
        ...oldPost,
        stationNewsCategories:
          listCategory.find(
            (item) =>
              item?.stationNewsCategoryId.toString() ===
              oldPost.stationNewsCategories
          )?.stationNewsCategoryTitle || '',
      })
      setSelectedImage(oldPostImage)
    } else {
      form.resetFields()
      setSelectedImage({
        imageFile: null,
        imageUrl: null,
      })
    }
  }, [oldPostImage, oldPost])

  const onSelectImage = async (e) => {
    if (!e) {
      setSelectedImage({
        imageFile: null,
        imageUrl: null,
      })
    } else {
      const { file } = e
      if (file) {
        const validImageTypes = 'image/'
        const fileType = file['type']
        if (!fileType.includes(validImageTypes)) {
          notification['error']({
            message: '',
            description: translation('new.wrongImageFile'),
          })
        } else {
          if (file.status !== 'uploading') {
            convertFileToBase64(file.originFileObj).then((dataUrl) => {
              const newData = dataUrl.replace(/,/gi, '').split('base64')
              if (newData[1]) {
                setSelectedImage({
                  imageFile: {
                    imageData: newData[1],
                    imageFormat: 'png',
                  },
                  imageUrl: dataUrl,
                })
              }
            })
          }
        }
      }
    }
  }

  const onPostNew = (value) => {
    let imageUrl = ''
    if (selectedImage.imageFile) {
      uploadService
        .uploadImage(selectedImage.imageFile)
        .then((result) => {
          if (result.issSuccess) {
            imageUrl = result.data
          } else {
            notification['error']({
              message: '',
              description:
                result.statusCode === 413
                  ? translation('new.imageTooLarge')
                  : translation('new.saveImageFailed'),
            })
          }
        })
        .then(() => {
          handlePostNew({
            ...value,
            stationNewsAvatar: imageUrl,
            stationNewsCategories: value.stationNewsCategories.toString(),
          })
        })
    } else {
      notification.error({
        message: '',
        description: translation('new.wrongImageFile'),
      })
    }
  }

  const handlePostNew = (data) => {
    NewService.postNew({ ...data }).then((result) => {
      if (result.isSuccess) {
        form.resetFields()
        setIsReload(true)
        setSelectedImage({
          imageFile: null,
          imageUrl: '',
        })
        ckeditor.setData('')
        setActiveKey(defaultKey)
        notification['success']({
          message: '',
          description: translation('new.postSucess'),
        })
      } else {
        notification['error']({
          message: '',
          description: translation('new.postFailed'),
        })
      }
    })
  }

  const onUpdatePost = (newValue) => {
    let imageUrl = ''
    if (selectedImage.imageFile) {
      uploadService
        .uploadImage(selectedImage.imageFile)
        .then((result) => {
          if (result.issSuccess) {
            imageUrl = result.data
          } else {
            notification['error']({
              message: '',
              description:
                result.statusCode === 413
                  ? translation('new.imageTooLarge')
                  : translation('new.saveImageFailed'),
            })
          }
        })
        .then(() => {
          handleUpdatePost({
            id: selectedPost.stationNewsId,
            data: {
              stationNewsTitle: newValue.stationNewsTitle,
              stationNewsContent: newValue.stationNewsContent,
              stationNewsAvatar: imageUrl,
              isDeleted: newValue.isDeleted,
              isHidden: newValue.isHidden,
            },
          })
        })
    } else {
      handleUpdatePost({
        id: selectedPost.stationNewsId,
        data: {
          stationNewsTitle: newValue.stationNewsTitle,
          stationNewsContent: newValue.stationNewsContent,
          isDeleted: newValue.isDeleted,
          isHidden: newValue.isHidden,
          stationNewsCategories:
            newValue.stationNewsCategories.length > 3
              ? oldPost.stationNewsCategories
              : newValue.stationNewsCategories.toString(),
        },
      })
    }
  }

  const handleUpdatePost = (data) => {
    NewService.updateANew(data).then((result) => {
      if (result.isSuccess) {
        form.resetFields()
        setSelectedPost(null)
        setIsReload(true)
        setSelectedImage({
          imageFile: null,
          imageUrl: '',
        })
        setActiveKey(defaultKey)
        ckeditor.setData('')
        notification['success']({
          message: '',
          description: translation('new.updateSuccess'),
        })
      } else {
        notification['error']({
          message: '',
          description: translation('new.updateFailed'),
        })
      }
    })
  }

  const options = listCategory.map((item) => {
    return {
      value: item.stationNewsCategoryId,
      label: item.stationNewsCategoryTitle,
    }
  })

  return (
    <main className='row post_new'>
      <Form onFinish={selectedPost ? onUpdatePost : onPostNew} form={form}>
        <div className='w-100 d-flex justify-content-center'>
          <div className='col-12 col-md-7'>
            <div className='new_component__modal_title mb-1'>
              <Space>
                {translation('new.title')}
                <Typography.Text type='danger'>*</Typography.Text>
              </Space>
            </div>
            <Form.Item
              rules={[
                { required: true, message: translation('new.errorContent') },
              ]}
              name='stationNewsTitle'
            >
              <Input
                className='new_component__modal_content'
                maxLength={200}
                placeholder={translation('new.type')}
              />
            </Form.Item>
          </div>
        </div>

        <div className='w-100 d-flex justify-content-center'>
          <div className='col-12 col-md-7'>
            <div className='new_component__modal_title mb-1'>
              <Space>
                {`${translation(
                  'new.content'
                )} (${ckeditorWordCount.toLocaleString()} / ${CONTENT_MAXLENGTH.toLocaleString()})`}
                <Typography.Text type='danger'>*</Typography.Text>
              </Space>
            </div>
            <Form.Item
              name='stationNewsContent'
              rules={[
                { required: true, message: translation('new.invalidContent') },
                {
                  validator: () =>
                    ckeditorWordCount > CONTENT_MAXLENGTH
                      ? Promise.reject(translation('new.invalidContent'))
                      : Promise.resolve(),
                },
              ]}
            >
              <div className='new_component__modal_content'>
                <TextEditor
                  // editor={Editor}
                  config={{
                    language: 'vi',
                    placeholder: translation('new.type'),
                    wordCount: {
                      displayCharacters: true,
                      onUpdate: (stats) => {
                        setWordcount(stats.characters)
                      },
                    },
                  }}
                  onChange={(__, editor) => {
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      stationNewsContent: editor.getData(),
                    })
                  }}
                  onReady={(editor) => {
                    const data = form.getFieldValue('stationNewsContent')
                    if (data) {
                      editor.setData(data)
                    }

                    setCkeditor(editor)
                  }}
                />
              </div>
            </Form.Item>
          </div>
        </div>

        <div className='w-100 d-flex justify-content-center'>
          <div className='col-12 col-md-7'>
            <div className='new_component__modal_title mb-1'>
              <Space>
                {translation('listCustomers.category')}
                <Typography.Text type='danger'>*</Typography.Text>
              </Space>
            </div>
            <Form.Item
              name='stationNewsCategories'
              rules={[
                { required: true, message: translation('new.errorContent') },
              ]}
            >
              <Select
                className='new_component__modal_content'
                placeholder={translation('new.selectCategory')}
                options={options}
              />
            </Form.Item>
          </div>
        </div>

        <div className='w-100 d-flex justify-content-center'>
          <div className='col-12 col-md-7 new_component__modal_title'>
            <Space>
              {translation('new.addImage')}
              <Typography.Text type='danger'>*</Typography.Text>
            </Space>
          </div>
        </div>

        <div className='w-100 d-flex justify-content-center mb-2'>
          <div className='col-12 col-md-7'>
            <div className='col-12'>
              <Upload.Dragger
                id='image'
                showUploadList={false}
                accept='image/*'
                onChange={onSelectImage}
                listType='picture'
                beforeUpload={(file) => file['type'].includes('image/')}
              >
                <p className='ant-upload-drag-icon'>
                  {selectedImage.imageUrl ? (
                    <RetweetOutlined />
                  ) : (
                    <PlusOutlined />
                  )}
                </p>
                <p className='ant-upload-text'>
                  {selectedImage.imageUrl
                    ? translation('new.changeImage')
                    : translation('new.selectImage')}
                </p>
              </Upload.Dragger>
            </div>
            <Image
              src={selectedImage.imageUrl}
              preview={false}
              hidden={selectedImage.imageUrl ? false : true}
              className='new_component__preview_image col-12 col-md-4 mt-2'
            />
          </div>
        </div>

        <div className='w-100 d-flex justify-content-center mt-3'>
          <div className='col-12 col-md-7'>
            <Form.Item>
              <Button
                type='primary'
                className='w-100'
                size='large'
                htmlType='submit'
              >
                {selectedPost
                  ? translation('new.updatePost')
                  : translation('new.post')}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </main>
  )
}

export default PostNew
