import React, { useState, useRef, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import GraphTooltip from "./tooltip";
import * as sc from "./Chart.styled";
import Switcher from "../../assets/icons/switcher.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement
);

export const BarChart = (props) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPos, setTooltipPos] = useState(null);
  const [enter, setEnter] = useState(false);

  const chartRef = useRef(null);

  const customTooltip = useCallback((context) => {
    const chart = chartRef.current;
    const canvas = chart.canvas;

    if (canvas && enter) {
      setTooltipData(context.tooltip);
      // enable tooltip visibilty
      setTooltipVisible(true);

      // set position of tooltip
      const left = context.tooltip.x;
      const top = context.tooltip.y;

      // handle tooltip multiple rerender
      if (tooltipPos?.top != top) {
        setTooltipPos({ top: top, left: left });
      }
    }
  });

  if(!props.data) return <></>;
  const { data: chartData } = props ;

  let currentMonth = new Date().getMonth() + 1;
  let currentYear = new Date().getFullYear();
  const labels = [`T${currentMonth}`];
  const labelsWithYear = {};
  labelsWithYear[`T${currentMonth}`] = currentYear;

  for(let i = 0; i < 11; i ++) {
    if(currentMonth - 1 === 0) {
      currentMonth = 13;
      currentYear = currentYear - 1;
    }
    currentMonth = currentMonth - 1;
    labels.unshift(`T${currentMonth}`);
    labelsWithYear[`T${currentMonth}`] = currentYear;
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Số lượng khách hàng mới",
        data: chartData.map(item => item.new.value),
        backgroundColor: "#A23FC3",
        borderRadius: 4,
        // maxBarThickness: 18,
      },
      {
        label: "Số lượng khách hàng quay lại lần 2",
        data: chartData.map(item => item.returned.value),
        backgroundColor: "#00877C",
        borderRadius: 4,
        // maxBarThickness: 18,
      },
    ],
  };

  

  const options = {
    responsive: true,
    categoryPercentage: 0.4,
    maintainAspectRatio: false,
    // barPercentage: 0.1,
    scales: {
      y: {
        grid: {
          display: true,
          borderDash: [4, 8],
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: true,
        align: "start",
        labels: {
          boxWidth: 8,
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
        title: {
          padding: 300,
        },
      },
      title: {
        display: true,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: customTooltip,
      },
    },
  };

  const onMouseOut = (e) => {
    e.preventDefault();
    setTooltipVisible(false);
    setEnter(false);
  };

  const onMouseOver = () => {
    setEnter(true);
  };

  return (
    <>
      
      <sc.ChartContainer onMouseOver={onMouseOver} onMouseLeave={onMouseOut}>
        <sc.Header>
          <span><b>Biểu đồ thống kê</b></span>
        </sc.Header>

        <sc.ChartContent>
          <Bar
            options={{ ...options }}
            plugins={[
              {
                beforeInit: function (chart) {
                  // Get reference to the original fit function
                  const originalFit = chart.legend.fit;

                  // Override the fit function
                  chart.legend.fit = function fit() {
                    // Call original function and bind scope in order to use `this` correctly inside it
                    originalFit.bind(chart.legend)();
                    // Change the height as suggested in another answers
                    this.height += 35;
                  };
                },
              },
            ]}
            data={data}
            ref={chartRef}
            style={{ maxWidth: "908px", width: "100%", minHeight: "400px" }}
          />
          {tooltipVisible && (
            <GraphTooltip
              labelsWithYear={labelsWithYear}
              labels={labels}
              apiData={chartData}
              data={tooltipData}
              position={tooltipPos}
              visibility={tooltipVisible}
            />
          )}
        </sc.ChartContent>
      </sc.ChartContainer>

      <sc.MainDivider />
    </>
  );
};
