import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import './setting.scss'
import { useDispatch } from 'react-redux';
import { SETTING } from './../../constants/setting'
import { useSelector } from 'react-redux'
import InspectionProcessService from "./../../services/inspectionProcessService"
import UploadService from "./../../services/uploadService"
import { convertFileToBase64 } from '../../helper/common';
import { UPDATE } from '../../constants/introduction'
import {
  Input,
  Button,
  Upload,
  notification,
  Switch
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { IconCopy } from '../../assets/icons';
import { useHistory } from 'react-router-dom';
import uploadService from './../../services/uploadService';

const BLACK = "BLACK"
const BLUE = "BLUE"
const BLUE_SECOND = "BLUE_SECOND"
const BLUE_THIRD = "BLUE_THIRD"
const GREEN = "GREEN"

export default function SettingWeb({
  setLoading
}) {
  const { t: translation } = useTranslation()
  const setting = useSelector((state) => state.setting)
  const user = useSelector((state) => state.member)
  const stationsIntroduction = useSelector((state) => state.introduction)
  const dispatch = useDispatch()
  const history = useHistory()

  function handleUpdateSetting(data) {
    InspectionProcessService.updateById({ id: user.stationsId, data })
  }

  function handleUpdateIntroduction(data) {
    uploadService.updateStationIntroduction({ id: user.stationsId, data })
  }

  function handleOnChange(data) {
    dispatch({ type: SETTING, data: data })
  }

  const normFile = (e) => {
    const { file } = e
    if (file.status !== "uploading") {
      convertFileToBase64(file.originFileObj).then(dataUrl => {
        const newData = dataUrl.replace(/,/gi, '').split('base64')
        if (newData[1]) {
          const data = {
            imageData: newData[1],
            imageFormat: "png"
          }
          setLoading(true)
          UploadService.uploadImage(data).then(result => {
            if (result && result.issSuccess) {
              notification['success']({
                message: '',
                description: translation('inspectionProcess.updateSuccess')
              });
              const newData = { stationsLogo: result.data }
              handleOnChange(newData)
              handleUpdateSetting(newData)
            } else {
              notification['error']({
                message: '',
                description: translation('inspectionProcess.updateError')
              });
            }
            setLoading(false)
          })
        }
      })
    }

  };

  function handleCopyItem(id) {
    const copyText = document.getElementById(id);
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
    notification.info({
      message: "",
      description: translation('setting.copied')
    })
  }

  return (
    <div className="setting">
      <div className="row">
        <div className="col-12 col-md-4">
          <label>Slogan</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              placeholder="Slogan"
              value={stationsIntroduction.stationIntroductionSlogan}
              onChange={(e) => {
                dispatch({
                  type: UPDATE,
                  data: {
                    stationIntroductionSlogan: e.target.value
                  }
                })
              }}
              onBlur={(e) => {
                const { value } = e.target
                handleUpdateIntroduction({ stationIntroductionSlogan: value })
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>{translation('setting.service')}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              placeholder={translation('setting.service')}
              value={stationsIntroduction.stationIntroServices}
              onChange={(e) => {
                dispatch({
                  type: UPDATE,
                  data: {
                    stationIntroServices: e.target.value
                  }
                })
              }}
              onBlur={(e) => {
                const { value } = e.target
                handleUpdateIntroduction({ stationIntroServices: value })
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>{translation('setting.logo')}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Upload showUploadList={false} accept="image/*" onChange={normFile} name="logo" listType="picture">
              <Button type="primary">{translation('setting.upFile')} <UploadOutlined /></Button>
            </Upload>
          </div>
          {setting.stationsLogo && (
            <div className='mb-4'>
              <img width={200} src={setting.stationsLogo} />
            </div>
          )}
        </div>
        <div className="col-12 col-md-4">
          <label>{translation('setting.settingAdvertising')}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Button onClick={() => history.push('/edit-banner')} type="primary">{translation('setting.settingAdvertising')}</Button>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>{translation('setting.webImagesSetting')}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Button onClick={() => history.push('/edit-landing-page')} type="primary">{translation('setting.webImagesSetting')}</Button>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>{translation('setting.link')}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item position-relative">
            <Input
              placeholder={translation('setting.inputLink')}
              value={setting.stationUrl}
              id="stationUrl"
              onChange={() => { }}
              disabled
            />
            <div className="setting__copy_icon bg" />
            <IconCopy onClick={() => handleCopyItem("stationUrl")} className="setting__copy_icon" />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>Link webhook</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item position-relative">
            <Input
              placeholder={translation('setting.inputLink')}
              value={setting.stationWebhookUrl}
              id="stationWebhookUrl"
              onChange={() => { }}
              disabled
            />
            <div className="setting__copy_icon bg" />
            <IconCopy onClick={() => handleCopyItem("stationWebhookUrl")} className="setting__copy_icon" />
          </div>
        </div>
        <hr/>
        <div className="setting__hr"></div>
        <div className="row">
          <div className="col-12">
            <label>{translation('setting.color')}</label>
          </div>
          <div className="col-12 setting__inline">
            <div onClick={() => {
              handleUpdateSetting({ stationsColorset: BLACK })
              handleOnChange({ stationsColorset: BLACK })
            }} className={`setting__circle ${setting.stationsColorset === BLACK ? "selected" : ""} `}>
              <div className="setting__black" />
            </div>
            <div onClick={() => {
              handleUpdateSetting({ stationsColorset: BLUE })
              handleOnChange({ stationsColorset: BLUE })
            }} className={`setting__circle ${setting.stationsColorset === BLUE ? "selected" : ""} `}>
              <div className="setting__black setting__blue " />
            </div>
            <div onClick={() => {
              handleUpdateSetting({ stationsColorset: BLUE_SECOND })
              handleOnChange({ stationsColorset: BLUE_SECOND })
            }} className={`setting__circle ${setting.stationsColorset === BLUE_SECOND ? "selected" : ""} `}>
              <div className="setting__black setting__blue-second " />
            </div>
            <div onClick={() => {
              handleUpdateSetting({ stationsColorset: BLUE_THIRD })
              handleOnChange({ stationsColorset: BLUE_THIRD })
            }} className={`setting__circle ${setting.stationsColorset === BLUE_THIRD ? "selected" : ""} `}>
              <div className="setting__black setting__blue-third " />
            </div>
            <div onClick={() => {
              handleUpdateSetting({ stationsColorset: GREEN })
              handleOnChange({ stationsColorset: GREEN })
            }} className={`setting__circle ${setting.stationsColorset === GREEN ? "selected" : ""} `}>
              <div className="setting__black setting__green" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}