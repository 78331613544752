import Request from "./request"
export default class AccreditationService {
  static async getList(data = {}) {
    return new Promise(resolve=>{
      Request.send({
        method: 'POST',
        path: '/CustomerRecord/todayCustomerRecord',
        data
      }).then((result = {})=>{
        const { statusCode, data } = result
        if(statusCode === 200) {
          return resolve(data)
        }else{
          return resolve([])
        }
      })
    })
  }

  static async updateById(data = {}) {
    return new Promise(resolve=>{
      Request.send({
        method: 'POST',
        path: '/CustomerRecord/updateById',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
          return resolve({ issSuccess: true })
        }else{
          return resolve({ issSuccess: false })
        }
      })
    })
  }

  static async deleteById(data = {}) {
    return new Promise(resolve=>{
      Request.send({
        method: 'POST',
        path: '/CustomerRecord/deleteById',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
          return resolve({ issSuccess: true })
        }else{
          return resolve({ issSuccess: false })
        }
      })
    })
  }

  static async createNewCustomer(data = {}) {
    return new Promise(resolve=>{
      Request.send({
        method: 'POST',
        path: '/CustomerRecord/insert',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
          return resolve({ issSuccess: true })
        }else{
          return resolve({ issSuccess: false })
        }
      })
    })
  }
}