
import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { SETTING } from './constants/setting'
import Layout from './components/Layout'
import Login from "./../src/Page/Login"
import ForgetPass from "./../src/Page/ForgetPass"
import AddBooking from "./Page/AddBooking"
import CustomerSchedule from "./Page/Schedule"
import ListCustomer from './Page/ListCustomers'
import Accreditation from "./../src/Page/Accreditation/listAccreditation"
import AccreditationPublic from "./../src/Page/AccreditationPublic"
import ListEditAccreditation from '../src/Page/Accreditation/listEditAccreditation'
import Setting from "./../src/Page/Setting"
import { useSelector } from 'react-redux'
import InspectionProcessService from "./services/inspectionProcessService"
import LoginService from "../src/services/loginService";
import NewPublic from "../src/Page/NewPublic";
import Statistical from "./Page/Statistical";
import 'antd/dist/antd.css'; 
import New from "../src/Page/New"
import './common.scss'
import './inputCommon.scss'
import './selectCommon.scss'
import './tableCommon.scss'
import './modalCommon.scss'
import './buttonCommon.scss'
import './dropDownCommon.scss'
import { notification } from "antd";
import CreateNewCustomer from "Page/Accreditation/createNewCustomer";
import TwoFAPage from "Page/Login/twoFAPage";
import LandingPage from "Page/Login/landingPage";
import SettingLandingPage from "Page/Setting/settingLandingPage";
import InspectionProcess from "Page/InspectionProcess";
import ManagementPage from "Page/Management";
import EditBanner from "Page/Setting/editBanner";
import { LIST_PERMISSION } from "constants/permission";
import PublicPunish from "Page/Punish/publicPunish";
import AuthPunish from "Page/Punish/authPunish";
import { INIT } from "constants/introduction";

export const routes = {
  login: {
    path: "/login",
    component: Login,
    isAuth: false
  },
  public_new: {
    path: '/new-public',
    component: NewPublic,
    isAuth: false
  },
  twoFAPage: {
    path: "/verifying-user",
    component: TwoFAPage,
    isAuth: false
  },
  forgotPass:  {
    path: "/forgot-password",
    component: ForgetPass,
    isAuth: false
  },
  bookingSchedule: {
    path: "/booking-schedule",
    component: AddBooking,
    isAuth: false
  },
  customerSchedule: {
    path: '/schedules',
    component: CustomerSchedule,
    isAuth: true
  },
  listCustomer: {
    path: '/list-customers',
    component: ListCustomer,
    isAuth: true
  },
  listDetailAccreditation: {
    path: "/list-detail-accreditation",
    component: ListEditAccreditation,
    isAuth: true
  },
  accreditation: {
    path: "/accreditation",
    component: Accreditation,
    isAuth: true
  },
  setting: {
    path: "/setting",
    component: Setting,
    isAuth: true
  },
  news: {
    path: "/news",
    component: New,
    isAuth: true
  },
  statistical: {
    path: "/statistical",
    isAuth: true,
    component: Statistical
  },
  createCustomer: {
    path: "/create-customer",
    isAuth: true,
    component: CreateNewCustomer
  },
  inspectionProcess :{
    path: "/inspection-process",
    isAuth: true,
    component: InspectionProcess
  },
  management :{
    path: "/management",
    isAuth: true,
    component: ManagementPage
  },
  punishPublic: {
    path: '/public-punish',
    isAuth: false,
    component: PublicPunish
  },
  punishAuth: {
    path: "/auth-punish",
    isAuth: true,
    component: AuthPunish
  }
}

function renderRoutes(permission, roleId) {
  let render = {
    login: routes.login,
    public_new: routes.public_new,
    twoFAPage: routes.twoFAPage,
    punishPublic: routes.punishPublic,
    bookingSchedule: routes.bookingSchedule,
    statistical: routes.statistical
  }
  
  if(roleId === 1) return {
    ...routes,
    home: routes.accreditation
  }

  if(!permission) {
    return render
  }
  render.home = undefined
  if(permission.includes(LIST_PERMISSION.MANAGE_RECORD)) {
    render.accreditation = routes.accreditation
    render.listDetailAccreditation = routes.listDetailAccreditation
    render.inspectionProcess = routes.inspectionProcess
    render.createCustomer = routes.createCustomer
    render.punishAuth = routes.punishAuth
    render.home = routes.accreditation
  }
  if(permission.includes(LIST_PERMISSION.MANAGE_CUSTOMER)) {
    render.listCustomer = routes.listCustomer
    if(render.home === undefined) render.home = routes.listCustomer
  }
  if(permission.includes(LIST_PERMISSION.MANAGE_SCHEDULE)) {
    render.customerSchedule = routes.customerSchedule
    if(render.home === undefined) render.home = routes.customerSchedule
  }
  if(permission.includes(LIST_PERMISSION.MANAGE_NEWS)) {
    render.news = routes.news
    if(render.home === undefined) render.home = routes.news
  }
  if(permission.includes(LIST_PERMISSION.MANAGE_SETTINGS)) {
    render.setting = routes.setting
    if(render.home === undefined) render.home = routes.setting
  }
  if(permission.includes(LIST_PERMISSION.MANAGE_APP_USER)) {
    render.management = routes.management
    if(render.home === undefined) render.home = routes.management
  }

  return render;

}

function App() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.member)
  const { isUserLoggedIn, permissions, appUserRoleId } = user
  const stationsColorset = useSelector((state) => state.setting ? state.setting.stationsColorset : "")
  const mainRoutes = renderRoutes(permissions, appUserRoleId)

  useEffect(()=>{
    const DOMAIN = window.origin.split('://')[1]
    LoginService.getLandingPageInfo(DOMAIN).then(result => {
			if (result) {
				dispatch({ type: INIT, data: result});
			}
		})

    if(user.stationsId){
      InspectionProcessService.getDetailById({ id: user.stationsId }).then(result=>{
        if(result){
          dispatch({ type: SETTING, data: result})
        }
      })
    } else {
      const DOMAIN = window.origin.split('://')[1]
      LoginService.getDetailByUrl(DOMAIN).then(result => {
        if(result){
          dispatch({ type: SETTING, data: result})
        }
      })
    }
  },[user.stationsId])  


  notification.config({
    closeIcon: () => {},
    duration: 5
  })

  return (
    <div className={stationsColorset}>
      <Router>
        <Switch>
          {(!user || !isUserLoggedIn) ? (
            <Route path="/" exact component={props => <Layout {...props} Component={LandingPage} Features="LOGIN" />} />
          ) : (
            <Route path='/' exact component={(props) => <Layout  {...props} Component={mainRoutes.home.component} Features="HOME" />} />
          )}
          <Route path='/edit-banner' component={EditBanner}/>
          <Route path={"/accreditation-public"}  component={(props) =>  <AccreditationPublic {...props} />} /> 
          <Route path="/edit-landing-page" component={SettingLandingPage} />
          {Object.keys(mainRoutes).map((key, index)=>{
            if(isUserLoggedIn && mainRoutes[key].isAuth){
              return <Route key={index} extract path={mainRoutes[key].path} component={(props) => <Layout  {...props} Component={mainRoutes[key].component}  Features="HOME" />} />
            }else if( !mainRoutes[key].isAuth ){
              return <Route key={index} extract path={mainRoutes[key].path} component={(props) => <Layout  {...props} Component={mainRoutes[key].component} Features="LOGIN"/> }/> 
            }
          })}
          {/* {isUserLoggedIn ? <Route path='/' component={(props) => <Layout  {...props} Component={mainRoutes.home.component} Features="HOME" />} />
            : <Route component={(props) =>  <Layout Features="LOGIN"  {...props} Component={mainRoutes.login.component}/>} /> } */}
        </Switch>
      </Router>

    </div>
  );
}

export default App
