export const listTimes = (translation) => ([
    translation('listSchedules.allTimeline'),
    '7h - 7h30',
    '7h30 - 8h',
    '8h - 8h30',
    '8h30 - 9h',
    '9h30 - 10h',
    '10h - 10h30',
    '10h30 - 11h',
    '11h - 11h30',
    '11h30 - 12h',
    '12h - 12h30',
    '12h30 - 13h',
    '13h30 - 14h',
    '14h - 14h30',
    '14h30 - 15h',
    '15h - 15h30',
    '15h30 - 16h',
    '16h - 16h30',
    '16h30 - 17h',
    '17h - 17h30',
    '17h30 - 18h',
])