import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import './addBooking.scss'
import { DatePicker, Button, Input, Select, notification, Form, Radio, Card } from 'antd';
import { validateEmail } from '../../helper/common';
import AddBookingService from '../../services/addBookingService'
import AddBookingSuccessImage from "./../../assets/icons/addBookingSuccess.png"
import moment from 'moment';
import ReCAPTCHA from "react-google-recaptcha";
import LoginService from '../../services/loginService';
import _ from 'lodash';
// import _verifyCarNumber from 'constants/licenseplates';

const { Option } = Select

const INFO_URL = "http://thongtinphatnguoi.com/"

function Booking(props) {
    const { t:translation } = useTranslation()
    const [isAddBookingSuccess, setIsAddBookingSuccess] = useState(false)
    const [ dataCaptcha, setDataCaptcha ] = useState({})
    const [ notificationMethod , setNotificationMethod ]= useState("Email")
    const [form] = Form.useForm()
    const [stationData, setStationData] = useState({})

    const onFinish = (values) => {
        if( notificationMethod==="Email" && !validateEmail(values.email)) {
            notification['error']({
                message: '',
                description: translation('landing.invalidEmail')
            });
            return
        }
        if(notificationMethod==="SMS"  &&values.phone.length < 10) {
            notification['error']({
                message: '',
                description: translation('landing.invalidPhone')
            });
            return
        }

        // if(values.licensePlates && !_verifyCarNumber(values.licensePlates)) {
        //     notification['error']({
        //         message: '',
        //         description: translation('landing.invalidLicensePlate')
        //     });
        //     return
        // }

        if(!dataCaptcha.value){
            setDataCaptcha({
                message: translation('landing.captcha')
            })
        }else{
            const newDate = {
                notificationMethod,
                ...values,
            }
            const DOMAIN = window.origin.split('://')[1]
            AddBookingService.AddBooking({
                ...newDate,
                licensePlates: values.licensePlates.toUpperCase(),
                dateSchedule: moment(values.dateSchedule).format("DD/MM/YYYY"),
                stationUrl: DOMAIN
            }).then(result => {
                if (result && result.issSuccess) {
                    setIsAddBookingSuccess(true)
                    setDataCaptcha({})
                } else {
                    notification['error']({
                        message: '',
                        description: translation('landing.sendFailed')
                    });
                }
            })
        }
       
    }

    useEffect(() => {
        const DOMAIN = window.origin.split("://")[1]
        LoginService.getDetailByUrl(DOMAIN).then(result => {
            if(result && !_.isEmpty(result)) {
                setStationData(result)
            }
        })
    },[])

    function handleRefresh() {
        form.resetFields()
        setIsAddBookingSuccess(false)
    }
	
    return (
        <main  className="booking_main">
        <div  className="booking-form">
                {!isAddBookingSuccess ? <>
                <div className="booking-title">{translation('landing.booking-title')}</div>
                    <Form
                        form={form}
                        name="addBooking"
                        autoComplete="off"
                        onFinish={onFinish}
                        onFinishFailed={()=>{
                            if(!dataCaptcha.value){
                                setDataCaptcha({
                                    message: translation('landing.captcha')
                                })
                            }
                        }}
                    >
                    <div className="row d-flex justify-content-center">
                        <Form.Item
                            name="fullnameSchedule"
                            className="col-12 col-md-6 col-lg-5"  
                        >
                            <Input
                                type="text"
                                size="large"
                                placeholder={translation('landing.fullname')}
                            />
                        </Form.Item>
                    </div>
                    
                    <div className="row d-flex justify-content-center">
                        <Form.Item
                            name="licensePlates"
                            rules={[
                                {
                                    required: true,
                                    message: translation('landing.invalidLicensePlate')
                                }
                            ]}
                            className="col-12 col-md-6 col-lg-5"  
                        >
                            <Input
                                type="text"
                                size="large"
                                placeholder={`${translation('landing.license-plates')}(*)`}
                            />
                        </Form.Item>
                    </div>
                    
                                        
                    <div className="row d-flex justify-content-center">
                        <Form.Item
                            name="phone"
                            rules={ notificationMethod ==="SMS" ?[
                                {
                                    required: true,
                                    message: translation('landing.invalidPhone')
                                }
                            ]: []}
                            className="col-12 col-md-6 col-lg-5"  
                        >
                            <Input 
                                type="text"
                                size="large"
                                placeholder={translation('landing.phoneNumber')}
                            />
                        </Form.Item>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <Form.Item
                            name="email"
                            rules={notificationMethod ==="Email" ?[
                                {
                                    required: true,
                                    message: translation('landing.invalidEmail'),
                                }
                            ]: []}
                            className="col-12 col-md-6 col-lg-5"  
                        >
                            <Input
                                type="text"
                                size="large"
                                placeholder="Email(*)"
                            />
                        </Form.Item>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <Form.Item
                            name="dateSchedule"
                            rules={[
                                {
                                    required: true,
                                    message: translation('landing.invalidDate'),
                                }
                            ]}
                            className="col-12 col-md-6 col-lg-5"  
                        >
                            
                            <DatePicker
                                placeholder={`${translation('landing.select-date')}(*)`}
                                format="DD/MM/YYYY"
                                size="large"
                                style={{width: "100%"}}
                            />
                        </Form.Item>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <Form.Item
                            name="time"        
                            className="col-12 col-md-6 col-lg-5"                   
                        >
                            <Select
                                size='middle'
                                placeholder={translation('landing.select-time')}
                                size="large"
                            >
                                {stationData && !_.isEmpty(stationData) 
                                && stationData.stationBookingConfig  
                                && stationData.stationBookingConfig.length > 0 
                                && stationData.stationBookingConfig.map((time, i) => {
                                   return (
                                        time.limit !== 0 &&
                                            <Option value={time.time} key={time.time}>{time.time}</Option>
                                   )
                                })}
                            </Select>
                        </Form.Item>
                    </div>

                        <div hidden className="ant-col ant-form-item-control">
                            <Radio.Group onChange={(e) =>{
                                const value = e.target.value
                                setNotificationMethod(value)
                            }} value={notificationMethod}>
                              <Radio value="SMS">SMS</Radio>
                              <Radio value="Email">EMAIL</Radio>                    
                            </Radio.Group>
                            <div className="ant-form-item-explain ant-form-item-explain-error"/>
                        </div>
                        
                        
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-6 col-lg-5 p-0">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          onChange={(value)=>{
                            setDataCaptcha({ value, message: null})
                          }}
                        />
                        {
                            dataCaptcha.message ? (
                            <div className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{dataCaptcha.message}</div>
                            </div>
                            ) :null                    
                        }
                        </div>
                    </div>   
                        <div className="w-100 d-flex justify-content-center p-4">
                        <Form.Item>
                            <Button
                                className="blue_button"
                                data-loading-text={translation('landing.processing')}
                                size="large"
                                htmlType="submit"
                            >
                                {translation('landing.send')}
                            </Button>
                        </Form.Item>
                        </div>
                        </Form>
                </> : (
                    <>
                        <div className="add-booking-success">
                        <div className="w-100">
                                <div className="d-flex justify-content-center">
                                    <img src={AddBookingSuccessImage} className="add-booking-success-icon"/>
                                </div>
                            </div>
                            <Card
                                className="mb-3"
                            >
                                <div 
                                    dangerouslySetInnerHTML={{
                                        __html: translation('addBookingSuccess.title', 
                                        { name: form.getFieldValue('fullnameSchedule')})
                                    }}
                                />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: translation('addBookingSuccess.firstly', 
                                        { 
                                            stationName: stationData.stationsName,
                                            bookingTime: form.getFieldValue('time'),
                                            stationAddress: stationData.stationsAddress
                                        }
                                    )}}
                                />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: translation('addBookingSuccess.secondly')
                                    }}
                                    className="mt-5"
                                />
                                <div>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: translation('addBookingSuccess.info1')
                                        }}
                                    />
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: translation('addBookingSuccess.info2')
                                        }}
                                    />
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: translation('addBookingSuccess.info3')
                                        }}
                                    />
                                </div>
                                <div className="mt-3" hidden>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: translation('addBookingSuccess.thirdly')}}
                                        className="d-flex"
                                    />
                                    <span className="add-booking-success_link" onClick={() => window.open(INFO_URL, '_blank')}>{INFO_URL}</span>
                                </div>
                                <div 
                                    dangerouslySetInnerHTML={{
                                        __html: translation('addBookingSuccess.thanks')
                                    }}
                                    className="mt-3"
                                />
                                <div className="w-100">
                                    <div className="d-flex justify-content-end">
                                        <div className="hotline">Hotline: {stationData.stationsHotline}</div>
                                    </div>
                                </div>
                                <Button type="primary" onClick={handleRefresh}>
                                    {translation('addBookingSuccess.goBack')}
                                </Button>
                            </Card>
                        </div>
                    </>
                )
                }
        </div>
        </main>
    )
}
export default Booking;

