import Request from './request'

export default class StatisticalService {
  static async getStatistical(data = {}) {
    return new Promise(resolve => {
        Request.send({
            method: 'POST',
            path: '/CustomerStatistical/report',
            data
        }).then((result = {})=>{
            const { statusCode, data } = result
            if(statusCode === 200) {
                return resolve(data)
            } else {
                return resolve(null)
            }
        })
    })
}
}