import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Table, notification, Button, Tooltip } from 'antd';
import { IconCar } from "../../assets/icons"
import InspectionProcessService from "./../../services/inspectionProcessService"
import AccreditationService from "./../../services/accreditationService"
import moment from 'moment';
import mqtt from 'mqtt';
import AccreditationTabs from '../../components/AccreditationTabs';
import { 
  AccreditationNotificationKey,
  InspectionProcessKey,
  CreateNewCustomerKey,
  ListEditAccreditationKey,
  PunishKey,
  ListAccreditationKey
} from 'constants/accreditationTabs';
import { useHistory } from 'react-router-dom';
import { ModalCrime } from 'Page/ListCustomers/ModalCrime';
import { ExceptionOutlined } from '@ant-design/icons';

const SIZE = 400
const DEFAULT_FILTER = { filter: {}, skip: 0, limit: SIZE, startDate: moment().format("DD/MM/YYYY"), endDate: moment().format("DD/MM/YYYY") }

function ListAccreditation() {
  // const { addData, updateData, deleteData } = props
  const [client, setClient] = useState(null);
  const { t:translation } = useTranslation()
  const [stationCheckingConfig, setStationCheckingConfig] = useState([])
  const [dataAccreditation, setDataAccreditation] = useState({
    total: 0,
    data: []
  })
  const [crimePlateNumber, setCrimePlateNumber] = useState('')
  const history = useHistory()
  
  const [dataFilter, setDataFilter] = useState(DEFAULT_FILTER)
  const user = useSelector((state) => state.member)

  function handleUpdateNewData(updateData) {
    setDataAccreditation(prevData => {
        const index = prevData.data.findIndex(item=> item.customerRecordId === updateData.customerRecordId)   
        if(index !==-1) {
          prevData.data[index] = updateData
          return ({
            total: prevData.total,
            data: [
              ...prevData.data
            ]
          })
      }
    })   
  }

  function handleAddNewData(addData){ 
    if(addData){
      setDataAccreditation(prevData => {
        const index = prevData.data.findIndex((item ) => item.customerRecordId === addData.customerRecordId)
        if(index === -1) {
          const newData = []
          prevData.data.forEach(item=>{
            newData.push({
              ...item,
              isAdd: false
            })
          })
          if(newData.length > SIZE){
            newData.pop()
          }
          return ({
            total: prevData.total + 1,
            data: [
              {
                ...addData,
                isAdd: true
              },
              ...newData
            ]
          });
        }
      })
    }   
  }

  function handleDeleteData(deleteData) { 
    if(deleteData){
      setDataAccreditation(prevData => {
        const newData = prevData.data.filter(item => item.customerRecordId !== deleteData.customerRecordId)
        return ({
          total: prevData.total - 1,
          data: newData
        })
      })
    }
  }

  
  const mqttConnect = (host, mqttOption) => {
    setClient(mqtt.connect(host, mqttOption));
  };

  useEffect(() => {
    if (client) {
      
      client.on('connect', () => {
        console.log('connect',3242)
      });
      client.on('error', (err) => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => {
        console.log('Reconnecting')
      });
      client.on('message', (topic, message) => {      
        const payload = { topic, message: message.toString() };
        if(topic === `RECORD_UPDATE_${user.stationsId}`) {         
          const newData = JSON.parse(`${payload.message}`)
          handleUpdateNewData(newData)
        }
        if(topic === `RECORD_ADD_${user.stationsId}`) {         
          const newData = JSON.parse(`${payload.message}`)
          handleAddNewData(newData)
        }
        if(topic === `RECORD_DELETE_${user.stationsId}`) {
          const newData = JSON.parse(`${payload.message}`)
          handleDeleteData(newData)
        }
      });
      mqttSub({
        topic: `RECORD_UPDATE_${user.stationsId}`, qos: 2
      })
      mqttSub({
        topic: `RECORD_ADD_${user.stationsId}`, qos: 2
      })
      mqttSub({
        topic: `RECORD_DELETE_${user.stationsId}`, qos: 2
      })
      return ()=>{
        mqttUnSub({
          topic: `RECORD_UPDATE_${user.stationsId}`
        }) 
        mqttSub({
          topic: `RECORD_ADD_${user.stationsId}`, qos: 2
        })
        mqttUnSub({
          topic: `RECORD_DELETE_${user.stationsId}`, qos: 2
        })
      }
    }
    
  }, [client]);

  const mqttDisconnect = () => {
    if (client) {
      client.end(() => {
        console.log('end')
      });
    }
  }

  const mqttSub = (subscription) => {
    if (client) {
      const { topic, qos } = subscription;
      
      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
      });
    }
  };

  const mqttUnSub = (subscription) => {
    if (client) {
      const { topic } = subscription;
      client.unsubscribe(topic, error => {
        if (error) {
          console.log('Unsubscribe error', error)
          return
        }
        
      });
    }
  };

  useEffect(()=>{
    handleConnect()
    return ()=>{
      handleDisconnect()
    }
  }, [])

  const handleConnect = () => {
    let url = process.env.REACT_APP_API_WSS_URL
    
    if (window.location.protocol !== "https:") {
       url = process.env.REACT_APP_API_WS_URL
   }
    
    const clientId = `RECORD_UPDATE_DK_${user.stationsId}_${moment().format("YYYY_MM_DD_hh_mm_ss")}`
    
    const options = {
      reconnectPeriod: 10000,   
      rejectUnauthorized: false
    };
    options.clientId = clientId;

    mqttConnect(url, options);
  };

  const handleDisconnect = () => {
    mqttDisconnect();
  };


  function handleFetchAccreditation(filter){
    AccreditationService.getList(filter).then(result=>{
      if(result){
        setDataAccreditation({
          ...result
        })
      }
    })
  }

  function handleUpdateById(data) {
    AccreditationService.updateById(data).then(result=>{
      if(result && !result.issSuccess){
        notification['error']({
          message: '',
          description: translation('accreditation.updateError')
        });
      }
      handleFetchAccreditation(dataFilter)
    })
  }

  useEffect(()=>{
    InspectionProcessService.getDetailById({ id: user.stationsId }).then(result=>{
      if(result && result.stationCheckingConfig){
        setStationCheckingConfig(result.stationCheckingConfig)
      }
    })
    handleFetchAccreditation(dataFilter)
  },[])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'name',
      width: 50,
      render: (_, __, index)=>{
        return dataFilter.skip ? dataAccreditation.total - ( dataFilter.skip + index ): dataAccreditation.total  - (index)
      }
    },
    {
      title: translation("accreditation.licensePlates"),
      dataIndex: 'customerRecordPlatenumber',
      key: 'customerRecordPlatenumber',
      width: 100,
      render: (_,record) => {
        const { customerRecordPlatenumber, hasCrime } = record
        if (!hasCrime) {
          return  <span className="accreditation__licenplates">{customerRecordPlatenumber}</span>
        }
        return (
          <div className='d-flex justify-content-between align-items-center'>
            <span className="accreditation__licenplates">{customerRecordPlatenumber}</span>
            <Tooltip title={translation('punish.notification')}>
              <ExceptionOutlined
                  style={{
                      color: 'var(--bs-warning)',
                      fontSize: '1.2rem',
                      cursor: 'pointer',
                  }}
                  onClick={() => setCrimePlateNumber(customerRecordPlatenumber)}
              />
            </Tooltip>
          </div>
        )
      }
    }
  ];

  const columnsV1 =[...columns]
  stationCheckingConfig.forEach(item=>{
    columnsV1.push({
      title: item.stepLabel,
      dataIndex: item.stepIndex,
      key: item.stepLabel,
      className: "accreditation__center",
      render: (_, record)=>{
        const { customerRecordId } = record

        return <div 
          onClick={() => {
            handleUpdateById({
              id: customerRecordId,
              data: {
                customerRecordState: item.stepIndex 
              }
            })
          }} 
          className="accreditation"
        >
          {item.stepIndex === record.customerRecordState ?<> <IconCar height="30px" width="30px" className="accreditation__circle-active"></IconCar></>:  <div className={`accreditation__circle`}></div>}
         
        </div>
      }
    })
  })

	return (
    <>
    <AccreditationTabs
      ListAccreditation={() => (
        <Table 
          className='noselect'
          dataSource={dataAccreditation.data}
          rowClassName={(record, index) => `${record &&record.isAdd ? "edited-row__add" : ""}`}  
          columns={columnsV1}
          scroll={{x: 1200}}
          pagination={{
            position: ['bottomCenter'],
            total: dataAccreditation.total,
            pageSize	: SIZE,
            current: dataFilter.skip ? (dataFilter.skip / dataFilter.limit) + 1 : 1
          }}
          onChange={({current, pageSize	})=>{
            dataFilter.skip = (current -1) * pageSize	
            setDataFilter({...dataFilter})
            handleFetchAccreditation(dataFilter)
          }}
        />
      )}
      onChangeTabs={(key)=> {
        if(key === AccreditationNotificationKey) {
          window.open("/accreditation-public", "_blank")
        } else if(key===CreateNewCustomerKey){
          window.open("/create-customer", "_blank")
        } else if(key === ListEditAccreditationKey) {
          history.push('/list-detail-accreditation')
        } else if(key === InspectionProcessKey){
          history.push("/inspection-process")
        }  else if(key === PunishKey) {
          history.push('/auth-punish')
        } else {
  
        }
      }}
      activeKey={ListAccreditationKey}
      ListEditAccreditation={null}
      InspectionProcess={null}
      CreateNewCustomer={null}
      AccreditationNotification={null}
    />
    {!!crimePlateNumber && <ModalCrime plateNumber={crimePlateNumber} onClose={() => setCrimePlateNumber('')} />}
   </>
	)
}
export default ListAccreditation;