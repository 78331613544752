// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { 
  Table, Form, notification,
  Input, Button, Modal, 
  DatePicker, InputNumber, Select, 
  Tabs, Space, Tooltip
} from 'antd';
import EditableCell from "../../components/EditableCell"
import EditableRow from "../../components/EditableRow"
import AccreditationService from "./../../services/accreditationService"
import { 
  DeleteOutlined, PlusOutlined,
  ReloadOutlined, ExclamationCircleOutlined, ExceptionOutlined
} from '@ant-design/icons';
import { IconCar } from "../../assets/icons"
import "./accreditation.scss"
import moment from 'moment';
import mqtt from 'mqtt'
// import VerifyLicenPlates from '../../constants/licenseplates';
import _ from 'lodash';
import { useHistory } from 'react-router';
import {
  ListAccreditationKey,
  ListEditAccreditationKey,
  CreateNewCustomerKey,
  AccreditationNotificationKey,
  InspectionProcessKey,
  PunishKey
} from '../../constants/accreditationTabs';
import { ModalCrime } from 'Page/ListCustomers/ModalCrime';

const { TabPane } = Tabs
const SIZE = 400
const DEFAULT_FILTER = { filter: {}, skip: 0, limit: SIZE, startDate: moment().format("DD/MM/YYYY"), endDate: moment().format("DD/MM/YYYY") }

function ListEditAccreditation() {
  const { t: translation } = useTranslation()
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const [isCreate, setIsCreate] = useState(false)
  const [dataAccreditation, setDataAccreditation] = useState({
    total: 0,
    data: []
  })
  const [client, setClient] = useState(null)
  const [crimePlateNumber, setCrimePlateNumber] = useState('')
  const inputRef = useRef(null)
  const OptionsColor = [
    {
      value: "white",
      lable: <div className="accreditation__parent">
        {translation("accreditation.white")}

        <IconCar className=" accreditation__circle-white" />

      </div>
    },
    {
      value: "blue",
      lable: <div className="accreditation__parent">
        {translation("accreditation.blue")}

        <IconCar className=" accreditation__circle-blue" />

      </div>
    },
    // {
    //   value: "red",
    //   lable: <div className="accreditation__parent">
    //   {translation("accreditation.red")}

    //   <IconCar className=" accreditation__circle-red"/>

    // </div>
    // },
    {
      value: "yellow",
      lable: <div className="accreditation__parent">
        {translation("accreditation.yellow")}

        <IconCar className=" accreditation__circle-yellow" />

      </div>
    }
  ]
  const [dataFilter, setDataFilter] = useState(DEFAULT_FILTER)
  const user = useSelector((state) => state.member)
  const history = useHistory()
  const setting = useSelector(state => state.setting)
  const settingAccreditationTabs = setting.settingAccreditationTabs || ""

  function isHiddenTabs (value) {
    if (settingAccreditationTabs.includes(value)) {
      return true
    }
    return false
  }

  function handleFetchAccreditation(filter) {
    AccreditationService.getList(filter).then(result => {
      if (result) {
        setDataAccreditation({
          ...result
        })
      }
    })
  }

  function handleUpdateById(data, isReload) {
    AccreditationService.updateById(data).then(result => {
      if (result) {
        if (result.issSuccess) {
          // form.resetFields()
          isReload && handleFetchAccreditation(dataFilter)
        }
      } else {
        notification['error']({
          message: '',
          description: translation('accreditation.updateError')
        });
      }
    })
  }

  function handleDeleteById(data) {
    AccreditationService.deleteById(data).then(result => {
      if (result && result.issSuccess) {
        // notification['success']({
        //   message: '',
        //   description: translation('accreditation.updateSuccess')
        // });
        handleFetchAccreditation(dataFilter)
      } else {
        notification['error']({
          message: '',
          description: translation('accreditation.updateError')
        });
      }
    })
  }

  useEffect(() => {
    handleFetchAccreditation(dataFilter)
  }, [])

  function handleChangeRow(record, currentIndex) {
    dataAccreditation.data[currentIndex - 1] = record
    setDataAccreditation({ ...dataAccreditation })
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'name',
      width: 50,
      render: (_, __, index) => {
        return dataFilter.skip ? dataAccreditation.total - (dataFilter.skip + index) : dataAccreditation.total - (index)
      }
    },
    {
      title: translation("accreditation.licensePlates"),
      dataIndex: 'customerRecordPlatenumber',
      key: 'customerRecordPlatenumber',
      editable: true,
      width: 150,
      render: (_, record) => {
        const { customerRecordPlatenumber, hasCrime } = record
        if (!hasCrime) {
          return <span className={`accreditation__licenplates LP`}>{customerRecordPlatenumber.toUpperCase()}</span>
        }
        return (
          <div className='d-flex justify-content-between align-items-center' style={{ flex: 1}}>
            <span className={`accreditation__licenplates LP`}>{customerRecordPlatenumber.toUpperCase()}</span>
            <Tooltip title={translation('punish.notification')}>
              <ExceptionOutlined
                  style={{
                      color: 'var(--bs-warning)',
                      fontSize: '1.2rem',
                      cursor: 'pointer',
                  }}
                  onClick={() => setCrimePlateNumber(customerRecordPlatenumber)}
              />
            </Tooltip>
          </div>
        )
      }
    }
  ];

  const components = {
    body: {
      row: (props) => <EditableRow {...props} form={form} />,
      cell: (props) => <EditableCell {...props} form={form} />,
    },
  };

  // let validateColor = (customerRecordPlatenumber) => {
  //   customerRecordPlatenumber = customerRecordPlatenumber.trim()
  //   let length = customerRecordPlatenumber.length
  //   //get last 2 character
  //   let test = customerRecordPlatenumber[length - 2] + customerRecordPlatenumber[length - 1]
  //   const REAL_LENGTH = 2
  //   let customerRecordPlateColor = OptionsColor[0].value
  //   //validate if user type V / X / T
  //   if(REAL_LENGTH !== parseInt(test).toString().length) {
  //     if(test[1].toUpperCase() === 'X') {
  //       customerRecordPlateColor = OptionsColor[1].value
  //     } else if(test[1].toUpperCase() === 'V') {
  //       customerRecordPlateColor = OptionsColor[2].value
  //     } else {
  //       customerRecordPlateColor = OptionsColor[0].value
  //     }
  //     customerRecordPlatenumber = customerRecordPlatenumber.substring(0, length-1)
  //   } else {
  //     //default not type => T => white
  //     customerRecordPlateColor = OptionsColor[0].value
  //   }

  //   return {
  //     customerRecordPlateColor : customerRecordPlateColor,
  //     customerRecordPlatenumber: customerRecordPlatenumber.toUpperCase()
  //   }
  // }

  function handleSave(row, key, isReload) {
    //   notification.error({
    //     message: '',
    //     description: translation('landing.invalidLicensePlate')
    //   })
    //   return false
    const { customerRecordId } = row
    // if(key === "customerRecordPlatenumber") {
    //   let renderLP = validateColor(customerRecordPlatenumber)
    //   handleUpdateById({
    //     id: customerRecordId,
    //     data: {
    //       customerRecordPlateColor: renderLP.customerRecordPlateColor,
    //       customerRecordPlatenumber: renderLP.customerRecordPlatenumber
    //     }
    //   })
    //   return true
    // } else {
    let customerRecordCheckExpiredDate = ''
    if (key === "customerRecordCheckDuration") {
      customerRecordCheckExpiredDate = moment(row.customerRecordCheckDate, "DD/MM/YYYY").add(Number(row.customerRecordCheckDuration), 'months').subtract(1, 'day').format("DD/MM/YYYY")
      handleUpdateById({
        id: customerRecordId,
        data: {
          [key]: row[key],
          customerRecordCheckExpiredDate: customerRecordCheckExpiredDate
        }
      }, isReload)
    } else {
      handleUpdateById({
        id: customerRecordId,
        data: {
          [key]: row[key]
        }
      }, isReload)
    }
    // }
  };

  const columnsV2 = [
    ...columns,
    {
      title: translation("accreditation.image"),
      dataIndex: 'customerRecordPlateImageUrl',
      key: 'customerRecordPlateImageUrl',
      width: 150,
      className: "accreditation__center",
      render: (_, record) => {
        return <div className="accreditation">
          {
            record && record.customerRecordPlateImageUrl ? (
              <img className="accreditation__img" src={record.customerRecordPlateImageUrl} ></img>
            ) : <div className="accreditation__img" />
          }
        </div>
      }
    },
    {
      title: translation("accreditation.fullName"),
      dataIndex: 'customerRecordFullName',
      key: 'customerRecordFullName',
      editable: true,
      width: 200,
      render: (_, record) => {
        const value = record && record.customerRecordFullName ? (
          record.customerRecordFullName
        ) : translation("accreditation.fullName")
        return <div>{value}</div>
      }
    },
    {
      title: translation("accreditation.phoneNumber"),
      dataIndex: 'customerRecordPhone',
      key: 'customerRecordPhone',
      editable: true,
      width: 150,
      render: (_, record) => {
        const value = record && record.customerRecordPhone ? (
          record.customerRecordPhone
        ) : translation("accreditation.phoneNumber")
        return <div>{value}</div>
      }
    },
    {
      title: translation("accreditation.inspectionCycle"),
      dataIndex: 'customerRecordCheckDuration',
      key: 'customerRecordCheckDuration',
      editable: true,
      width: 150,
      componentInput: (inputRef, save) => {
        return (
          <InputNumber
            ref={inputRef}
            min={0}
            max={99}
            type="number"
            placeholder={translation('inspectionProcess.enterProcessTime')}
            onBlur={save}
            onPressEnter={save}
          />
        )
      },
      render: (_, record) => {
        const value = record && record.customerRecordCheckDuration ? (
          `${record.customerRecordCheckDuration} ${translation('accreditation.month')}`
        ) : translation("accreditation.inspectionCycle")
        return <div>{value}</div>
      }
    },
    {
      title: translation("accreditation.licensePlateColor"),
      dataIndex: 'customerRecordPlateColor',
      key: 'customerRecordPlateColor',
      editable: true,
      componentInput: (inputRef, save) => {
        return (
          <Select onBlur={save} ref={inputRef} defaultOpen onClick={save}>
            {OptionsColor.map(item => {
              return (
                <Select.Option value={item.value} key={item.value}>{item.lable}</Select.Option>
              )
            })}
          </Select>
        )
      },
      render: (_, record) => {
        const color = OptionsColor.filter(item => item.value === record.customerRecordPlateColor)
        return <div>{color.length > 0 ? color[0].lable : ""}</div>
      }
    },
    {
      title: translation("accreditation.inspectionExpireDate"),
      dataIndex: 'customerRecordCheckExpiredDate',
      key: 'customerRecordCheckExpiredDate',
      editable: true,
      isTime: true,
      width: 160,
      componentInput: (inputRef, save) => {
        return <DatePicker
          format="DD/MM/YYYY"
          defaultOpen
          placeholder={translation('accreditation.inspectionExpireDate')}
          ref={inputRef}
          onBlur={save}
          onChange={save}
        />
      },
      render: (_, record) => {
        const value = record && record.customerRecordCheckExpiredDate ? (
          record.customerRecordCheckExpiredDate
        ) : translation("accreditation.inspectionExpireDate")
        return <div>{value}</div>
      }
    },
    {
      title: "",
      dataIndex: 'Action',
      key: 'Action',
      render: (_, record) => {
        const { customerRecordId } = record
        return <Space className="accreditation">
          <DeleteOutlined onClick={() => {
            handleDeleteById({
              id: customerRecordId
            })
          }} />
          <ExclamationCircleOutlined
            onClick={() => {
              history.push('/auth-punish', { plate: record.customerRecordPlatenumber })
            }}
          />
        </Space>
      }
    }
  ]
  const columnsV3 = columnsV2.map((col, index) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        isTime: col.isTime ? true : false,
        componentInput: col.componentInput,
        handleSave: (row, isReload) => handleSave(row, col.key, isReload),
        handleChangeRow: (record) => handleChangeRow(record, index)
      }),
    };
  });

  const onCreateNewCustomer = ({ customerRecordPlatenumber }) => {
    // && _verifyCarNumber(customerRecordPlatenumber)
    if (customerRecordPlatenumber) {
      AccreditationService.createNewCustomer({
        customerRecordPlatenumber: customerRecordPlatenumber.toUpperCase(),
        customerStationId: user.stationsId,
        customerRecordCheckDate: moment().format('DD/MM/YYYY'),
        customerRecordPlateColor: OptionsColor[0].value
      }).then(result => {
        if (result && result.issSuccess) {
          setIsCreate(false)
          formModal.resetFields()
          // notification['success']({
          //   message: "",
          //   description: translation('accreditation.addSuccess')
          // })
          handleFetchAccreditation(dataFilter)
        }
      })
    } else {
      notification.error({
        message: "",
        description: translation('landing.invalidLicensePlate')
      })
    }
  }

  const onReloadData = () => {
    handleFetchAccreditation(dataFilter)
  }


  const mqttConnect = (host, mqttOption) => {
    setClient(mqtt.connect(host, mqttOption));
  };

  useEffect(() => {
    if (client) {

      client.on('connect', () => {
        console.log('connect', 3242)
      });
      client.on('error', (err) => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => {
        console.log('Reconnecting')
      });
      client.on('message', (topic, message) => {
        if (topic === `RECORD_UPDATE_${user.stationsId}`) {
          notification.info({
            message: "",
            description: translation('accreditation.onCustomersUpdate')
          })
        }
        if (topic === `RECORD_ADD_${user.stationsId}`) {
          notification.info({
            message: "",
            description: translation('accreditation.onCustomersGoOn')
          })
        }
        if (topic === `RECORD_DELETE_${user.stationsId}`) {
          notification.info({
            message: "",
            description: translation('accreditation.onCustomersLeave')
          })
        }
      });
      mqttSub({
        topic: `RECORD_UPDATE_${user.stationsId}`, qos: 2
      })
      mqttSub({
        topic: `RECORD_ADD_${user.stationsId}`, qos: 2
      })
      mqttSub({
        topic: `RECORD_DELETE_${user.stationsId}`, qos: 2
      })
      return () => {
        mqttUnSub({
          topic: `RECORD_UPDATE_${user.stationsId}`
        })
        mqttSub({
          topic: `RECORD_ADD_${user.stationsId}`, qos: 2
        })
        mqttUnSub({
          topic: `RECORD_DELETE_${user.stationsId}`, qos: 2
        })
      }
    }

  }, [client]);

  const mqttDisconnect = () => {
    if (client) {
      client.end(() => {
        console.log('end')
      });
    }
  }

  const mqttSub = (subscription) => {
    if (client) {
      const { topic, qos } = subscription;

      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
      });
    }
  };

  const mqttUnSub = (subscription) => {
    if (client) {
      const { topic } = subscription;
      client.unsubscribe(topic, error => {
        if (error) {
          console.log('Unsubscribe error', error)
          return
        }

      });
    }
  };

  useEffect(() => {
    handleConnect()
    return () => {
      handleDisconnect()
    }
  }, [])

  const handleConnect = () => {
    let url = process.env.REACT_APP_API_WSS_URL

    if (window.location.protocol !== "https:") {
      url = process.env.REACT_APP_API_WS_URL
    }

    const clientId = `RECORD_UPDATE_DK_${user.stationsId}_${moment().format("YYYY_MM_DD_hh_mm_ss")}`

    const options = {
      reconnectPeriod: 10000,
      rejectUnauthorized: false
    };
    options.clientId = clientId;

    mqttConnect(url, options);
  };

  const handleDisconnect = () => {
    mqttDisconnect();
  };

  return (
    <>
      <Tabs activeKey={ListEditAccreditationKey} onChange={(key) => {
          if (key === AccreditationNotificationKey) {
            window.open("/accreditation-public", "_blank")
          } else if (key === CreateNewCustomerKey) {
            window.open("/create-customer", "_blank")
          } else if (key === InspectionProcessKey) {
            history.push('/inspection-process')
          } else if (key === ListAccreditationKey) {
            history.push('/accreditation')
          }  else if(key === PunishKey) {
            history.push('/auth-punish')
          } else {
    
          }
        }} >
        <TabPane tab={translation("accreditation.title")} key={ListAccreditationKey} />
        <TabPane tab={translation("accreditation.list")} key={ListEditAccreditationKey}>
        <>
          <div className="row accreditation__date">
            <div className="col-12">
              <Button type="primary" onClick={() => {
                setIsCreate(!isCreate)
                if (inputRef && inputRef.current) {
                  setTimeout(() => {
                    inputRef.current.focus()
                  }, 0)
                }
              }}>
                {translation('inspectionProcess.add')}
                <PlusOutlined className="addIcon" />
              </Button>

              <Button
                className="mx-3"
                type="default"
                onClick={onReloadData}
              >
                {translation("accreditation.reload")}
                <ReloadOutlined className="addIcon" />
              </Button>
            </div>
          </div>
          <Table
            rowClassName={(record, index) => `${record && record.customerRecordModifyDate ? 'edited-row editable-row' : 'editable-row'} ${record && record.isAdd ? "edited-row__add" : ""}`}
            dataSource={dataAccreditation.data}
            columns={columnsV3}
            scroll={{ x: 1200 }}
            components={components}
            pagination={{
              position: ['bottomCenter'],
              total: dataAccreditation.total,
              pageSize: dataFilter.limit,
              current: dataFilter.skip ? (dataFilter.skip / dataFilter.limit) + 1 : 1
            }}
            onChange={({ current, pageSize }) => {
              dataFilter.skip = (current - 1) * pageSize
              setDataFilter({ ...dataFilter })
              handleFetchAccreditation(dataFilter)
            }}
          />
          <Modal
            visible={isCreate}
            title={translation('inspectionProcess.add')}
            onCancel={() => setIsCreate(!isCreate)}
            footer={null}
          >
            <Form onFinish={onCreateNewCustomer} form={formModal}>
              <div className="row">
                <div className="col-12 col-md-12">
                  <Form.Item
                    name="customerRecordPlatenumber"
                  >
                    <Input ref={inputRef} autoFocus placeholder={translation('landing.license-plates')} />
                  </Form.Item>
                </div>
                <div className="col-9 col-md-9" />
                <div className="col-3 col-md-3 mt-3">
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {translation('inspectionProcess.save')}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
          </>
        </TabPane>
        {!isHiddenTabs(InspectionProcessKey) && 
          <TabPane tab={translation('header.inspectionProcess')} key={InspectionProcessKey}/>
        }
        {!isHiddenTabs(CreateNewCustomerKey)&&
          <TabPane tab={translation("accreditation.createNew")} key={CreateNewCustomerKey}/>
        }
        {!isHiddenTabs(AccreditationNotificationKey)&&
          <TabPane tab={translation("accreditation.accreditationNotification")} key={AccreditationNotificationKey}/>
        }
        {
          !isHiddenTabs(PunishKey) && 
          <TabPane tab={translation("header.punish")} key={PunishKey}/>
        }
      </Tabs>
      {!!crimePlateNumber && <ModalCrime plateNumber={crimePlateNumber} onClose={() => setCrimePlateNumber('')} />}
    </>
  )
}
export default ListEditAccreditation;