import Request from './request'

export default class MessageService {
  static async getMessageSMSTemplate() {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: '/CustomerMessage/findTemplates'
      }).then((result = {})=>{
        const { statusCode, data } = result
        if(statusCode === 200) {
            return resolve(data)
        }else{
            return resolve([])
        }
      })
    })
  }

  static async sendMessageByCustomerList(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: '/CustomerMessage/sendMessageByCustomerList',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
            return resolve({ isSuccess: true })
        }else{
            return resolve({ isSuccess: false })
        }
      })
    })
  }

  static async sendMessageByFilter(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: '/CustomerMessage/sendMessageByFilter',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
            return resolve({ isSuccess: true })
        }else{
            return resolve({ isSuccess: false })
        }
      })
    })
  }
}
