import Request from './request'

export default class ListSchedulesService {
    static async getList(dataFilter) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: '/CustomerSchedule/list',
                data: {
                    ...dataFilter,
                    "order": {
                        "key": "createdAt",
                        "value": "desc"
                    }
                }
            }).then((result = {}) => {
                const { statusCode, data } = result
                if (statusCode === 200) {
                    return resolve(data)
                } else {
                    return resolve(null)
                }
            })
        })
    }

    static async deleteSchedule(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: '/CustomerSchedule/delete',
                data
            }).then((result = {}) => {
                const { statusCode } = result
                if (statusCode === 200) {
                    return resolve({ isSuccess: true })
                } else {
                    return resolve({ isSuccess: false })
                }
            })
        })
    }

    static async updateSchedule(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: '/CustomerSchedule/update',
                data
            }).then((result = {}) => {
                const { statusCode } = result
                if (statusCode === 200) {
                    return resolve({ isSuccess: true })
                } else {
                    return resolve({ isSuccess: false })
                }
            })
        })
    }

}