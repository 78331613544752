import { combineReducers } from 'redux'
import app from './app'
import member from './member'
import setting from './setting'
import introduction from './stationIntroduction'

const rootReducer = combineReducers({
  member,
  app,
  setting,
  introduction,
})

export default rootReducer