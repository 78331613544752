import React, { useState, useEffect } from 'react'
import { Select, Table, Space, notification, Modal, Button, Input, DatePicker, Form, Tooltip } from 'antd'
import { DeleteOutlined, FormOutlined } from '@ant-design/icons'
import './listSchedulesStyle.scss'
import { listTimes } from '../../constants/listTime'
import ListSchedulesService from '../../services/listSchedulesService'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { HandleIcon } from 'assets/icons'

const { Option } = Select

const ListSchedule = () => {
  const { t:translation } = useTranslation()
  const [dataListSchedules, setDataSchedules] = useState({
    data: [],
    total: 0
  })
  const [dataFilter, setDataFilter] = useState({ filter: {}, skip: 0, limit: 20, searchText: undefined })
  const [visible, setVisible] = useState(false)
  const [selectedSchedule, setSelectedSchedule] = useState({})
  
  function handleUpdateState(record) {
    if(record && record.CustomerScheduleStatus === 0) {
        ListSchedulesService.updateSchedule({
            customerScheduleId: record.customerScheduleId,
            data: { CustomerScheduleStatus: 1 }
        }).then(result => {
            if(result && result.isSuccess) {
                notification.success({
                    message: "",
                    description: translation('listCustomers.success', {
                        type: translation('listCustomers.handle')
                    })
                })
                fetchListData(dataFilter)
            } else {
                notification.error({
                    message: "",
                    description: translation('listCustomers.failed', {
                        type: translation('listCustomers.handle')
                    })
                })
            }
        })
    } 
  }

  const columns = [
    {
      title: translation('listSchedules.index'),
      dataIndex: 'index',
      key: 'index',
      width: 102,
      render: (_, __, index)=>{
        return dataFilter.skip ? dataListSchedules.total - ( dataFilter.skip + index ): dataListSchedules.total  - (index)
      }
    },
    {
      title: translation('listSchedules.licensePlates'),
      dataIndex: 'licensePlates',
      key: 'licensePlates',
      width: 168,
      render: (value) => {
        return (
          <div>{value.toUpperCase()}</div>
        )
      }
    },
    {
      title: translation('listSchedules.phoneNumber'),
      dataIndex: 'phone',
      key: 'phone',
      width: 151,
      render: (value) => {
        return <span className="blue-text">{value}</span>
      }
    },
    {
      title: translation('listSchedules.fullName'),
      key: 'fullnameSchedule',
      dataIndex: 'fullnameSchedule',
      width: 230
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: 175,
      render: (value) => {
        return <span className="blue-text">{value}</span>
      }
    },
    {
      title: translation('listSchedules.time'),
      key: 'time',
      dataIndex: 'time',
      width: 151
    },
    {
      title: translation('listSchedules.inspectionDate'),
      key: 'dateSchedule',
      dataIndex: 'dateSchedule',
      width: 151
    },
    {
      title: ' ',
      key: 'action',
      width: 45,
      render: (text, record) => {
        return (
          <Space size="middle">
            <div 
                className="pointer" 
                onClick={() => handleUpdateState(record)}
            >
                <Tooltip title={translation('listCustomers.handle')}>
                    <HandleIcon
                        className="handle_icon"
                        style={{
                            opacity: record.CustomerScheduleStatus === 0 ? 0.6 : 0
                        }}
                    />
                </Tooltip>
            </div>
            <FormOutlined onClick={() => onOpenModal(record)} />
            <DeleteOutlined onClick={() => onDeleteSchedule(record && record.customerScheduleId)} />
          </Space>
        )
      },
    },
  ];

  useEffect(() => {
    fetchListData(dataFilter)
  }, [])

  const onFilterByTime = async (time) => {
    translation('listSchedules.allTimeline')
    if(time === translation('listSchedules.allTimeline')) {
      dataFilter.filter.time = undefined
    } else {
      dataFilter.filter.time = time.split(" - ").join("-")
    }
    dataFilter.skip = 0
    setDataFilter({...dataFilter})
    fetchListData(dataFilter)
  }

  const fetchListData = (filter) => {
    ListSchedulesService.getList(filter).then(result => {
      if (result)
        setDataSchedules(result)
      else
        dataListSchedules.data.length > 0 && setDataSchedules({
          data: [],
          total: 0
        })
    })
  }

  const onDeleteSchedule = (id) => {
    ListSchedulesService.deleteSchedule({ customerScheduleId: id })
      .then(result => {
        if (result && result.isSuccess) {
          notification['success']({
            message: '',
            description: translation('listSchedules.deleteSuccessfully')
          });
          fetchListData(dataFilter)
        } else {
          notification['error']({
            message: '',
            description: translation('listSchedules.deletefailed')
          });
        }
      })
  }

  const toggleVisible = () => {
    setVisible(prev => !prev);
  };

  const onOpenModal = (data) => {
    toggleVisible()
    setSelectedSchedule(data)
  }

  const onChooseDate = (date, dateString) => {
    dataFilter.filter.dateSchedule = dateString || undefined
    dataFilter.skip = 0
    setDataFilter({...dataFilter})
    fetchListData(dataFilter)
  }

  const onUpdateSchedule = (values) => {
    Object.keys(values).map(key => {
      if(!values[key])
        delete values[key]
    })

    ListSchedulesService.updateSchedule({
      "customerScheduleId": selectedSchedule.customerScheduleId,
      "data": {
        ...values,
        "dateSchedule": values.dateSchedule.format('DD/MM/YYYY')
      }
    }).then(result => {
      if (result && result.isSuccess) {
        toggleVisible()
        fetchListData(dataFilter)
        notification['success']({
          message: '',
          description:translation('listSchedules.updateSuccessfully')
        });
      } else {
        notification['error']({
          message: '',
          description: translation('listSchedules.updateFailed')
        });
      }
    })
  }
  
  const onSearchSchedule = (val) => {
    dataFilter.searchText = val || undefined
    dataFilter.skip = 0
    setDataFilter({...dataFilter, searchText: val })
    fetchListData(dataFilter)
  }

  const LIST_TIMES = listTimes(translation)

  const onChangeSearchText = (e) => {
    e.preventDefault()
    setDataFilter({...dataFilter, searchText: e.target.value ? e.target.value : undefined})
  }

  return (
    <main className="list_schedules">
      <div className="list_schedules__title">
        {translation('listSchedules.listSchedules')}
      </div>
      <div className="list_schedules__select row p-3">
        <div className="col-12 col-md-4 mb-3 list_schedules__select_item">
          <Input.Search autoFocus onSearch={onSearchSchedule} onChange={onChangeSearchText} placeholder={translation("listSchedules.searchText")} className="w-100"/>
        </div>
        <div className="col-12 col-md-4 mb-3 list_schedules__select_item">
            <Select className="w-100" placeholder={translation('listSchedules.filterByTime')} onChange={onFilterByTime}>
              {
                LIST_TIMES.map(time => {
                  return (
                    <Option value={time} key={time}>{time}</Option>
                  )
                })
              }
            </Select>
        </div>
        <div className="col-12 col-md-4 mb-3 list_schedules__select_item">
          <DatePicker className="w-100" format="DD/MM/YYYY" placeholder={translation('listSchedules.filterByDate')} onChange={onChooseDate} />
        </div>
      </div>

      <div className="list_schedules__body row">
          <Table 
          dataSource={dataListSchedules.data} 
          rowClassName={(record, index) => `${record && record.CustomerScheduleStatus ? 'handled_customer' : ''}`} 
          columns={columns} 
          scroll={{x: 1200}}
          pagination={{
            position: ['bottomCenter'],
            total: dataListSchedules.total,
            pageSize	: dataFilter.limit,
            current: dataFilter.skip ? (dataFilter.skip / dataFilter.limit) + 1 : 1
          }}
          onChange={({ current, pageSize	})=>{
            dataFilter.skip = (current -1) * pageSize	
            setDataFilter({...dataFilter})
            fetchListData (dataFilter)
          }} 
        />
      </div>
      <Modal
        visible={visible}
        title={translation('listSchedules.modalTitle')}
        onOk={toggleVisible}
        onCancel={toggleVisible}
        footer={null}
      >
        <Form initialValues={{
          ...selectedSchedule,
          dateSchedule: moment(selectedSchedule.dateSchedule)
        }} onFinish={onUpdateSchedule}>
          <div className="row">
            <div className="col-6 col-md-6 mb-3">
              <Form.Item name="licensePlates">
                <Input placeholder={`${translation('listSchedules.licensePlates')}`}/>
              </Form.Item>
            </div>
            <div className="col-6 col-md-6">
              <Form.Item name="phone">
                <Input placeholder={`${translation('listSchedules.phoneNumber')}`}/>
              </Form.Item>
            </div>
            <div className="col-6 col-md-6 mb-3">
              <Form.Item name="fullnameSchedule">
                <Input placeholder={`${translation('listSchedules.fullName')}`} />
              </Form.Item>
            </div>
            <div className="col-6 col-md-6 mb-3">
              <Form.Item name="email">
                <Input placeholder={`Email`} />
              </Form.Item>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <Form.Item name="time">
                <Select placeholder={`${translation('listSchedules.time')}`}>
                  {
                    LIST_TIMES.map((time, i) => {
                      return (
                        i !== 0 && <Option key={Math.random()} value={time}>{time}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <Form.Item name="dateSchedule">
                <DatePicker className="w-100" format="DD/MM/YYYY" placeholder={`${translation('listSchedules.inspectionDate')}`} />
              </Form.Item>
            </div>
          </div>
          <div className="row mt-3">
              <div className="col-9 col-md-9"/>
              <div className="col-3 col-md-3">
                <Form.Item>
                    <Button type="primary" htmlType="submit">{translation('listSchedules.save')}</Button>
                </Form.Item>
              </div>
          </div>
        </Form>
      </Modal>
    </main>
  )
}

export default ListSchedule