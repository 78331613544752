import React, { useEffect } from "react"
import { Modal, Form, Input, Button, DatePicker, InputNumber } from 'antd'
import { useTranslation } from "react-i18next"
import moment from "moment"

const ModalEditUserInfo = ({ isEditing, toggleEditModal, onUpdateCustomer, selectedCustomer, inputRef }) => {
  const { t: translation } = useTranslation()
  const [form] = Form.useForm()

  async function handleUpdate(values) {
   const isOK = await onUpdateCustomer(values)
   if (isOK) {
    form.resetFields()
   }
  }

  useEffect(() => {
    if(selectedCustomer) {
      form.setFieldsValue({
        ...selectedCustomer,
        customerRecordCheckExpiredDate: selectedCustomer.customerRecordCheckExpiredDate ?
          moment(selectedCustomer.customerRecordCheckExpiredDate, "DD/MM/YYYY") :
          ''
      })
    }
    return () => form.resetFields()
  }, [selectedCustomer])

  return (
    <Modal
      visible={isEditing}
      title={translation('listCustomers.modalEditTitle')}
      onCancel={toggleEditModal}
      footer={null}
    >
      <Form
        form={form}
        onFinish={handleUpdate}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <Form.Item
              name="customerRecordPlatenumber"
              rules={[
                {
                  required: true,
                  message: translation(
                    'landing.invalidLicensePlate'
                  ),
                },
                {
                  validator: (_, value) => {
                    if (!/^[a-zA-Z0-9]+$/.test(value) ) {
                      return Promise.reject(translation(
                        'landing.invalidLicensePlate'
                      ))
                    }
                    return Promise.resolve()
                  }
                }
              ]}
            >
              <Input autoFocus ref={inputRef} placeholder={`${translation('listSchedules.licensePlates')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="customerRecordPhone"
            >
              <Input type="number" placeholder={`${translation('listSchedules.phoneNumber')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6 mb-3">
            <Form.Item name="customerRecordFullName">
              <Input placeholder={`${translation('listSchedules.fullName')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="customerRecordEmail"
            >
              <Input 
                placeholder={`Email`}
                type="email"
              />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="customerRecordCheckExpiredDate"
              rules={[
                {
                  required: true,
                  message: translation(
                    'accreditation.invalidInspectionExpireDate'
                  ),
                }
              ]}
            >
              <DatePicker className="w-100" format="DD/MM/YYYY" placeholder={`${translation('accreditation.inspectionExpireDate')}`} />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <Form.Item
              name="customerRecordCheckDuration"
              rules={[
                {
                  required: true,
                  message: translation(
                    'accreditation.invalidInspectionCycle'
                  ),
                },
                ({ getFieldValue }) => ({
                  validator() {
                    if (getFieldValue('customerRecordCheckDuration') < 1) {
                      return Promise.reject(new Error(translation("accreditation.validateInspectionCycle")))
                    }
                    return Promise.resolve();
                  }
                }),
              ]}
            >
              <InputNumber
                min={0}
                max={99} 
                type="number" 
                placeholder={`${translation('accreditation.inspectionCycle')}`}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-8 col-md-9" />
          <div className="col-3 col-md-3">
            <Form.Item>
              <Button key="submit" className="blue_button" htmlType="submit">
                {translation('listSchedules.save')}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEditUserInfo