import React, { useState, useEffect } from "react"
import { Form, Modal, Select, Button, Input } from 'antd'
import { useTranslation } from "react-i18next"
import MessageService from "../../services/messageService"
import Handlebars from "handlebars";
import moment from "moment";
import { xoa_dau } from "../../helper/common";
import { useSelector } from "react-redux";

const { Option } = Select
const TYPES = [
  "SMS", "Email"
]
const ModalMessage = ({ visible, toggleMessageModal, onSendMessage, message }) => {
  const { t: translation } = useTranslation()
  const [types, setTypes] = useState(TYPES[0])
  const [temps, setTemps] = useState([])
  const [form] = Form.useForm()
  const [SMSLength, setSMSLength] = useState('') 
  const stationSetting = useSelector(state => state.setting)

  const FORM_DATA = { 
    dateSchedule: moment().format("DD/MM/YYYY"),
    time: '7h-7h30',
    customerRecordPlatenumber: "99A999999", 
    customerRecordCheckExpiredDate: moment().format("DD/MM/YYYY"),
    stationsBrandname: stationSetting.stationCustomSMSBrandConfig ? JSON.parse(stationSetting.stationCustomSMSBrandConfig).smsBrand : "",
    ...stationSetting
  }

  useEffect(() => {
    MessageService.getMessageSMSTemplate().then(result => {
      if (result.length > 0) {
        setTemps(result)
        const template = Handlebars.compile(result[0].messageTemplateContent);
        const content = xoa_dau(template(FORM_DATA))
        form.setFieldsValue({
          customerMessageTemplateId: result[0].messageTemplateName,
          customerMessageContent: types === TYPES[0] ? content : ""
        })
        setSMSLength(`${content.length} ký tự - ${Math.ceil(content.length/150)} tin nhắn`)
      }
    })
  }, [message])

  function changeTypeMessageSMS(e) {
    let typeIndex = temps.findIndex(t => t.messageTemplateId === e)
    const template = Handlebars.compile(temps[typeIndex].messageTemplateContent);
    const content = xoa_dau(template(FORM_DATA))
    form.setFieldsValue({
      customerMessageContent: content,
      customerMessageTemplateId: e
    })
    setSMSLength(`${content.length} ký tự - ${Math.ceil(content.length/150)} tin nhắn`)
  }

  function handleSelectMedia(e) {
    setTypes(e)
    if (e === TYPES[0]) {
      changeTypeMessageSMS(temps[0].messageTemplateId)
    } else {
      form.setFieldsValue({
        customerMessageContent: ''
      })
    }
  }

  function handleSendMessage (values) {
    if(values) {
      let typeIndex = temps.find(t =>  {
        return t.messageTemplateId === values.customerMessageTemplateId
      })
      if(typeIndex) {
        values.customerMessageTemplateId = typeIndex.messageTemplateId
      } else {
        values.customerMessageTemplateId = temps[0].messageTemplateId
      }
      if(values.customerMessageCategories === TYPES[1]) {
        delete values.customerMessageTemplateId
      }
      const status = onSendMessage(values)
      if(status) {
        form.resetFields()
      }
    }
  }

  return (
    <Modal
      visible={visible}
      title={translation("listCustomers.sendMessage")}
      onCancel={toggleMessageModal}
      footer={null}
    >
      <Form
        onFinish={handleSendMessage}
        initialValues={{
          customerMessageCategories: TYPES[0]
        }}
        form={form}
      >
          <label className="h5">{translation("listCustomers.category")}</label>
          <Form.Item
            name="customerMessageCategories"
          >
            <Select onChange={handleSelectMedia}>
              {
                TYPES.map((type, i) => {
                  return (
                    <Option key={type} value={type}>{type}</Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          {message && <div className="h6 mb-3">
            {translation('listCustomers.sendMessage')}{': '}{message}
          </div>}
        {
          types === TYPES[0] ? (
            <>
            <label className="h5">{translation("listCustomers.formMessage")}</label>
            <Form.Item
              name="customerMessageTemplateId"
            >
              <Select onChange={changeTypeMessageSMS}>
                {
                  temps.map(t => {
                    return (
                      <Option key={t.messageTemplateId} value={t.messageTemplateId}>{t.messageTemplateName}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
            <label className="h5">
              {translation("listCustomers.contentMessage")}{" "}{SMSLength}
            </label>
            </>
          ) : <> </>
        }
        <Form.Item
          name="customerMessageContent"
          rules={[
            {
              required: true,
              message: translation('listCustomers.invalidContent'),
            }
          ]}
        >
          <Input.TextArea disabled={types === TYPES[0]} className={'noselect'}  placeholder={translation("listCustomers.messageContent")} size="large" rows={7} />
        </Form.Item>
        <div className="d-flex justify-content-center">
          <Form.Item className="col-12 col-md-2">
            <Button key="submit" className="blue_button text-center" size="large" htmlType="submit">
              <span className="text-center">{translation('listCustomers.send')} </span>
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalMessage