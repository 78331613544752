import React, { useState, useEffect, useRef } from 'react'
import './listCustomersStyle.scss'
import { Table, Input, Space, Button, notification, DatePicker, Tooltip, Select } from 'antd';
import { FormOutlined, DeleteOutlined, ExceptionOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import ListCustomersService from '../../services/listCustomersService'
import moment from 'moment';
import { convertFileToBase64 } from '../../helper/common'
import uploadService from '../../services/uploadService'
import MessageService from '../../services/messageService';
import ModalUpload from './ModalUpload';
import ModalMessage from './ModalMessage';
import ModalEditUserInfo from './ModalEditUserInfo';
import { SMSSendIcon, EmailSendIcon } from 'assets/icons';
import { ModalCrime } from './ModalCrime';

const { RangePicker } = DatePicker;
const { Option } = Select

const XLSX_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

export default function ListCustomer() {
    const { t:translation } = useTranslation()
    const [listDataCustomers, setListDataCustomer] = useState({
        data: [],
        total: 0
    })
    const [isEditing, setIsEditing] = useState(false)
    const [crimePlateNumber, setCrimePlateNumber] = useState('')
    const [isSendMessage, setIsSendMessage] = useState(false)
    const [isUploadFile, setIsUploadFile] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState({})
    const [message, setMessage] = useState({
      message: '',
      button: translation('listCustomers.selectAll', { total: listDataCustomers.total })
    })
    const [dataFilter, setDataFilter] = useState({ filter: {}, skip: 0, limit: 20, searchText: undefined, endDate: undefined, startDate: undefined })
    const [dateBySelect, setDateBySelect] = useState("")
    const [fileSelected, setFileSelected] = useState(undefined)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedCustomers, setSelectedCustomers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [messageType, setMessageType] = useState('')
    const inputRef = useRef()
    const onExportListCustomers = () => {
        ListCustomersService.exportListCustomers({...dataFilter, skip: undefined, limit: undefined})
            .then(url => {
                if(url)
                    window.open(url, "_blank")
                else
                    notification['error']({
                        description: translation('listCustomers.exportFailed')
                    })
            })
    }

    const columns = [
        {
            title: translation('listSchedules.index'),
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: translation('listSchedules.licensePlates'),
            dataIndex: 'customerRecordPlatenumber',
            key: 'customerRecordPlatenumber',
            width: 168,
            render: (_, item) => {
                const { hasCrime, customerRecordPlatenumber } = item
                if (!hasCrime) {
                    return customerRecordPlatenumber
                }
                return (
                    <div className='d-flex justify-content-between align-items-center'>
                        {customerRecordPlatenumber}
                        <Tooltip title={translation('punish.notification')}>
                        <ExceptionOutlined
                            style={{
                                color: 'var(--bs-warning)',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                            }}
                            onClick={() => setCrimePlateNumber(customerRecordPlatenumber)}
                        />
                        </Tooltip>
                    </div>
                )
            },
        },
        {
            title: translation('listSchedules.phoneNumber'),
            dataIndex: 'customerRecordPhone',
            key: 'customerRecordPhone',
            width: 170,
            render: (_, record) => {
                let className = ''
                let message = ''
                if(record) {
                    const time = moment(record.customerRecordSMSNotifyDate).format("hh:mm DD/MM/YYYY")
                    if(record.customerRecordSMSNotifyDate && record.customerRecordSMSNotifyResult ) {
                        className = "send_success"
                        message = translation('listCustomers.sendMessageSuccess', {
                            type: "SMS",
                            time: time
                        })
                    } else if(!record.customerRecordSMSNotifyDate && !record.customerRecordSMSNotifyResult ) {
                        className = "send_fail"
                        message = translation('listCustomers.sendMessageFail', {
                            type: "SMS",
                            time: time
                        })
                    } else {
                        className = "no_send"
                        message = translation('listCustomers.noSend', {
                            type: "SMS"
                        })
                    }
                }
                return (
                    <div className='d-flex justify-content-between'>
                        <div className="blue-text">
                            {record.customerRecordPhone}
                        </div>
                        <Tooltip title={message}>
                            <div className={className}>
                                <SMSSendIcon/>
                            </div>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            title: translation('listSchedules.fullName'),
            key: 'customerRecordFullName',
            dataIndex: 'customerRecordFullName',
            width: 230
        },
        {
            title: 'Email',
            key: 'customerRecordEmail',
            dataIndex: 'customerRecordEmail',
            render: (_, record) => {
                let className = ''
                let message = ''
                if(record) {
                    const time = moment(record.customerRecordEmailNotifyDate).format("hh:mm DD/MM/YYYY")
                    if(record.customerRecordEmailNotifyDate && record.customerRecordEmailNotifyResult) {
                        className = "send_success"
                        message = translation('listCustomers.sendMessageSuccess', {
                            type: "email",
                            time: time
                        })
                    } else if(!record.customerRecordEmailNotifyDate && !record.customerRecordEmailNotifyResult) {
                        className = "send_fail"
                        message = translation('listCustomers.sendMessageFail', {
                            type: "email",
                            time: time
                        })
                    } else {
                        className = "no_send"
                        message = translation('listCustomers.noSend', {
                            type: "email"
                        })
                    }
                }
                return (
                    <div className='d-flex justify-content-between'>
                        <div className="blue-text">
                            {record.customerRecordEmail}
                        </div>
                        <Tooltip title={message}>
                            <div className={className}>
                                <EmailSendIcon/>
                            </div>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            title: translation('listCustomers.dateEnd'),
            key: 'customerRecordCheckExpiredDate',
            dataIndex: 'customerRecordCheckExpiredDate'
        },
        {
            title: ' ',
            key: 'action',
            width: 45,
            render: (_, record) => {
            return (
                <Space size="middle">
                    <FormOutlined onClick={() => {
                        onOpenModal(record)
                        if(inputRef && inputRef.current) {
                            setTimeout(() => {
                                inputRef.current.focus()
                            },0)
                        }
                    }} />
                    <DeleteOutlined onClick={() => onDeleteCustomer(record && record.customerRecordId)} />
                </Space>
            )
            },
        }
    ];

    const LIST_SMS_EMAIL = [
        {
            id: 1,
            value: 'all',
            label: translation('listCustomers.filterBySmsEmail')
        },
        {
            id: 2,
            value: 'SMS',
            label: 'SMS'
        },
        {
            id: 3,
            value: 'EMAIL',
            label: "EMAIL"
        }
    ]

    const toggleMessageModal = () => {
        setIsSendMessage(prev => !prev)
    }

    const toggleUploadModal = () => {
        setIsUploadFile(prev => !prev)
    }

    const headerButtons = [
        {
            key: 'SMS',
            name: translation("listCustomers.typeMessage"),
            onClick: toggleMessageModal
        },
        {
            key: 'upload',
            name: translation("listCustomers.upload"),
            onClick: toggleUploadModal
        },
        {
            key: 'export',
            name: translation("listCustomers.export"),
            onClick: onExportListCustomers
        }
    ]

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
					if(selectedRowKeys.length > 0) {
                        
                        if(selectedRowKeys.length === listDataCustomers.total) {
                            setMessage({
                                button: translation('listCustomers.undo'),
                                message: `${listDataCustomers.total} ${translation('listCustomers.selected')}`
                            })
                        }
						else if(selectedRowKeys.length === listDataCustomers.data.length) {
							setMessage({
								button: translation('listCustomers.selectAll',  { total: listDataCustomers.total }),
								message: `${selectedRowKeys.length} ${translation('listCustomers.selected')}`
							})
						} else {
							setMessage({
								button: translation('listCustomers.selectAll',  { total: listDataCustomers.total }),
								message: `${selectedRowKeys.length} ${translation('listCustomers.selected')}`
							})
						}
						let arrCustomers = []
						selectedRows.forEach(cus => {
								arrCustomers.push(cus.customerRecordId)
						})
						setSelectedCustomers(arrCustomers)
						setSelectedRowKeys(selectedRowKeys)
					}
				else {
					setMessage({
						button: translation('listCustomers.selectAll',  { total: listDataCustomers.total }),
						message: ""
					})
					setSelectedCustomers([])
					setSelectedRowKeys([])
				}
			}
    };

    const fetchData = (filter) => {
        ListCustomersService.getData(filter).then(result => {
            if(result) {
                setListDataCustomer(result)
            }
        })
    }

    useEffect(() => {
        fetchData(dataFilter)
    },[])

    const onSearch = (value) => {
        dataFilter.searchText = value || undefined
        dataFilter.skip = 0
        setDataFilter({...dataFilter})
        fetchData(dataFilter)
    };

    const onFilterBySmsEmail = (filterSelect) => {
        // create new state objects
        const filter = { 
            ...dataFilter.filter,
            customerMessageCategory: filterSelect,
        }
        switch(filterSelect) {
            case LIST_SMS_EMAIL[1].value:
                filter.customerRecordSMSNotifyDate =  dateBySelect ? dateBySelect.format() : undefined
                filter.customerRecordEmailNotifyDate = undefined
                break;
            case LIST_SMS_EMAIL[2].value:
                filter.customerRecordSMSNotifyDate = undefined
                filter.customerRecordEmailNotifyDate = dateBySelect ? dateBySelect.format() : undefined
                break;
            default:
                filter.customerRecordSMSNotifyDate = undefined
                filter.customerRecordEmailNotifyDate = undefined
                filter.customerMessageSentDate = dateBySelect ? dateBySelect.format() : undefined
                break;
        }
        setDataFilter({ ...dataFilter, filter})
        setMessageType(filterSelect)
        fetchData({ ...dataFilter, filter})
    }

    const onFilterDateBySmsEmail = (date, dateString) => {
        setDateBySelect(date)
      
        const filter = {...dataFilter.filter}
        const dateFormat = moment(dateString).startOf('day').format();
        switch(messageType) {
            case LIST_SMS_EMAIL[1].value:
                filter.customerRecordSMSNotifyDate =  dateString ? dateFormat : undefined
                filter.customerRecordEmailNotifyDate = undefined
                break;
            case LIST_SMS_EMAIL[2].value:
                filter.customerRecordSMSNotifyDate = undefined
                filter.customerRecordEmailNotifyDate = dateString ? dateFormat : undefined
                break;
            default:
                filter.customerRecordSMSNotifyDate = undefined
                filter.customerRecordEmailNotifyDate = undefined
                filter.customerMessageSentDate = dateString ? dateFormat :undefined
                break;
        }
        // currentFilter[currentFilterKey] = dateString ? dateFormat : undefined;
        setDataFilter({ ...dataFilter, filter})
        fetchData({ ...dataFilter, filter})
    }

    const onFilterByDate = (date, dateString) => {
        if(date) {
            let start = dateString[0]
            let end = dateString[1]
            dataFilter.startDate = start
            dataFilter.skip = 0
            dataFilter.endDate = end
            setDataFilter({...dataFilter})
            fetchData(dataFilter)
        } else {
            dataFilter.startDate = undefined
            dataFilter.endDate = undefined
            dataFilter.skip = 0
            setDataFilter({...dataFilter})
            fetchData(dataFilter)
        }
    }

    const toggleEditModal = () => {
        setIsEditing(prev => !prev)
    }

    const onOpenModal = (customer) =>{ 
        toggleEditModal()
        setSelectedCustomer(customer)
    }

    const onUpdateCustomer = (values) => {
        ListCustomersService.updateCustomerInfo({
            id: selectedCustomer.customerRecordId,
            ...values,
            customerRecordCheckDate: moment(values.customerRecordCheckDate).format("DD/MM/YYYY") || "",
            customerRecordCheckExpiredDate: moment(values.customerRecordCheckDate).add(Number(values.customerRecordCheckDuration), 'months').subtract(1, 'day').format("DD/MM/YYYY") || ""
        }).then(result => {
            if(result && result.isSuccess) {
                notification['success']({
                    message: '',
                    description: translation('listCustomers.updateSuccess')
                });
                toggleEditModal()
                fetchData(dataFilter)
                return true
            } 
            notification['error']({
                message: '',
                description: translation('listCustomers.updateFailed')
            });
            return false
        })
    }

    const onDeleteCustomer = (id) => {
        ListCustomersService.deleteCustomerById(id).then(result => {
            if(result && result.isSuccess) {
                notification['success']({
                    message: '',
                    description: translation('listCustomers.deleteSuccess')
                });
                fetchData(dataFilter)
            } else {
                notification['error']({
                    message: '',
                    description: translation('listCustomers.deleteFailed')
                  });
            }
        })
    }

    const onUploadFile = (e) => {
        const file = e.target.files[0] || undefined
        
        if(file && file.type !== XLSX_TYPE)
            notification['warn']({
                message: '',
                description:  translation('listCustomers.wrongfile')
            })
        else {
            convertFileToBase64(file).then(dataUrl => {
                const newData = dataUrl.replace(/,/gi, '').split('base64')
                if(newData[1]) {
                    const data = {
                        "file": newData[1],
                        "fileFormat": "xlsx"
                    }
                    setFileSelected({
                        name: file.name,
                        data: data
                    })
                }

            })
        }
    }

    const onImportFileToDb = () => {
        if(!fileSelected)
            notification['warn']({
                message: '',
                description: translation('listCustomers.wrongfile')
            })
        else {
            setIsLoading(true)
            uploadService.importDataCustomers({...fileSelected.data}).then(result => {
                if(result && Object.keys(result).length > 0) {
                    fetchData(dataFilter)
                    setFileSelected(undefined)
                    setIsUploadFile(false)
                    if(result.importTotalWaiting) {
                        notification['success']({
                            message: translation('listCustomers.total', {num: result.importTotalWaiting}),
                            description: translation('listCustomers.importTotalWaiting', {min: ((result.importTotalWaiting/50)/60).toFixed(2)})
                        })
                    } else {
                        notification['success']({
                            message: translation('listCustomers.total', {num: result.importTotal}),
                            description: `${translation('listCustomers.importSuccess', {num: result.importSuccess})}. 
                                ${translation('listCustomers.importSuccessFail', {num: result.importTotal-result.importSuccess})}`
                        })
                    }
                } else {
                    notification['error']({
                        message: "",
                        description:translation('listCustomers.importFailed')
                    })
                }
                setIsLoading(false)
            })
        }
    }
    
    function handleSelectAll () {
      if(message.message.includes(listDataCustomers.total.toString())) {
        rowSelection.onChange([], [])
      } else {
        let arrKey = []
        listDataCustomers.data.forEach(item => {
          arrKey.push(item.key)
        })
				//click select all => send message by filter
				setSelectedRowKeys(arrKey)
				setMessage({
					button: translation('listCustomers.undo'),
					message: `${listDataCustomers.total} ${translation('listCustomers.selected')}`
				})
      }
    }

    function sendMessageByList ({
        customerMessageContent,
        customerMessageCategories,
        customerRecordIdList,
        customerMessageTemplateId
    }) {
        MessageService.sendMessageByCustomerList({
            customerMessageContent,
            customerMessageCategories,
            customerRecordIdList,
            customerMessageTemplateId
          }).then(result => {
            if(result.isSuccess) {
							setIsSendMessage(false)
							notification.success({
								message: "",
								description: translation("listCustomers.sendSuccess")
							})
                            return true
            } else {
							notification.error({
								message: "",
								description: translation("listCustomers.sendFailed")
							})
                            return false
            }
        })
    }

    function sendMessageByFilter({
        customerMessageContent,
        customerMessageCategories,
        customerMessageTemplateId,
        filter = {}
    }) {
        MessageService.sendMessageByFilter({
            customerMessageContent,
            customerMessageCategories,
            customerMessageTemplateId,
            filter
          }).then(result => {
            if(result.isSuccess) {
							setIsSendMessage(false)
							notification.success({
								message: "",
								description: translation("listCustomers.sendSuccess")
							})
                            return true
            } else {
							notification.error({
								message: "",
								description: translation("listCustomers.sendFailed")
							})
                            return false
            }
        })
    }

		const onSendMessage = (values) => {
			if(selectedCustomers.length === 0) {
					notification.error({
							message: '',
							description: translation('listCustomers.noSelected')
					})
                    return false
			} else {
					if(values) {
						//check if select all => send message by filter
						if(message.message.includes(listDataCustomers.total.toString())) {
							const status = sendMessageByFilter({
								...values,
								filter: {
                                    startDate: dataFilter.startDate,
                                    endDate: dataFilter.endDate,
                                    searchText: dataFilter.searchText
                                }
							})
                            return status
						} else {
							//send by list
							const status = sendMessageByList({
								...values,
								customerRecordIdList: selectedCustomers
							})
                            return status
						}
					}
			}
	}

    const onChangeSearchText = (e) => {
        setDataFilter({ ...dataFilter, searchText: e.target.value.length > 0 ? e.target.value : undefined })
    }

    return (
        <main className="list_customers noselect">
            <div className="row d-flex justify-content-end list_customers__header">
                <div className="col-12 col-md-4 list_customers__header_button">
                    {
                        headerButtons.map((btn, i) => {
                            return (
                                <Button onClick={btn.onClick}>{btn.name}</Button>
                            )
                        })
                    }
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <label className="list_customers__title">{translation('listCustomers.listCustomers')}</label>
            </div>

            <div className="row pb-3 pr-3">
                <div className="col-12 col-md-4 mb-3">
                    <Input.Search autoFocus placeholder={translation('listCustomers.search')} onChange={onChangeSearchText} onSearch={onSearch}/>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <Input.Group compact>
                        <Select style={{ width: '40%' }} placeholder={translation('listCustomers.filterBySmsEmail')} onChange={onFilterBySmsEmail}>
                            {
                                LIST_SMS_EMAIL.map(item => (
                                    <Option value={item.value} key={item.id}>{item.label}</Option>
                                ))
                            }
                        </Select>
                        <DatePicker defaultValue={dateBySelect} value={dateBySelect} onChange={onFilterDateBySmsEmail} style={{ width: '60%' }} />
                    </Input.Group>
                </div>
                <div className="col-12 col-md-4">
                    <RangePicker className="w-100" format="DD/MM/YYYY" onChange={onFilterByDate} placeholder={[translation('listCustomers.startDate'), translation('listCustomers.endDate')]}/>
                </div>
            </div>
            {message.message && 
            <div className="d-flex justify-content-center pb-3">
                <div>
                    <Button disabled type="text">
                        {message.message}
                    </Button>
                    <Button 
                        type="text"
                        onClick={handleSelectAll}
                    >{message.button}</Button>
                </div>
            </div>
            }
            <div className="list_customers__body">
                    <Table
                        dataSource={listDataCustomers.data}
                        rowSelection={{...rowSelection, selectedRowKeys: selectedRowKeys }}
                        columns={columns} 
                        scroll={{x: 1200}}
                        pagination={{
                            position: ['bottomCenter'],
                            total: listDataCustomers.total,
                            pageSize	: dataFilter.limit,
                            current: dataFilter.skip ? (dataFilter.skip / dataFilter.limit) + 1 : 1
                        }}
                        onChange={({ current, pageSize })=>{
                            dataFilter.skip = (current -1) * pageSize	
                            setDataFilter({...dataFilter})
                            fetchData(dataFilter)
                        }} 
                    />
            </div>
            <ModalEditUserInfo 
                isEditing={isEditing}
                toggleEditModal={toggleEditModal} 
                onUpdateCustomer={onUpdateCustomer}
                selectedCustomer={selectedCustomer}
                inputRef={inputRef}
            />
            <ModalMessage 
                visible={isSendMessage} 
                toggleMessageModal={toggleMessageModal}
                onSendMessage={onSendMessage}
                message={message.message}
            />
            <ModalUpload
                visible={isUploadFile}
                toggleUploadModal={toggleUploadModal}
                onUploadFile={onUploadFile}
                file={fileSelected}
                onImportFileToDb={onImportFileToDb}
                isLoading={isLoading}
            />
            {!!crimePlateNumber && <ModalCrime plateNumber={crimePlateNumber} onClose={() => setCrimePlateNumber('')} />}
        </main>
    )
}