import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleFilled,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Card, DatePicker, Select, Image, Input, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import './newStyle.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import NewService from '../../services/newService'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const DEFAULT_FILTER = {
  filter: {
    isHidden: undefined,
    stationNewsCategories: undefined,
  },
  skip: 0,
  limit: 20,
  searchText: undefined,
  startDate: undefined,
  endDate: undefined,
}

export default function ListNews({
  isReload,
  isActive,
  listCategory,
  setIsReload,
  onSelectPostEdit,
}) {
  const [dataNews, setDataNews] = useState([])
  const [total, setTotal] = useState(0)
  const { t: translation } = useTranslation()
  const [dataFilter, setDataFilter] = useState(DEFAULT_FILTER)

  const fetchDataNews = (filter) => {
    NewService.adminGetList(filter).then((result) => {
      if (result.data && result.data.length > 0) {
        setDataNews(dataNews.concat(result.data))
      }
    })
  }

  const fetchDataNewsNoConcat = (filter) => {
    NewService.adminGetList(filter).then((result) => {
      if (result.data) {
        setDataNews(result.data)
        setTotal(result.total)
      }
    })
  }

  useEffect(() => {
    fetchDataNewsNoConcat(DEFAULT_FILTER)
  }, [])

  useEffect(() => {
    if (isReload) {
      fetchDataNewsNoConcat(DEFAULT_FILTER)
      setDataFilter(DEFAULT_FILTER)
    }
    setIsReload(false)
  }, [isReload])

  const onScrollToFetchData = () => {
    // Prevent fetch when scroll in another tab
    if (!isActive) {
      return
    }
    let newSkip = dataFilter.skip + 20
    dataFilter.skip = newSkip
    setDataFilter({ ...dataFilter })
    fetchDataNews(dataFilter)
  }

  const preventScroll = () => {
    if (dataNews.length !== 0) {
      if (dataNews.length !== total) {
        return true
      }
      return false
    }

    return false
  }

  const getTime = (time) => {
    if (time) {
      return `${moment(time.createdAt).format('LT')} ${moment(
        time.createdAt
      ).format('DD/MM/YYYY')}`
    }
  }

  const getTotalView = (view) => {
    if (view) {
      if (view <= 1000) {
        return view
      } else if (view > 1000 && view < 1000000) {
        return (view / 1000).toFixed(2) + ' K'
      } else {
        return (view / 1000000).toFixed(2) + ' M'
      }
    } else {
      return '0'
    }
  }

  function renderDescription(text = '') {
    if (!text) return ''
    else return text.replace(/\n/g, '<br />')
  }

  const onChangeSearchText = (e) => {
    e.preventDefault()
    setDataFilter({
      ...dataFilter,
      searchText: e.target.value ? e.target.value : undefined,
    })
  }

  const onSearchPost = () => {
    fetchDataNewsNoConcat(dataFilter)
  }

  const onFilterByStatus = (e) => {
    if (e === 'ALL') {
      dataFilter.filter.isHidden = undefined
    } else {
      dataFilter.filter.isHidden = e
    }
    setDataFilter({ ...dataFilter, skip: 0 })
    fetchDataNewsNoConcat({ ...dataFilter, skip: 0 })
  }

  const onChooseDate = (_, dateString) => {
    if (dateString && dateString[0].length > 0) {
      dataFilter.startDate = dateString[0]
      dataFilter.endDate = dateString[1]
    } else {
      dataFilter.startDate = undefined
      dataFilter.endDate = undefined
    }
    setDataFilter({ ...dataFilter, skip: 0 })
    fetchDataNewsNoConcat({ ...dataFilter, skip: 0 })
  }

  const POST_STATUS = [
    {
      label: translation('new.allPost'),
      value: 'ALL',
    },
    {
      label: translation('new.hidePost'),
      value: 1,
    },
    {
      label: translation('new.showPost'),
      value: 0,
    },
  ]

  const onChangePostStatus = (post) => {
    onUpdatePost({
      id: post.stationNewsId,
      data: {
        isHidden: post.isHidden === 1 ? 0 : 1,
      },
    })
  }

  const onDeletePost = async (postId) => {
    //delete by id
    onUpdatePost({
      id: postId,
      data: {
        isDeleted: 1,
      },
    })
  }

  const onUpdatePost = (data) => {
    NewService.updateANew(data).then((result) => {
      if (result && result.isSuccess) {
        fetchDataNewsNoConcat(dataFilter)
        notification.success({
          message: '',
          description: translation('new.updateSuccess'),
        })
      } else {
        notification.error({
          message: '',
          description: translation('new.updateFailed'),
        })
      }
    })
  }

  function handleFilterCategory(value) {
    if (value === null || value === undefined || value === '') {
      dataFilter.filter.stationNewsCategories = undefined
    } else {
      dataFilter.filter.stationNewsCategories = value.toString()
    }
    setDataFilter(dataFilter)
    fetchDataNewsNoConcat({ ...dataFilter, skip: 0 })
  }

  const options = listCategory.map((item) => {
    return {
      value: item.stationNewsCategoryId,
      label: item.stationNewsCategoryTitle,
    }
  })

  function renderNewCategory(categoryId) {
    if (!categoryId) return ''
    for (let i = 0; i < listCategory.length; i++) {
      if (listCategory[i].stationNewsCategoryId === parseInt(categoryId)) {
        return listCategory[i].stationNewsCategoryContent
      }
    }
    return ''
  }

  return (
    <>
      <div className='list_schedules__select row p-3'>
        <div className='col-12 col-md-3 mb-3 list_schedules__select_item'>
          <Input.Search
            autoFocus
            onChange={onChangeSearchText}
            onSearch={onSearchPost}
            placeholder={translation('listSchedules.searchText')}
            className='w-100'
            value={dataFilter.searchText}
          />
        </div>
        <div className='col-12 col-md-3 mb-3 list_schedules__select_item'>
          <Select
            style={{ minWidth: "175px" }}
            className='new_component__modal_content'
            rows={40}
            placeholder={translation('new.selectCategory')}
            options={[{ value: '', label: translation('new.all') }, ...options]}
            value={dataFilter.stationNewsCategories}
            onSelect={handleFilterCategory}
          />
        </div>
        <div className='col-12 col-md-3 mb-3 list_schedules__select_item'>
          <Select
            className='w-100'
            placeholder={translation('new.filterByStatus')}
            onChange={onFilterByStatus}
          >
            {POST_STATUS.map((type, _) => {
              return (
                <Select.Option key={type.label} value={type.value}>
                  {type.label}
                </Select.Option>
              )
            })}
          </Select>
        </div>
        <div className='col-12 col-md-3 mb-3 list_schedules__select_item'>
          <DatePicker.RangePicker
            className='w-100'
            format='DD/MM/YY'
            placeholder={[
              translation('listCustomers.startDate'),
              translation('listCustomers.endDate'),
            ]}
            onChange={onChooseDate}
          />
        </div>
      </div>
      <InfiniteScroll
        dataLength={dataNews.length}
        next={onScrollToFetchData}
        hasMore={preventScroll()}
        style={{ overflow: 'hidden' }}
        loader={<LoadingOutlined />}
        endMessage={
          <h4>{dataNews.length > 0 ? translation('new.readAll') : ''}</h4>
        }
      >
        {dataNews && dataNews.length > 0 ? (
          dataNews.map((newItem, i) => {
            return (
              <>
                <div className='row list_new__item' key={Math.random()}>
                  <div className='col-12 col-md-4 d-flex justify-content-center align-items-center'>
                    <Image
                      src={newItem.stationNewsAvatar}
                      alt='image'
                      width='100%'
                      style={{ maxHeight: 285 }}
                      preview={false}
                      className='list_new__item_image'
                    />
                  </div>
                  <div className='col-12 col-md-8'>
                    <Card type='inner' className='mb-3  border-0'>
                      <div className='list_new__item__title'>
                        {newItem.stationNewsTitle}
                        <br />
                        <em>
                          {renderNewCategory(newItem?.stationNewsCategories)}
                        </em>
                      </div>
                      <Card.Meta
                        description={
                          <div className='w-100 d-flex justify-content-between'>
                            <div>{getTime(newItem)}</div>
                            <div className='d-flex flex-row'>
                              <div className='list_new__item_icon'>
                                <EyeOutlined />
                              </div>
                              <div>{getTotalView(newItem.totalViewed)}</div>
                            </div>
                          </div>
                        }
                      />
                      <Card.Meta
                        className='list_new__item__description'
                        style={{ fontSize: 18 }}
                      />
                      <div className='list_new__item__action_button'>
                        <EditOutlined
                          onClick={() =>
                            onSelectPostEdit(newItem.stationNewsId)
                          }
                        />
                        {newItem.isHidden === 0 ? (
                          <EyeOutlined
                            onClick={() => onChangePostStatus(newItem)}
                          />
                        ) : (
                          <EyeInvisibleFilled
                            onClick={() => onChangePostStatus(newItem)}
                          />
                        )}
                        <DeleteOutlined
                          onClick={() => onDeletePost(newItem.stationNewsId)}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
              </>
            )
          })
        ) : (
          <div className='d-flex justify-content-center h2'>
            {translation('new.listEmpty')}
          </div>
        )}
      </InfiniteScroll>
    </>
  )
}
