import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, notification, Switch } from 'antd';
import './scheduleSettingStyle.scss'
import ScheduleSettingService from '../../services/scheduleSettingService';
import { useSelector } from 'react-redux';

function ScheduleSetting() {
    const { t: translation } = useTranslation()
    const [dataStation, setDataStation] = useState([])
    const [loading, setLoading] = useState(true)

    const user = useSelector((state) => state.member)

    const fetchData = () => {
        ScheduleSettingService.getDetailById({ id: user.stationsId }).then(result => {
            if (result && result.stationBookingConfig && result.stationBookingConfig.length > 0) {
                setDataStation(result.stationBookingConfig)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const onFinish = (values) => {
        let newData = (values && values.sections && values.sections.length > 0) ? values.sections : []
        if(!newData || newData.length === 0) {
            return
        }
        newData.forEach(item => {
            item.limit = item.limit === true ? 1 : 0
        })
        setLoading(true)
        ScheduleSettingService.saveScheduleSetting({
            id: user.stationsId,
            data: {
                stationBookingConfig: newData
            }
        }).then(result => {
            if (result && result.issSuccess) {
                fetchData()
                notification['success']({
                    message: '',
                    description: translation('scheduleSetting.saveSuccess')
                });
            } else {
                notification['error']({
                    message: '',
                    description: translation('scheduleSetting.saveError')
                });
            }
        })
    };

    return (
        <main className="schedule_setting">
            <div className="schedule_setting__title">
                {translation('scheduleSetting.scheduleSetting')}
            </div>
            <div className="row pb-5">
                <div className="col-6 col-md-6">
                    <div className="schedule_setting__section_title row d-flex justify-content-end">
                        <div className="col-6 col-md-3">
                            <label>
                                {translation("scheduleSetting.timeline")}
                            </label>
                        </div>
                        <div className="col-6 col-md-3">
                            <label>
                                {translation("scheduleSetting.limit")}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6">
                    <div className="schedule_setting__section_title row d-flex justify-content-start">
                        <div className="col-6 col-md-3">
                            <label>
                                {translation("scheduleSetting.timeline")}
                            </label>
                        </div>
                        <div className="col-6 col-md-3">
                            <label>
                                {translation("scheduleSetting.limit")}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="schedule_setting__body">
                {!loading && 
                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                        <div className="row">
                            <Form.List name="sections" initialValue={dataStation.length > 0 ? dataStation : []}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => {
                                            return (
                                                <>
                                                    <div className="col-6 col-md-6" key={key}>
                                                        <div className={`row d-flex ${key % 2 === 0 ? 'justify-content-end' : 'justify-content-start'}`}>
                                                            <div className="col-6 col-md-3 schedule_setting__body__timeline">{dataStation[key] && dataStation[key].time}</div>

                                                            <div className="col-6 col-md-3">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'limit']}
                                                                    fieldKey={[fieldKey, 'limit']}
                                                                    rules={[{ required: true, message: translation("scheduleSetting.quantityError") }]}
                                                                >
                                                                    <Switch 
                                                                        defaultChecked={dataStation[key].limit === 1 ? true : false}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)
                                        })}
                                    </>
                                )}
                            </Form.List>
                        </div>
                        <div className="row">   
                            <div className="col-12 d-flex justify-content-center">
                                <Button className="blue_button" htmlType="submit">
                                    {translation("scheduleSetting.save")}
                                </Button>
                            </div>
                        </div>
                    </Form>}
            </div>
        </main>
    )
}

export default ScheduleSetting