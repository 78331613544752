import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./setting.scss";
import { useDispatch } from "react-redux";
import { SETTING } from "./../../constants/setting";
import { useSelector } from "react-redux";
import InspectionProcessService from "./../../services/inspectionProcessService";
import { validateEmail } from "../../helper/common";
import { Input } from "antd";
import _ from "lodash";
import Service from "../../services/request";
import StationIntroduceService from "services/stationIntroduceService";

export default function SettingGeneral() {
  const { t: translation } = useTranslation();
  const setting = useSelector((state) => state.setting);
  const user = useSelector((state) => state.member);
  const [introData, setIntroData] = useState({});
  const [settingData, setSettingData] = useState({});
  const dispatch = useDispatch();

  function handleUpdateSetting(data) {
    InspectionProcessService.updateById({ id: user.stationsId, data });
  }

  function handleOnChange(data) {
    window.localStorage.removeItem("setting")
    dispatch({ type: SETTING, data: data });
  }

  function onChangeIntro() {
    const newParams = {
      id: user.stationsId,
    };
    StationIntroduceService.findStationById(newParams)
      .then((result) => {
        setIntroData(result);
      })
      .catch(() => {
        translation("introduce.importFailed");
      });
  }

  useEffect(() => {
    onChangeIntro();

    setSettingData(setting);
  }, []);

  const handleChangeIntro = (name, value) => {
    setIntroData({
      ...introData,
      [name]: value,
    });
  };

  function handleUpdateIntro(data) {
    StationIntroduceService.updateStationById({ id: user.stationsId, data });
  }

  function handleUpdateStation(data) {
    window.localStorage.removeItem("setting")
    StationIntroduceService.updateStationExtraInfoById({ id: user.stationsId, data });
  }

  return (
    <div className="setting">
      <div className="row">
        <div className="col-12 col-md-4">
          <label>{translation("setting.name")}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              placeholder={translation("setting.inputName")}
              value={setting.stationsName}
              onChange={(e) => {
                const { value } = e.target;
                handleOnChange({ stationsName: value });
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateSetting({ stationsName: value });
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>Hotline</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              placeholder="Hotline"
              value={setting.stationsHotline}
              onChange={(e) => {
                const { value } = e.target;
                handleOnChange({ stationsHotline: value });
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateSetting({ stationsHotline: value });
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>{translation("setting.address")}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              placeholder={translation("setting.address")}
              value={setting.stationsAddress}
              onChange={(e) => {
                const { value } = e.target;
                handleOnChange({ stationsAddress: value });
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateSetting({ stationsAddress: value });
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>Email</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              placeholder="Email"
              value={setting.stationsEmail}
              onChange={(e) => {
                const { value } = e.target;
                handleOnChange({ stationsEmail: value });
              }}
              onBlur={(e) => {
                const { value } = e.target;
                if (!validateEmail(value)) {
                  handleOnChange({ stationsEmail: "" });
                } else {
                  handleUpdateSetting({ stationsEmail: value });
                }
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>Facebook</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              id="stationFacebookUrl"
              name="stationFacebookUrl"
              placeholder="Facebook"
              value={introData.stationFacebookUrl}
              onChange={(e) => {
                const { name, value } = e.target;
                handleChangeIntro(name, value);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateIntro({ stationFacebookUrl: value });
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>Instagram</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              id="stationInstagramUrl"
              name="stationInstagramUrl"
              placeholder="Instagram"
              value={introData.stationInstagramUrl}
              onChange={(e) => {
                const { name, value } = e.target;
                handleChangeIntro(name, value);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateIntro({ stationInstagramUrl: value });
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>Twitter</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              id="stationTwitterUrl"
              name="stationTwitterUrl"
              placeholder="Twitter"
              value={introData.stationTwitterUrl}
              onChange={(e) => {
                const { name, value } = e.target;
                handleChangeIntro(name, value);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateIntro({ stationTwitterUrl: value });
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>Youtube</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              id="stationYoutubeUrl"
              name="stationYoutubeUrl"
              placeholder="Youtube"
              value={introData.stationYoutubeUrl}
              onChange={(e) => {
                const { name, value } = e.target;
                handleChangeIntro(name, value);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateIntro({ stationYoutubeUrl: value });
              }}
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <label>{translation("setting.stationsManager")}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              id="stationsManager"
              name="stationsManager"
              placeholder={`${translation("setting.stationsManager")}`}
              value={settingData.stationsManager}
              onChange={(e) => {
                const { name, value } = e.target;
                setSettingData({...settingData, [name]: value});
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateStation({ stationsManager: value });
              }}
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <label>{translation("setting.stationsLicense")}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              id="stationsLicense"
              name="stationsLicense"
              placeholder={`${translation("setting.stationsLicense")}`}
              value={settingData.stationsLicense}
              onChange={(e) => {
                const { name, value } = e.target;
                setSettingData({...settingData, [name]: value});
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateStation({ stationsLicense: value });
              }}
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <label>{translation("setting.stationsCertification")}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
            <Input
              id="stationsCertification"
              name="stationsCertification"
              placeholder={`${translation("setting.stationsCertification")}`}
              value={settingData.stationsCertification}
              onChange={(e) => {
                const { name, value } = e.target;
                setSettingData({...settingData, [name]: value});
              }}
              onBlur={(e) => {
                const { value } = e.target;
                handleUpdateStation({ stationsCertification: value });
              }}
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <label htmlFor="stationsVerifyStatus">{translation("setting.stationsVerifyStatus")}</label>
        </div>
        <div className="col-12 col-md-8">
          <div className="ant-row ant-form-item">
          <select name="stationsVerifyStatus" id="stationsVerifyStatus" onChange={(e) => handleUpdateStation({stationsVerifyStatus: e.target.value})}>
            <option selected={setting.stationsVerifyStatus?.toString() === "0"} value="0">Chưa khai báo</option>
            <option selected={setting.stationsVerifyStatus?.toString() === "1"} value="1">Đã khai báo</option>
            <option selected={setting.stationsVerifyStatus?.toString() === "2"} value="2">Đă đăng kí Bộ Công thương</option>
          </select>
          </div>
        </div>

      </div>
    </div>
  );
}
