import React, { useEffect, useState } from "react";
import { DatePicker, Spin } from "antd";
import { useTranslation } from "react-i18next";
import "./statistical.scss";
import {
  IconCustomer,
  IconCustomerReturn,
  IconSMS,
  IconMoney,
  EmailSendIcon,
} from "../../assets/icons";
import StatisticalService from "../../services/statisticalService";
import moment from "moment";
import _ from "lodash";
import EmailIcon from "components/EmailIcon";

import { BarChart } from "Page/Charts/BarChart";
import { DoughnutChart } from "Page/Charts/DoughnutChart";
import UserInfoIcon from "../../assets/icons/user-info.svg";
import UserProfileIcon from "../../assets/icons/user-profile.svg";
import UserNewIcon from "../../assets/icons/new-user.svg";
import PercentUp from "../../assets/icons/percent-up.svg";
import PercentDown from "../../assets/icons/percent-down.svg";
import Sms from "../../assets/icons/sms.svg";
import Email from "../../assets/icons/email.svg";
import Money from "../../assets/icons/money2.svg";
import { formatNumber } from "helper/formatNumber";
import { LineChart } from "Page/Charts/LineChart";
import * as sc from "../Charts/Chart.styled";

const { RangePicker } = DatePicker;

const Statistical = (props) => {
  const { t: translation } = useTranslation();
  const [statisticalData, setStatisticalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  });

  const onFilterByDate = async (date, dateString) => {
    // if (dateString && dateString[0] &getDetailByUrl& dateString[0].length > 0) {
    //   filter.startDate = date[0];
    //   filter.endDate = date[1];
    // } else {
    //   filter.startDate = moment().startOf("month");
    //   filter.endDate = moment().endOf("month");
    // }
    // setFilter({ ...filter });

    const result = await StatisticalService.getStatistical({ stationId: 0 });
    if (result && !_.isEmpty(result)) {
      setStatisticalData(result);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true)
      await onFilterByDate();
      setLoading(false)
    })()
  }, []);

  const thisMonthVal = (() => {
    if(!statisticalData.customer) return
    if(!statisticalData.customer.data) return
    if(!statisticalData.customer.data.length === 0) return

    return statisticalData.customer.data[statisticalData.customer.data.length -1]
  })();

  const thisMonthSmsVal = (() => {
    if(!statisticalData.messages?.sms) return

    return statisticalData.messages.sms.data[statisticalData.messages.sms.data.length -1]
  })();

  if(loading) {
    return (
      <div className="flex-center loading">
        <Spin />
      </div>
    )
  }

  return (
    <main className="statistical">
      <div className="statistical__title">
        {translation("statistical.title")}
      </div>
      <div className="d-flex justify-content-center w-100">
        <div className="statistical__body">
          <div className="w-100 d-flex justify-content-center">
            {/* <RangePicker
              format="DD/MM/YYYY"
              onChange={onFilterByDate}
              value={[filter.startDate, filter.endDate]}
              placeholder={[translation('listCustomers.startDate'), translation('listCustomers.endDate')]}
            /> */}
          </div>

          {/* { statisticalData && !_.isEmpty(statisticalData) ?
            <>
            <div className="row mt-5 statistic-user flex-between">
              <StatisticalItem bg={"#2E5BFF"} title={`Tổng khách hàng`} icon={UserInfoIcon} count={999000000} percent={{isUp: true, count: 2.4}} />
              <StatisticalItem bg={"#A23FC3"} title={`Khách hàng mới`} icon={UserNewIcon} count={10000000} percent={{isUp: true, count: 2.4}} />
              <StatisticalItem bg={"#00877C"} title={`KH quay lại lần 2`} icon={UserProfileIcon} count={7500000} percent={{isUp: true, count: 2.4}} />
              <div className='statistic-container flex-center' style={{ background: "white", flexFlow: "column" }}>
                <DoughnutChart />
                <h3 className='statistic-doughnut-des'>Tỉ lệ khách quay lại lần 2</h3>
              </div>
            </div>
          </> : <></>
          } */}

          <>
            <div className="d-flex align-items-center">
              <sc.Line />
              <sc.Title>KHÁCH HÀNG</sc.Title>
            </div>

            <div className="row mt-5 statistic-user flex-around">
              <StatisticalItem
                bg={"#2E5BFF"}
                title={`Tổng khách hàng`}
                icon={UserInfoIcon}
                count={statisticalData.customer?.total?.value || 0}
                percent={{
                  type:
                    statisticalData.customer?.total?.lastMonthValueDiff
                      ?.type,
                  count:
                    statisticalData.customer?.total?.lastMonthValueDiff
                      ?.value || 0,
                }}
              />
              <StatisticalItem
                bg={"#A23FC3"}
                title={`Khách hàng mới`}
                icon={UserNewIcon}
                count={statisticalData.customer?.new?.value || 0}
                percent={{
                  type:
                    statisticalData.customer?.new?.lastMonthValueDiff?.type ===
                    "GT",
                  count:
                    statisticalData.customer?.new?.lastMonthValueDiff?.value ||
                    0,
                }}
              />
              <StatisticalItem
                bg={"#00877C"}
                title={`KH quay lại lần 2`}
                icon={UserProfileIcon}
                count={thisMonthVal?.returned?.value || 0}
                percent={{
                  type:
                  thisMonthVal?.returned?.lastMonthValueDiff
                      ?.type,
                  count:
                  thisMonthVal?.returned?.lastMonthValueDiff
                  ?.value || 0,
                }}
              />
              <div
                className="statistic-container flex-center"
                style={{ background: "white", flexFlow: "column" }}
              >
                <DoughnutChart
                  percent={{
                    type:
                      thisMonthVal?.returned?.lastMonthValueDiff
                        ?.type,
                    count:
                      thisMonthVal?.returned?.lastMonthValueDiff
                        ?.value || 0,
                  }}
                />
                <h3 className="statistic-doughnut-des">
                  Tỉ lệ khách quay lại lần 2
                </h3>
              </div>
            </div>
          </>
        </div>
      </div>

      <div className="mt-4">
        <BarChart data={statisticalData.customer?.data || []} />
      </div>

      <div className="d-flex justify-content-center w-100">
        <div className="statistical__body">
          <div className="d-flex align-items-center">
            <sc.Line />
            <sc.Title>Tin nhắn SMS</sc.Title>
          </div>

          <div className="row mt-5 statistic-user flex-around">
            <StatisticalItem
              bg={"#FFB63B"}
              title={`Tổng số SMS`}
              icon={Sms}
              count={statisticalData.messages?.sms?.total?.value || 0}
              percent={{
                type:
                  statisticalData.messages?.sms?.total?.lastMonthValueDiff
                    ?.type,
                count:
                statisticalData.messages?.sms?.total?.lastMonthValueDiff
                    ?.value || 0,
              }}
            />
            <StatisticalItem
              bg={"#007BFF"}
              title={`Gửi thành công`}
              icon={Sms}
              count={statisticalData.messages?.sms?.sent.value || 0}
              percent={{
                type:
                  statisticalData.messages?.sms?.sent.lastMonthValueDiff
                    ?.type,
                count:
                statisticalData.messages?.sms?.sent.lastMonthValueDiff
                    ?.value || 0,
              }}
            />
            <StatisticalItem
              bg={"#FF3D58"}
              title={`Gửi thất bại`}
              icon={Sms}
              count={statisticalData.messages?.sms?.failed.value || 0}
              percent={{
                type:
                  statisticalData.messages?.sms?.failed.lastMonthValueDiff
                    ?.type,
                count:
                statisticalData.messages?.sms?.failed.lastMonthValueDiff
                    ?.value || 0,
              }}
            />
            <StatisticalItem
              bg={"#BD8180"}
              title={`Chi phí`}
              icon={Sms}
              count={statisticalData.messages?.sms?.cost.value || 0}
              percent={{
                type:
                  statisticalData.messages?.sms?.cost.lastMonthValueDiff
                    ?.type,
                count:
                statisticalData.messages?.sms?.cost.lastMonthValueDiff
                    ?.value || 0,
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <LineChart data={statisticalData.messages} />
      </div>
    </main>
  );
};

const StatisticalItem = ({ title, count, icon, bg, percent }) => {
  return (
    <div className="statistic-container" style={{ background: bg }}>
      <div className="statistic-icon flex-center">
        <img src={icon} alt={"statistic-icon"} />
      </div>
      <div className="statistic-title">
        <span>{title}</span>
      </div>
      <div className="statistic-count">
        <span>{formatNumber(count)}</span>
      </div>
      <div className="statistic-percent d-flex w-100">
        <span className="d-inline-block">So với tháng trước</span>
        <span className={`percent-${percent.type === "GT" ? "up" : percent.type === "LT" ? "down" : ""} flex-center`}>
          <img src={(percent.type === "GT" || percent.type === "EQ") ? PercentUp : PercentDown} alt="percent" />{" "}
          {Math.ceil(percent.count)}%
        </span>
      </div>
    </div>
  );
};

export default Statistical;
