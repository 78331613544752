import { FormOutlined, LockOutlined, UnlockOutlined, DeleteOutlined } from '@ant-design/icons';
import { notification, Space, Table, Select, Input, Button, Form } from 'antd';
import ModalEditUserInfo from './ModalEditUserInfo';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ManagementService from '../../services/manageService';
import './management.scss'
import ModalAddUser from './ModalAdd';



function ListUser() {
  const { t: translation } = useTranslation()
  const member = useSelector(state => state.member)
  const [formEdit] = Form.useForm()
  const [formAdd] = Form.useForm()
  const [isAdd, setIsAdd] = useState(false)
  const inputAddRef = useRef()
  const DEFAULT_FILTER = {
    "filter": {
      "active": undefined,
      "username": undefined,
      "email": undefined,
      "phoneNumber": undefined,
      "stationsId": member.stationsId
    },
    "skip": 0,
    "limit": 20,
    "searchText": undefined,
    "order": {
      "key": "createdAt",
      "value": "desc"
    }
  }
  const [dataFilter, setDataFilter] = useState(DEFAULT_FILTER)
  const [dataUser, setDataUser] = useState({
    total: 0,
    data: []
  })
  const [isEditting, setIsEditting] = useState(false)
  const inputRef = useRef()
  const [selectedUser, setSelectedUser] = useState(null)
  const [searchText, setSearchText] = useState('')

  const columns = [
    {
      title: translation('listSchedules.index'),
      width: 50,
      key: "key",
      render: (_, __, index) => {
        return dataFilter.skip ? dataUser.total - (dataFilter.skip + index) : dataUser.total - (index)
      }
    },
    {
      title: translation('landing.fullname'),
      key: 'customerRecordPlatenumber',
      width: 270,
      render: (_, row) => {
        return `${row.firstName} ${row.lastName}`
      }
    },
    {
      title: translation('landing.account'),
      dataIndex: 'username',
      key: 'username',
      width: 170
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: translation('landing.phoneNumber'),
      key: 'phoneNumber',
      dataIndex: 'phoneNumber'
    },
    // {
    //   title: translation('setting.security') +  ' 2FA',
    //   key: 'twoFAEnable',
    //   render: (_, row) => {
    //     return (
    //       <Switch 
    //         checked={row.twoFAEnable === 1 ? true : false} 
    //         onChange={(newVal) => updateUserData({
    //           id: row.appUserId,
    //           data: {
    //             twoFAEnable: newVal ? 1 : 0
    //           }
    //         })}
    //       />
    //     )
    //   }
    // },
    {
      title: translation('management.role'),
      key: 'appUserRoleName',
      dataIndex: 'appUserRoleName',
      render: (_,row) =>{ 
        return <div>{row.appUserRoleName ? row.appUserRoleName : translation('management.none')}</div>
      }
    },
    {
      title: ' ',
      key: 'action',
      width: 50,
      render: (_, record) => {
        return (
          <Space size="middle">
            {
              record.active === 1 ? (
                <LockOutlined onClick={() => updateUserData({
                  id: record.appUserId,
                  data: {
                    active: 0
                  }
                })} />
                ) : (
                <UnlockOutlined onClick={() => updateUserData({
                  id: record.appUserId,
                  data: {
                    active: 1
                  }
                })}/>
              )
            }
            <FormOutlined onClick={() => {
              setSelectedUser(record)
              setIsEditting(true)
              if(inputRef && inputRef.current) {
                setTimeout(() => {
                  inputRef.current.focus()
                },100)
              }
            }} />
            <DeleteOutlined 
              onClick={() => updateUserData({
                id: record.appUserId,
                data: {
                  isDeleted: 1
                }
              })}
            />
          </Space>
        )
      },
    }
  ];

  function updateUserData(data) {
    ManagementService.updateUser(data).then(result => {
      if(result) {
        setSelectedUser(null)
        fetchData(dataFilter)
        isEditting && setIsEditting(false)
        formEdit.resetFields()
        notification.success({
          message:'',
          description: translation('accreditation.updateSuccess')
        })
      } else {
        notification.error({
          message:'',
          description: translation('accreditation.updateError')
        })
      }
    })
  }

  function fetchData(paramFilter) {
    ManagementService.getListUser(paramFilter).then(result => {
      if(result) {
        setDataUser(result)
      } else {
        notification.error({
          message: '',
          description: translation('new.fetchDataFailed')
        })
      }
    })
  }

  useEffect(() => {
    fetchData(dataFilter)
  },[])

  const onFilterUserByStatus = (e) => {
    let newFilter = dataFilter
    if(e || e === 0) {
      newFilter.filter.active = e
    } else {
      newFilter.filter.active = undefined
    }
    setDataFilter(newFilter)
    fetchData(newFilter)
  }

  function handleFilter() {
    let newFilter = dataFilter
    newFilter.searchText = searchText ? searchText : undefined
    setDataFilter(newFilter)
    fetchData(newFilter)
  }

  function handleCreateNew(values) {
    ManagementService.registerUser(values).then(result => {
      if(result && result.isSuccess) {
        notification.success({
          message: "",
          description: translation('management.createSuccess')
        })
        isAdd && setIsAdd(false)
        formAdd.resetFields()
        fetchData(dataFilter)
      } else {
        notification.error({
          message: "",
          description: translation('management.addFailed')
        })
      }
    })
  }

  return (
    <div className="management mt-5">
      <div className="management__header">
        <div className="management__header__title">{translation('management.userManagement')}</div>
        <div className='row mb-3'>
          <div className='col-12 col-md-3 mb-md-1'>
            <Input.Search 
              value={searchText} 
              onPressEnter={handleFilter} 
              onSearch={handleFilter}
              onChange={(e) => setSearchText(e.target.value)} 
              placeholder={translation('new.type')}
            />
          </div>

          <div className='col-6 col-md-3 mb-md-1'>
            <Select defaultValue={""} onChange={onFilterUserByStatus} style={{width: "100%"}}>
              <Select.Option value="">{translation('new.allPost')}</Select.Option>
              <Select.Option value={1}>{translation('management.active')}</Select.Option>
              <Select.Option value={0}>{translation('management.inActive')}</Select.Option>
            </Select>
          </div>

          <div className='d-m-none col-md-3'/>
          
          <div className='col-6 col-md-3 mb-md-1'>
            <div className='w-100 d-flex justify-content-end'>
              <Button 
                onClick={() => {
                  setIsAdd(true)
                  setTimeout(() => {
                    if(inputAddRef && inputAddRef.current) {
                      inputAddRef.current.focus()
                    }
                  },10)
                }} 
                type="primary"
              >{translation('inspectionProcess.add')}</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="management__body">
        <Table
          dataSource={dataUser.data}
          columns={columns} 
          scroll={{x: 1200}}
          pagination={{
              position: ['bottomCenter'],
              total: dataUser.total,
              pageSize	: dataFilter.limit,
              current: dataFilter.skip ? (dataFilter.skip / dataFilter.limit) + 1 : 1
          }}
          onChange={({ current, pageSize })=>{
              dataFilter.skip = (current -1) * pageSize	
              setDataFilter({...dataFilter})
              fetchData(dataFilter)
          }} 
        />
      </div>
      <ModalEditUserInfo 
        isEditing={isEditting}
        toggleEditModal={() => setIsEditting(!isEditting)}
        onUpdateUser={updateUserData}
        selectedUser={selectedUser}
        inputRef={inputRef}
        form={formEdit}
      />
      <ModalAddUser 
        isAdd={isAdd}
        onCancel={() => setIsAdd(false)}
        form={formAdd}
        onCreateNew={handleCreateNew}
        inputRef={inputAddRef}
        member={member}
      />
    </div>
  )
}

export default ListUser