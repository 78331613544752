import React, { useState, useEffect } from "react";
import {
  Form,
  Upload,
  Image,
  notification,
  Input,
  Button,
  Space,
  Typography,
  Popconfirm
} from "antd";
import { PlusOutlined, RetweetOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { convertFileToBase64 } from "../../helper/common";
import uploadService from "../../services/uploadService";
import NewService from "../../services/newService";
import _ from "lodash";
import "./newStyle.scss";
import TextArea from "antd/lib/input/TextArea";
import { Avatar, List } from "antd";

const CONTENT_MAXLENGTH = 1500;

function CategoryNew({ setIsReload }) {
  const { t: translation } = useTranslation();
  const [selectedImage, setSelectedImage] = useState({
    imageFile: null,
    imageUrl: null,
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [totalCategories, setTotalCategories] = useState(0);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [wordCount, setWordcount] = useState(0)

  useEffect(() => {
    (async () => {
      const categoriesRes = await NewService.getListCategory();
  
      setCategories(categoriesRes.data);
      setTotalCategories(categoriesRes.total);
    })()
  }, []);

  const onFormReset = () => {
    form.resetFields();
    setSelectedCategory(null);
    setSelectedImage({
      imageFile: null,
      imageUrl: null,
    });
  };

  const onSelectImage = async (e) => {
    if (!e) {
      setSelectedImage({
        imageFile: null,
        imageUrl: null,
      });
    } else {
      const { file } = e;
      if (file) {
        const validImageTypes = "image/";
        const fileType = file["type"];
        if (!fileType.includes(validImageTypes)) {
          notification["error"]({
            message: "",
            description: translation("new.wrongImageFile"),
          });
        } else {
          if (file.status !== "uploading") {
            convertFileToBase64(file.originFileObj).then((dataUrl) => {
              const newData = dataUrl.replace(/,/gi, "").split("base64");
              if (newData[1]) {
                setSelectedImage({
                  imageFile: {
                    imageData: newData[1],
                    imageFormat: "png",
                  },
                  imageUrl: dataUrl,
                });
              }
            });
          }
        }
      }
    }
  };

  const onCategoryNew = (value) => {
    let imageUrl = "";
    if (selectedImage.imageFile) {
      uploadService
        .uploadImage(selectedImage.imageFile)
        .then((result) => {
          if (result.issSuccess) {
            imageUrl = result.data;
          } else {
            notification["error"]({
              message: "",
              description:
                result.statusCode === 413
                  ? translation("new.imageTooLarge")
                  : translation("new.saveImageFailed"),
            });
          }
        })
        .then(() => {
          handleCategoryNew({
            ...value,
            stationNewsCategoryAvatar: imageUrl,
          });
        });
    } else {
      notification.error({
        message: "",
        description: translation("new.wrongImageFile"),
      });
    }
  };

  const handleCategoryNew = async (data) => {
    NewService.addCategory(data).then(async (result) => {
      if (result.isSuccess) {
        form.resetFields();
        setSelectedImage({
          imageFile: null,
          imageUrl: "",
        });

        const categoriesRes = await NewService.getListCategory();

        setCategories(categoriesRes.data);
        setTotalCategories(categoriesRes.total);

        notification["success"]({
          message: "",
          description: translation("category.addCategorySuccess"),
        });
      } else {
        notification["error"]({
          message: "",
          description: translation("category.addCategoryFailed"),
        });
      }
    });
  };

  const onUpdateCategory = (newValue) => {
    let imageUrl = "";
    if (selectedImage.imageFile) {
      uploadService
        .uploadImage(selectedImage.imageFile)
        .then((result) => {
          if (result.issSuccess) {
            imageUrl = result.data;
          } else {
            notification["error"]({
              message: "",
              description:
                result.statusCode === 413
                  ? translation("new.imageTooLarge")
                  : translation("new.saveImageFailed"),
            });
          }
        })
        .then(() => {
          handleUpdateCategory({
            id: selectedCategory.stationNewsCategoryId,
            data: {
              stationNewsCategoryTitle: newValue.stationNewsCategoryTitle,
              stationNewsCategoryContent: newValue.stationNewsCategoryContent,
              stationNewsCategoryAvatar: imageUrl,
              stationNewsCategoryDisplayIndex:
                newValue.stationNewsCategoryDisplayIndex,
            },
          });
        });
    } else {
      handleUpdateCategory({
        id: selectedCategory.stationNewsCategoryId,
        data: {
          stationNewsCategoryTitle: newValue.stationNewsCategoryTitle,
          stationNewsCategoryContent: newValue.stationNewsCategoryContent,
          stationNewsCategoryDisplayIndex:
            newValue.stationNewsCategoryDisplayIndex,
        },
      });
    }
  };

  const handleUpdateCategory = (data) => {
    NewService.updateCategory(data).then((result) => {
      if (result.isSuccess) {
        const foundIndex = categories.findIndex(
          (item) => item.stationNewsCategoryId === data.id
        );
        if (foundIndex !== -1) {
          const newCategories = categories;
          newCategories.splice(foundIndex, 1, {id: data.id, ...data.data});
          
          setCategories(newCategories);
        }

        form.resetFields();
        setSelectedCategory(null);
        setSelectedImage({
          imageFile: null,
          imageUrl: "",
        });
        notification["success"]({
          message: "",
          description: translation("category.updateSuccess"),
        });
      } else {
        notification["error"]({
          message: "",
          description: translation("category.updateFailed"),
        });
      }
    });
  };

  const onDeleteCategory = async (id) => {
    const result = await NewService.deleteCategory({ id });

    if (result && result.isSuccess) {
      setCategories(
        categories.filter((item) => item.stationNewsCategoryId !== id)
      );
      notification.success({
        message: "",
        description: translation("category.updateSuccess"),
      });
    } else {
      notification.error({
        message: "",
        description: translation("category.updateFailed"),
      });
    }
  };

  const onInitEditCategory = async (id) => {
    const category = categories.find(
      (item) => id === item.stationNewsCategoryId
    );
    if (category) {
      form.setFieldsValue(category);
      setSelectedCategory(category);
      setSelectedImage({
        imageUrl: category.stationNewsCategoryAvatar,
      });
    }
  };

  const handleChangePage = async (pageNum) => {
    setCurrentPage(pageNum);
    const newSkip = Math.abs((pageNum - 1) * 20);
    const categoriesRes = await NewService.getListCategory(newSkip);

    setCategories(categoriesRes.data);
  };

  return (
    <>
      <main className="">
        <h1>{translation("category.list")}</h1>
        {categories && categories.length > 0 ? (
          <List
            pagination={{
              current: currentPage,
              pageSize: 20,
              total: totalCategories,
              onChange: (nextPage) => handleChangePage(nextPage),
            }}
            itemLayout="horizontal"
            dataSource={categories}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <a
                    style={{ color: "rgba(0, 0, 0, 0.45)"}}
                    href="#category_form"
                    key="list-loadmore-edit"
                    onClick={() =>
                      onInitEditCategory(item.stationNewsCategoryId)
                    }
                  >
                    {translation("category.edit")}
                  </a>,
                  <Popconfirm
                    title={translation("category.confirm")}
                    onConfirm={() => onDeleteCategory(item.stationNewsCategoryId)}
                    onCancel={() => {}}
                    okText={translation("category.yes")}
                    cancelText={translation("category.no")}
                  >
                    <a href="#" style={{ color: "rgba(0, 0, 0, 0.45)"}}>{translation("category.delete")}</a>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.stationNewsCategoryAvatar} />}
                  title={
                    <>
                      <div>
                        {`${translation("category.index")}: ${item.stationNewsCategoryDisplayIndex}`}
                      </div>
                      <a>
                        {item.stationNewsCategoryTitle}
                      </a>
                    </>
                  }
                  description={item.stationNewsCategoryContent}
                />
              </List.Item>
            )}
          />
        ) : (
          <div className="d-flex justify-content-center h2">
            {translation("new.listEmpty")}
          </div>
        )}
      </main>
      <main className="row category_new mt-3">
        <h1 id="category_form">
          {selectedCategory
            ? translation("category.updateCategory")
            : translation("category.addCategory")}
        </h1>
        <Form
          onFinish={selectedCategory ? onUpdateCategory : onCategoryNew}
          form={form}
          style={{padding: "12px"}}
        >
          <div className="w-100 d-flex justify-content-center">
            <div className="col-12 col-md-7">
              <div className="new_component__modal_title mb-1">
                <Space>
                  {translation("category.title")}
                  <Typography.Text type="danger">*</Typography.Text>
                </Space>
              </div>
              <Form.Item
                rules={[
                  { required: true, message: translation("new.errorContent") },
                ]}
                name="stationNewsCategoryTitle"
              >
                <Input
                  className="new_component__modal_content"
                  maxLength={200}
                  placeholder={translation("category.title")}
                />
              </Form.Item>
            </div>
          </div>

          {selectedCategory && (
            <div className="w-100 d-flex justify-content-center">
              <div className="col-12 col-md-7">
                <div className="new_component__modal_index mb-1">
                  <Space>{translation("category.index")}</Space>
                </div>
                <Form.Item name="stationNewsCategoryDisplayIndex">
                  <Input
                    className="new_component__modal_content"
                    placeholder={translation("new.index")}
                    type={"number"}
                  />
                </Form.Item>
              </div>
            </div>
          )}

          <div className="w-100 d-flex justify-content-center">
            <div className="col-12 col-md-7">
              <div className="new_component__modal_title mb-1">
                <Space>
                  {`${translation(
                    "category.content"
                  )} (${wordCount} / ${CONTENT_MAXLENGTH.toLocaleString()})`}
                  <Typography.Text type="danger">*</Typography.Text>
                </Space>
              </div>
              <Form.Item
                name="stationNewsCategoryContent"
                rules={[
                  {
                    required: true,
                    message: translation("new.invalidContent"),
                  },
                ]}
              >
                <TextArea
                  className="new_component__modal_content"
                  maxLength={1500}
                  placeholder={translation("category.content")}
                  onChange={(e) => setWordcount(e.target.value.length)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="w-100 d-flex justify-content-center">
            <div className="col-12 col-md-7 new_component__modal_title">
              <Space>
                {translation("new.addImage")}
                <Typography.Text type="danger">*</Typography.Text>
              </Space>
            </div>
          </div>

          <div className="w-100 d-flex justify-content-center mb-2">
            <div className="col-12 col-md-7">
              <div className="col-12">
                <Upload.Dragger
                  id="image"
                  showUploadList={false}
                  accept="image/*"
                  onChange={onSelectImage}
                  listType="picture"
                  beforeUpload={(file) => file["type"].includes("image/")}
                >
                  <p className="ant-upload-drag-icon">
                    {selectedImage.imageUrl ? (
                      <RetweetOutlined />
                    ) : (
                      <PlusOutlined />
                    )}
                  </p>
                  <p className="ant-upload-text">
                    {selectedImage.imageUrl
                      ? translation("new.changeImage")
                      : translation("new.selectImage")}
                  </p>
                </Upload.Dragger>
              </div>
              <Image
                src={selectedImage.imageUrl}
                preview={false}
                hidden={selectedImage.imageUrl ? false : true}
                className="new_component__preview_image col-12 col-md-4 mt-2"
              />
            </div>
          </div>

          <div className="w-100 d-flex justify-content-center mt-3">
            <div className="col-12 col-md-7">
              <Form.Item>
                <Button
                  type="primary"
                  className="w-100"
                  size="large"
                  htmlType="submit"
                >
                  {selectedCategory
                    ? translation("category.updateCategory")
                    : translation("category.addCategory")}
                </Button>
              </Form.Item>
            </div>
          </div>

          <div className="w-100 d-flex justify-content-center">
            <div className="col-12 col-md-7">
              <Button
                type="danger"
                className="w-100"
                size="large"
                htmlType="Reset"
                onClick={onFormReset}
              >
                {translation("category.reset")}
              </Button>
            </div>
          </div>
        </Form>
      </main>
    </>
  );
}

export default CategoryNew;
