import React from 'react'
import ListSchedule from './listSchedule'
import ScheduleSetting from './scheduleSetting'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

export default function CustomerSchedule () {
    const { t: translation } = useTranslation()

    return (
        <Tabs defaultActiveKey="1" >
            <TabPane tab={translation("listSchedules.listSchedules")} key="1">
                <ListSchedule/>
            </TabPane>
            <TabPane tab={translation("scheduleSetting.scheduleSetting")} key="2">
                <ScheduleSetting />
            </TabPane>
        </Tabs>
    )

}