import Request from './request'

export default class ManagementService {
  static async getListUser(data) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/stationUserList',
        data
      }).then((result = {})=>{
        const { statusCode, data } = result
        if(statusCode === 200) {
          return resolve(data)
        }else{
          return resolve(null)
        }
      })
    })
  }

  static async updateUser(data) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/updateStationUserById',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
          return resolve('ok')
        }else{
          return resolve(null)
        }
      })
    })
  }

  static async registerUser(data) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/registerStationUser',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        console.clear();
        console.log(result);
        if(statusCode === 200) {
          return resolve({isSuccess: true})
        }else{
          return resolve({isSuccess: false})
        }
      })
    })
  }
  
  static async getListRole(data) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: '/AppUserRole/find',
        data
      }).then((result = {})=>{
        const { statusCode, data } = result
        if(statusCode === 200) {
          return resolve(data)
        }else{
          return resolve(null)
        }
      })
    })
  }
}
