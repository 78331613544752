import { routes } from "App"
const staticRoutes = (translation) => {
  return [
    {
      name: translation("header.accreditation"),
      href: routes.accreditation.path,
    },
    {
      name: translation('header.customers'),
      href: routes.listCustomer.path,
    },
    {
      name: translation('header.schedule'),
      href: routes.customerSchedule.path,
    },
    {
      name: translation("listCustomers.statistical"),
      href: routes.statistical.path
    },
    {
      name: translation('header.new'),
      href: routes.news.path,
      isNoHref: false
    },
    {
      name: translation('header.establish'),
      href: routes.setting.path,
    },
    {
      name: translation('header.management'),
      href: routes.management.path
    }
  ]
}

export const LIST_PERMISSION = {
  MANAGE_CUSTOMER: "MANAGE_CUSTOMER",
  MANAGE_RECORD: "MANAGE_RECORD",
  MANAGE_APP_USER: "MANAGE_APP_USER",
  MANAGE_SETTINGS: "MANAGE_SETTINGS",
  MANAGE_SCHEDULE: "MANAGE_SCHEDULE",
  MANAGE_NEWS: "MANAGE_NEWS"
}

export const renderRoutes = (translation, permission, roleId) => {
  let permissionRoute = []
  const ALL_ROUTES = staticRoutes(translation)
  
  if(roleId === 1) return ALL_ROUTES
  if(!permission) return []

  if(permission.includes(LIST_PERMISSION.MANAGE_RECORD)) {
    permissionRoute.push(ALL_ROUTES[0])
  }
  if(permission.includes(LIST_PERMISSION.MANAGE_CUSTOMER)) {
    permissionRoute.push(ALL_ROUTES[1])
  }
  if(permission.includes(LIST_PERMISSION.MANAGE_SCHEDULE)) {
    permissionRoute.push(ALL_ROUTES[2])
  }
  permissionRoute.push(LIST_PERMISSION[3]);
  if(permission.includes(LIST_PERMISSION.MANAGE_NEWS)) {
    permissionRoute.push(ALL_ROUTES[4])
  }
  if(permission.includes(LIST_PERMISSION.MANAGE_SETTINGS)) {
    permissionRoute.push(ALL_ROUTES[5])
  }
  if(permission.includes(LIST_PERMISSION.MANAGE_APP_USER)) {
    permissionRoute.push(ALL_ROUTES[6])
  }

  permissionRoute = permissionRoute.filter(item => item)

  return permissionRoute;
}